import React, {useEffect, useState, useRef} from "react"
import connect from "./connectToStore"
import { snowplowUrls, constants } from "../constants"
import { withRouter } from "react-router"

/**
 * The snowplowTracker is a component that will setup and initialize the snowplow tracker in our application.
 * This is a third party tool, provided by Visma, that will help us track user behaviour on our platform.
 * It will track all setup events and page views. Currently it is setup to track link clicks, form submissions and page views.
 * All the data that is being provided to the tracker comes from our Redux Store. 
 * 
 * @param {object} user - The user object from the Redux Store
 * @param {string} userRole - The role of the user from the Redux Store
 * @param {object} course - The course object from the Redux Store
 * @param {object} organisation - The organisation object from the Redux Store
 * @param {object} currentPage - The current page object from the Redux Store
 * @param {object} story - The current story object from the Redux Store
 * @param {object} chapter - The current chapter object from the Redux Store
 * @param {object} location - The location object from the router. This is used to fetch the chapterId and storyId to fetch the correct data from the Redux Store
 * @returns 
 */
const SnowplowTracker = ({user, userRole, course, organisation, currentPage, story, chapter, location}) => {
    const getUserRole = (role) => {
      const userRoles = constants.userRoles
      return Object.keys(userRoles).find(key => userRoles[key] === role);
    }

    /**
     * Returns the chapter page type.
     * @param {*} pageType 
     * @returns 
     */
    const getPageType = (pageType) => {
      const pageTypes = constants.pageTypes
      return Object.keys(pageTypes).find(key => pageTypes[key] === pageType);
    }

    const { pathname } = location;
    const pathParts = pathname.split("/");
    const chapterId = pathParts[6];

    const [userData, setUserData] = useState(null)
    const [orgData, setOrgData] = useState(null)

    const previousPage = useRef(null);
    const [isInChapterFlow, setIsInChapterFlow] = useState(false);

    /**
     * This useEffect will run every time that the user or organisation object changes.
     * This will store the user and organisation data in state for use throughout the flow.
     */
    useEffect(() => {
      // Store user data in state for use throughout the flow
      if (user && userData !== user) {
        setUserData(user)
      }

      // Store org data in state for use throughout the flow
      if (organisation && Object.keys(organisation).length > 0 && orgData !== organisation) {
        setOrgData(organisation)
      }
    }, [user, organisation])

    /**
     * This useEffect will run every time that the chapterId changes.
     * This will set the isInChapterFlow state to true if a chapterId is present in the URL.
     */
    useEffect(() => {
      if (chapterId) {
        setIsInChapterFlow(true);
      }
    }, [chapterId])
  
    /**
     * This useEffect will render the snowplow tracker on the page, making tracking available.
     */
    useEffect(() => {
      // Check if all the data is available to prevent spamming
      if (!userData || !orgData || !course) {
        return null;
      }

      ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
        p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
        };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
        n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//storage.googleapis.com/snowplow-cto-office-tracker-bucket/3.12.0/sp.js","snowplow")); // Have a look here for latest releases of the js tracker https://github.com/snowplow/snowplow-javascript-tracker/releases

        const snowplowUrl = process.env.REACT_APP_SNOWPLOW_URL || snowplowUrls[process.env.REACT_APP_ENV]; // Either use the env variable or the constant
    
        window.snowplow('newTracker', 'cf', snowplowUrl, { // Initialise a tracker
          appId : "Appical_Now",
          platform: "web", // possible values (default is web): web, mob, app, pc, srv
          // anonymousTracking: true, // Use this option in case you want to anonymise your tracking. This will set user_ids and domain ids to 'null'
          contexts: {
              webPage: true,   // Common id for webpages
              performanceTiming: true // Creates performance contexts to create performance metrics of pages
              //geolocation: true    // Prompts user for geolocation data
          }
        });
    
    
        // DEFINING CONTEXTS --------------- Check the event dictionary (https://docs.google.com/spreadsheets/d/1G_Xqdj3qo8MqD5T_YAoMjYGPD380FrqkNbvYBlIkF-A/edit?usp=sharing) to understand how each event or context should be used.
    
        /**
         * Context about the customer
         * @param {string} customer_name - The name of the customer [MANDATORY]
         * @param {string} company_name - The name of the company. Set equal to customer_name or leave blank if inapplicable
         * @param {string} organization_no - The organisation number as string
         * @param {string} business_unit - The business unit [MANDATORY]
         * @param {string} countryiso - The country code. Follows the IBAN ALPHA-2 code https://www.iban.com/country-codes
         */
        const context_customer = {
            "customer_name" : organisation?.name,
            "company_name" : organisation?.name,
            "organization_no" : organisation?.id ? organisation?.id.toString() : "",
            "business_unit" : "ADD",
            "countryiso" : "XX",
        };

        /**
         * Context about the product
         * @param {string} r12 - The R12 product line code (https://docs.google.com/spreadsheets/d/1grDHVaMsXXPty9Za9UzI935laWGIqyJntEknstsjMWs/edit?usp=sharing)
         * @param {string} product_name - The name of the product [MANDATORY]
         * @param {string} module_name - The name of the module - can be equal to product_name. The course title is used here
         * @param {string} module_no - The module number - can be left blank
         * @param {string} customfield_product1 - The story title
         * @param {string} customfield_product2 - The chapter title
         * @param {string} customfield_product3 - The type of page e.g. text, image, video etc.
         */
        const context_product = {
            "r12" : "004",
            "product_name" : "Appical - Webplayer",
            "module_name" : course?.title,
            "module_no" : "",
            "customfield_product1" : story?.title,
            "customfield_product2" : chapter?.title,
            "customfield_product3" : chapterId && getPageType(currentPage?.page.pageType)
        };
        /**
         * Context about our user
         * @param {string} user_type - The role of the user [MANDATORY]
         * @param {string|null} registration_date - The date the user registered. Must follow exact date format: YYYY-MM-DD, can also be set to null
         * @param {string} org_name - The name of the organisation
         * @param {string} org_ID - The ID of the organisation
         */
        const context_user = { 
            "user_type" : getUserRole(userRole),
            "registration_date" : user?.first_day,
            "org_name" : organisation?.name,
            "org_ID" : organisation?.id,
        };
    
        // Place all contexts in a list. Remove contexts from lists if not used.
        const contexts = [{'schema': 'iglu:com.visma-tech-hubs/context_customer/jsonschema/1-0-0', 'data': context_customer},
                {'schema': 'iglu:com.visma-tech-hubs/context_product/jsonschema/1-0-0', 'data': context_product},
                {'schema': 'iglu:com.visma-tech-hubs/context_user/jsonschema/1-0-0', 'data': context_user}];
        // DEFINING CONTEXTS END ---------------
    
        window.snowplow('setUserId', user?.id); // Create a user id. User Id will be anonymized.
    
        // EVENT METHODS ---------------
    
        // Initialize link click events with contexts. Only works on HTML objects with "a" tags
        window.snowplow('enableLinkClickTracking',
                {context: contexts,
                pseudoClicks: true,
                trackContent: true
                })
        
        /*
        NOTE!! If using Form Tracking, make sure to only track fields with non-sensible information. To do this, add a config variable to the second argument (row 100 for example). 
        Fields labelled password are already excluded by default
        var opts = {
              forms: {
                denylist: []
              },
              fields: {
                denylist: ['password']
              }
            };
        window.snowplow('enableFormTracking', { options: opts, context: contexts });
        */
        window.snowplow('enableFormTracking', { options: {}, context: contexts });
    
        // Enable page ping events. Contexts are added based on 'trackPageView'. The enableActivityTracking method must be called before the trackPageView method.
        window.snowplow('enableActivityTracking', { 
              minimumVisitLength: 30,
              heartbeatDelay: 20 
            });
        
        // Determine if the page view should be tracked
        const shouldTrackPageView = (userData && orgData && course && (
          currentPage && (
            !previousPage.current || currentPage?.page.id !== previousPage.current?.page.id
          ) || (!currentPage && previousPage.current)
        )) || (userData && orgData && course && !isInChapterFlow);

        // Initialize page view events. Timer is reset for each time the page is loaded
        if (shouldTrackPageView) {
          window.snowplow('trackPageView', {
            'title': null, 
            'context': contexts
          }); 
        }
    
        // EVENT METHODS END ---------------
        previousPage.current = currentPage;
    }, [course, story, chapter, currentPage, userData, orgData])

    return null;
}

export default withRouter(connect(SnowplowTracker))