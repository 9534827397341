import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { LOADED, ERROR } from "./actions"

const actionListeners = []

// eslint-disable-next-line no-unused-vars
export const formHandlerMiddleware = store => next => (action) => {
    const actionPromise = next(action)

    actionListeners.forEach(listener => listener.emit(action))

    return actionPromise
}

class ActionListener {
    constructor(finalizer) {
        this.handlers = []
        this.finalizer = finalizer
    }

    emit(action) {
        this.handlers
            .filter(handler => handler.type === action.type)
            .forEach(handler => handler.next(action.payload))
    }

    subscribe(type, next) {
        this.handlers.push({ type, next })
    }

    reset() {
        this.handlers = []
    }

    destroy() {
        this.reset()
        this.finalizer()
    }
}

export const createSubscription = () => {
    let subscriptionIndex = -1

    const subscription = new ActionListener(() => actionListeners.splice(subscriptionIndex, 1))

    subscriptionIndex = actionListeners.push(subscription) - 1

    return subscription
}

export function withAsyncSubmit(Component) {
    class AsyncSubmitWrapper extends React.Component {
        constructor(props) {
            super(props)

            const { dispatch } = props
            this.curriedAsyncSubmit = this.createAsyncSubmit.bind(this, dispatch)
            this.subscription = null
        }

        componentWillUnmount() {
            if (this.subscription) {
                this.subscription.destroy()
                this.subscription = null
            }
        }

        createAsyncSubmit(dispatch, actionCreator, errorHandler) {
            const subscription = this.subscription || createSubscription()

            if (!this.subscription) this.subscription = subscription

            const subscribe = subscription.subscribe.bind(subscription)

            return (formData) => {
                const action = actionCreator(formData)

                const loadedAction = action.type + LOADED
                const errorAction = action.type + ERROR

                dispatch(action)

                return new Promise((resolve, reject) => {
                    subscribe(loadedAction, () => {
                        subscription.reset()
                        resolve()
                    })
                    subscribe(errorAction, (payload) => {
                        subscription.reset()
                        reject(errorHandler(payload))
                    })
                })
            }
        }

        render() {
            const { dispatch, ...otherProps } = this.props

            return (
                <Component
                    createAsyncSubmit={this.curriedAsyncSubmit}
                    {...otherProps}
                />
            )
        }
    }

    AsyncSubmitWrapper.propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    return connect(null, dispatch => ({ dispatch }))(AsyncSubmitWrapper)
}
