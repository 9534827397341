import { get } from "lodash"

const styles = theme => ({
    root: {
        display: "none",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        flexWrap: "wrap",
        flexDirection: "column",
        backgroundColor: get(theme, "appicalTheme.primaryBackground"),
        padding: `0 ${theme.spacing.unit}px`,

        "&.loading": {
            display: "flex",
        },
        "&.transparent": {
            backgroundColor: "transparent",

        },
        "&.green": {
            display: "inline-block!important;",
            width: "auto",
        },
    },
    appicalLogo: {
        textAlign: "center",
        marginBottom: "32px",
    },
    loader: {
        width: "100%",
        maxWidth: "350px",
        color: get(theme, "palette.primary.main"),
        "&.green": {
            color: "rgb(0, 196, 140)",
            height: "14px!important",
            width: "14px!important",
        },
        "&.white": {
            color: "white",
        }
    },
})

export default styles
