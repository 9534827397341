import get from "lodash/get"
import {
    selectAuthToken,
    selectIsRefreshingAuthToken,
    selectRefreshToken,
    tokenRefresh,
    LOGOUT,
} from "../reducers/authentication"
import { selectAppLanguage } from "../reducers/languages"
import { createAddRequestAction } from "../reducers/requestQueue"
import { ERROR, LOADED, LOADING } from "./actions"
import constants from "../../constants"

import { captureException } from "@sentry/browser"

export const addTokenToRequest = (state, request) => {
    const authToken = selectAuthToken(state)
    return (authToken) ? {
        ...request,
        headers: {
            ...request.headers || null,
            Authorization: `Bearer ${authToken}`,
        },
    } : request
}

export const addLanguageToRequest = (state, request) => {
    const { languages } = constants
    let selectedLng = localStorage.getItem("i18nextLng") || "en"
    selectedLng = selectedLng.split("-")[0].toLowerCase()
    if (!languages[selectedLng]) {
        selectedLng = "en"
    }
    const currentLanguage = selectAppLanguage(state) || selectedLng
    return {
        ...request,
        headers: {
            ...request.headers || null,
            "LANGUAGE-ID": currentLanguage,
        },
    }
}

export const createApiClient = client => store => next => (action) => {
    function makeAction(status, data) {
        const newAction = { ...action, type: action.type + status, ...data }
        delete newAction.request
        return newAction
    }

    if (!action || !action.request) {
        return next(action)
    }

    next(makeAction(LOADING, false))

    if (selectIsRefreshingAuthToken(store.getState())) {
        return store.dispatch(createAddRequestAction(action))
    }

    let request = addTokenToRequest(store.getState(), action.request)
    request = addLanguageToRequest(store.getState(), request)

    return client.request(request).then(
        (result) => {
            const payload = { result: result.data, originalPayload: action.payload }
            next(makeAction(LOADED, { payload }))
            if (action.callback) {
                const { dispatch, getState } = store
                action.callback(dispatch, getState, payload)
            }
        },
        (error) => {
            if (action.type === LOGOUT) {
                next(makeAction(LOADED, {
                    payload: { result: null, originalPayload: action.payload },
                }))
            } 

            if (error.response && (error.response.status != 401 && error.response.status != 404)) {
                // Log the API error to Sentry
                captureException(error, { extra: { action, extraInfo: error?.response?.data?.error }, tags: { type: "api" } , level: "error"})
            }

            // if the request failed because of a login issue (code 401)
            if (get(error, "response.status") === 401) {
                const currentToken = selectAuthToken(store.getState())
                const requestToken = get(request, "headers.Authorization")

                const isUnauthenticatedRequest = !currentToken && !requestToken

                if (isUnauthenticatedRequest) {
                    next(makeAction(ERROR, {
                        payload: { result: error, originalPayload: action.payload },
                    }))
                    return
                }

                // It is possible the auth token was updated while this request happened.
                if (`Bearer ${currentToken}` !== requestToken) {
                    store.dispatch(action)
                    return
                }

                const refreshToken = selectRefreshToken(store.getState())

                // store current request in the queue so we can restart when we have a new token.
                store.dispatch(createAddRequestAction(action))

                if (!selectIsRefreshingAuthToken(store.getState())) {
                    // Only request a new token if we're not currently refreshing.
                    store.dispatch(tokenRefresh(refreshToken))
                }
            } else {
                next(makeAction(ERROR, {
                    payload: { result: error, originalPayload: action.payload },
                }))
            }
        },
    )
}

export default createApiClient
