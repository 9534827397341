import { get } from "lodash"
import { reducerName } from './index'

/**
 * Retrieves the isLoading state from the Redux store.
 * 
 * @param {Object} state - The Redux state object.
 * @returns {boolean} - The isLoading state.
 */
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`);

/**
 * Retrieves the errorMessage state from the Redux store.
 * 
 * @param {Object} state - The Redux state object.
 * @returns {string} - The errorMessage state.
 */
export const selectErrorMessage = state => get(state, `${reducerName}.errorMessage`);

/**
 * Retrieves the isRequestSent state from the Redux store.
 * 
 * @param {Object} state - The Redux state object.
 * @returns {boolean} - The isRequestSent state.
 */
export const selectIsRequestSent = state => get(state, `${reducerName}.isRequestSent`);

/**
 * Retrieves the isConfirmationEmailSent state from the Redux store.
 * 
 * @param {Object} state - The Redux state object.
 * @returns {boolean} - The isConfirmationEmailSent state.
 */
export const selectisConfirmationEmailSent = state => get(state, `${reducerName}.isConfirmationEmailSent`);
