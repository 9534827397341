import get from "lodash/get"
import orderBy from "lodash/orderBy"
import { createSelector } from "reselect"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { SELECT_COURSE } from "../courses"
import { SELECT_LANGUAGE } from "../languages"

// Required variables
const initialState = {
    /* Holds the data */
    data: [],
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
}
export const reducerName = "stories"

// Actions
export const STORIES = createActionName(reducerName, "STORIES")

// Action creators
export function createLoadingAction(courseId) {
    return {
        type: STORIES,
        request: {
            method: "get",
            url: `courses/${courseId}/stories`,
        },
    }
}

// Selectors
export const selectStories = state => get(state, `${reducerName}.data`)
export const selectLastStories = (state) => {
    const stories = get(state, `${reducerName}.data`)

    // All stories that are completed should be last all unstarted stories just before that
    return orderBy(stories, [(story) => {
        const { progress } = story

        if (progress === 100) {
            return -1
        }

        return progress
    }], ["desc"]).slice(0, 3)
}
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectStory = createSelector(selectStories, (_, id) => id,
    (stories, id) => stories.find(
        story => parseInt(story.id, 10) === parseInt(id, 10),
    ))

// Reducer
const reducers = {
    [STORIES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [STORIES + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [STORIES + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SELECT_COURSE]() { return initialState },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
