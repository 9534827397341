import get from "lodash/get"

const handleInvitationCodeErrors = (err) => {
    const errors = get(err, "response.data.error_description") || get(err, "response.data.error_descriptions")

    let returnErrors = {
        invitation_code: "main:generic_error",
    }

    if (!errors || !Array.isArray(errors)) return returnErrors

    const errorTranslations = {
        "This invite has expired": "main:invite_code_expired",
        "User limit for this invite has reached": "main:invite_code_expired",
        default: "main:invite_code_invalid",
    }

    errors.forEach((error) => {
        const translatedErr =
            // Weird differences in backend
            errorTranslations[error.error_code || error.user_input || error.error] ||
            errorTranslations.default

        if (!translatedErr) return

        returnErrors = {
            ...returnErrors,
            invitation_code: translatedErr,
        }
    })

    return returnErrors
}

export default handleInvitationCodeErrors
