import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import get from "lodash/get"
import moment from "moment/moment"
import constants from "../constants"

// Import required moment locales
import "moment/locale/ar"
import "moment/locale/bn"
import "moment/locale/cs"
import "moment/locale/da"
import "moment/locale/de"
import "moment/locale/es"
import "moment/locale/fr"
import "moment/locale/fy"
import "moment/locale/hu"
import "moment/locale/id"
import "moment/locale/ja"
import "moment/locale/ms"
import "moment/locale/nl"
import "moment/locale/nn"
import "moment/locale/pl"
import "moment/locale/pt"
import "moment/locale/ro"
import "moment/locale/ru"
import "moment/locale/sk"
import "moment/locale/sv"
import "moment/locale/th"
import "moment/locale/tr"
import "moment/locale/vi"
import "moment/locale/zh-cn"
import "moment/locale/zh-tw"
import "moment/locale/it"
import "moment/locale/he"
import "moment/locale/sr"
import "moment/locale/fi"
import "moment/locale/lv"
import "moment/locale/ko"

// Import required translation resources
import arMain from "../l10n/ar-SA/main.json"
import arThemeEditor from "../l10n/ar-SA/themeEditor.json"
import bnMain from "../l10n/bn-BD/main.json"
import csMain from "../l10n/cs-CZ/main.json"
import csThemeEditor from "../l10n/cs-CZ/themeEditor.json"
import daMain from "../l10n/da-DK/main.json"
import daThemeEditor from "../l10n/da-DK/themeEditor.json"
import deMain from "../l10n/de-DE/main.json"
import deThemeEditor from "../l10n/de-DE/themeEditor.json"
import enMain from "../l10n/en/main.json"
import enThemeEditor from "../l10n/en/themeEditor.json"
import esMain from "../l10n/es-ES/main.json"
import esThemeEditor from "../l10n/es-ES/themeEditor.json"
import frMain from "../l10n/fr-FR/main.json"
import frThemeEditor from "../l10n/fr-FR/themeEditor.json"
import fyMain from "../l10n/fy/main.json"
import fyThemeEditor from "../l10n/fy/themeEditor.json"
import huMain from "../l10n/hu-HU/main.json"
import huThemeEditor from "../l10n/hu-HU/themeEditor.json"
import idMain from "../l10n/id-ID/main.json"
import idThemeEditor from "../l10n/id-ID/themeEditor.json"
import jaMain from "../l10n/ja-JP/main.json"
import jaThemeEditor from "../l10n/ja-JP/themeEditor.json"
import msMain from "../l10n/ms-MY/main.json"
import msThemeEditor from "../l10n/ms-MY/themeEditor.json"
import nlMain from "../l10n/nl-NL/main.json"
import nlThemeEditor from "../l10n/nl-NL/themeEditor.json"
import noMain from "../l10n/no-NO/main.json"
import noThemeEditor from "../l10n/no-NO/themeEditor.json"
import plMain from "../l10n/pl-PL/main.json"
import plThemeEditor from "../l10n/pl-PL/themeEditor.json"
import ptMain from "../l10n/pt-BR/main.json"
import ptThemeEditor from "../l10n/pt-BR/themeEditor.json"
import roMain from "../l10n/ro-RO/main.json"
import roThemeEditor from "../l10n/ro-RO/themeEditor.json"
import ruMain from "../l10n/ru-RU/main.json"
import ruThemeEditor from "../l10n/ru-RU/themeEditor.json"
import skMain from "../l10n/sk-SK/main.json"
import skThemeEditor from "../l10n/sk-SK/themeEditor.json"
import svMain from "../l10n/sv-SE/main.json"
import svThemeEditor from "../l10n/sv-SE/themeEditor.json"
import thMain from "../l10n/th-TH/main.json"
import thThemeEditor from "../l10n/th-TH/themeEditor.json"
import trMain from "../l10n/tr-TR/main.json"
import trThemeEditor from "../l10n/tr-TR/themeEditor.json"
import viMain from "../l10n/vi-VN/main.json"
import viThemeEditor from "../l10n/vi-VN/themeEditor.json"
import zhCNMain from "../l10n/zh-CN/main.json"
import zhTWMain from "../l10n/zh-TW/main.json"
import zhCNThemeEditor from "../l10n/zh-CN/themeEditor.json"
import zhTWThemeEditor from "../l10n/zh-TW/themeEditor.json"
import itMain from "../l10n/it-IT/main.json"
import itThemeEditor from "../l10n/it-IT/themeEditor.json"
import heMain from "../l10n/he-IL/main.json"
import heThemeEditor from "../l10n/he-IL/themeEditor.json"
import hiMain from "../l10n/hi-IN/main.json"
import hiThemeEditor from "../l10n/hi-IN/themeEditor.json"
import hrMain from "../l10n/hr-HR/main.json"
import hrThemeEditor from "../l10n/hr-HR/themeEditor.json"
import srMain from "../l10n/sr-CS/main.json"
import srThemeEditor from "../l10n/sr-CS/themeEditor.json"
import fiMain from "../l10n/fi-FI/main"
import fiThemeEditor from "../l10n/fi-FI/themeEditor"
import lvMain from "../l10n/lv-LV/main.json"
import lvThemeEditor from "../l10n/lv-LV/themeEditor.json"
import koMain from "../l10n/ko-KR/main.json"
import koThemeEditor from "../l10n/ko-KR/themeEditor.json"

// Set up supported languages
const supportedLanguages = {
    ar: {
        main: arMain,
        themeEditor: arThemeEditor,
    },
    bn: {
        main: bnMain,
        themeEditor: enThemeEditor,
    },
    cs: {
        main: csMain,
        themeEditor: csThemeEditor,
    },
    da: {
        main: daMain,
        themeEditor: daThemeEditor,
    },
    de: {
        main: deMain,
        themeEditor: deThemeEditor,
    },
    en: {
        main: enMain,
        themeEditor: enThemeEditor,
    },
    es: {
        main: esMain,
        themeEditor: esThemeEditor,
    },
    fi: {
        main: fiMain,
        thThemeEditor: fiThemeEditor,
    },
    fr: {
        main: frMain,
        themeEditor: frThemeEditor,
    },
    fy: {
        main: fyMain,
        themeEditor: fyThemeEditor,
    },
    he: {
        main: heMain,
        themeEditor: heThemeEditor,
    },
    hi: {
        main: hiMain,
        themeEditor: hiThemeEditor,
    },
    hr: {
        main: hrMain,
        themeEditor: hrThemeEditor,
    },
    hu: {
        main: huMain,
        themeEditor: huThemeEditor,
    },
    id: {
        main: idMain,
        themeEditor: idThemeEditor,
    },
    it: {
        main: itMain,
        themeEditor: itThemeEditor,
    },
    ja: {
        main: jaMain,
        themeEditor: jaThemeEditor,
    },
    ms: {
        main: msMain,
        themeEditor: msThemeEditor,
    },
    nl: {
        main: nlMain,
        themeEditor: nlThemeEditor,
    },
    // Support all norwegian codes
    no: {
        main: noMain,
        themeEditor: noThemeEditor,
    },
    nb: {
        main: noMain,
        themeEditor: noThemeEditor,
    },
    nn: {
        main: noMain,
        themeEditor: noThemeEditor,
    },
    // End of norwegian.
    pl: {
        main: plMain,
        themeEditor: plThemeEditor,
    },
    pt: {
        main: ptMain,
        themeEditor: ptThemeEditor,
    },
    ro: {
        main: roMain,
        themeEditor: roThemeEditor,
    },
    ru: {
        main: ruMain,
        themeEditor: ruThemeEditor,
    },
    sk: {
        main: skMain,
        themeEditor: skThemeEditor,
    },
    sv: {
        main: svMain,
        themeEditor: svThemeEditor,
    },
    sr: {
        main: srMain,
        thThemeEditor: srThemeEditor,
    },
    th: {
        main: thMain,
        themeEditor: thThemeEditor,
    },
    tr: {
        main: trMain,
        themeEditor: trThemeEditor,
    },
    vi: {
        main: viMain,
        themeEditor: viThemeEditor,
    },
    lv: {
        main: lvMain,
        themeEditor: lvThemeEditor,
    },
    ko: {
        main: koMain,
        themeEditor: koThemeEditor,
    },
    "zh-CN": {
        main: zhCNMain,
        themeEditor: zhCNThemeEditor,
    },
    "zh-TW": {
        main: zhTWMain,
        themeEditor: zhTWThemeEditor,
    },
}

// Get the code for the current language / locale
export const getCurrentLanguageCode = () => {
    let locale = "en"
    if (i18n.languages) {
        locale = i18n.languages.find(lang => Object.keys(supportedLanguages).indexOf(lang) > -1)
            || locale
    }
    return locale
}

// Callback for configureI18n function, once the browser language has been found and set by
// i18n we can use it to configure momentJS and set custom text per locale.
const configureMomentJS = () => {
    let locale = getCurrentLanguageCode()
    // Support all Norwegian codes
    if (locale === "no" || locale === "nb") {
        locale = "nn"
    }

    locale = locale.toLowerCase()

    // Set up custom locale
    moment.defineLocale("custom", {
        parentLocale: locale,
    })

    // Localize Moment JS
    moment.locale(locale)

    // Set up moment custom text
    let localeFile = locale
    if (locale === "zh-cn") {
        localeFile = "zh-CN"
    }
    moment.updateLocale(locale, {
        relativeTime: {
            future: get(supportedLanguages, `${localeFile}.main.momentFuture`, "%s left"),
            past: get(supportedLanguages, `${localeFile}.main.momentPast`, "Expired %s ago"),
        },
    })
}

const configureI18n = () => i18n
    .use(LanguageDetector)
    .init({
        detection: { order: ["navigator"] },
        resources: supportedLanguages,
        fallbackLng: "en",
        react: {
            useSuspense: false
        }
    }, configureMomentJS)

export const languageIsRTL = () => {
    const { languages } = constants
    let selectedLng = localStorage.getItem("i18nextLng") || "en"
    selectedLng = selectedLng.split("-")[0].toLowerCase()
    if (!languages[selectedLng]) {
        selectedLng = "en"
    }
    const { rtl } = languages[selectedLng]
    const htmlTag = document.getElementsByTagName("html")[0]
    htmlTag.setAttribute("lang", selectedLng)
    if (rtl) {
        htmlTag.setAttribute("dir", "rtl")
    } else {
        htmlTag.setAttribute("dir", "ltr")
    }

    return rtl
}

export const changeLanguage = (code) => {
    if (!code) {
        i18n.changeLanguage(navigator ? navigator.language : "en", configureMomentJS)
        return
    }
    i18n.changeLanguage(code, configureMomentJS)
}

export default configureI18n
