import get from "lodash/get"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { SELECT_COURSE, selectSelectedCourse } from "../courses"
import { SELECT_LANGUAGE } from "../languages"

// Required variables
const initialState = {
    /* Holds the user redux object */
    data: [],
    /* Are we loading new informationCategories items */
    isLoading: false,
    /* Loading informationCategories items resulted in an error */
    loadingError: null,
}
export const reducerName = "informationCategories"

// Actions
export const CATEGORIES = createActionName(reducerName, "CATEGORIES")

// Action creators
export function createLoadingAction() {
    return (dispatch, getState) => {
        const course = selectSelectedCourse(getState())

        dispatch({
            type: CATEGORIES,
            request: {
                method: "get",
                url: `courses/${course.id}/information_categories`,
            },
        })
    }
}

// Selectors
export const selectInformationCategories = state => get(state, `${reducerName}.data`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectCategoryName = (state, catId) => {
    const categories = get(state, `${reducerName}.data`)
    let categoryName = ""
    categories.map((category) => {
        if (parseInt(category.id, 10) === parseInt(catId, 10)) {
            categoryName = category.title
        }
        return null
    })
    return categoryName
}
export const selectCategoryType = (state, catId) => {
    const categories = get(state, `${reducerName}.data`)
    let categoryType = ""
    categories.map((category) => {
        if (parseInt(category.id, 10) === parseInt(catId, 10)) {
            categoryType = category.type
        }
        return null
    })
    return categoryType
}

// Reducer
const reducers = {
    [CATEGORIES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [CATEGORIES + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [CATEGORIES + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SELECT_COURSE]() {
        return initialState
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
