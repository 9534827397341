import { IMAGE_UPDATE, USER, USER_UPDATE, GET_FAVOURITES,CONFIRM_POPUP_REQUEST, SET_MANAGER_TUTORIAL_SEEN, DATA_DELETION_REQUEST, RESET_STATUS_DATA_DELETION_REQUEST } from "./index"
import { selectPolicyData } from "./selectors"
import { selectSelectedCourse } from "../courses"

export function createLoadUserAction(courseId) {
    return {
        type: USER,
        request: {
            method: "get",
            url: `courses/${courseId}/profile`,
        },
    }
}

export function createUpdateUserAction(courseId, data) {
    return {
        type: USER_UPDATE,
        request: {
            method: "post",
            url: `courses/${courseId}/profile`,
            data,
        },
    }
}

export function createAcceptPolicyAction() {
    return (dispatch, getState) => {
        const state = getState()
        const { id } = selectSelectedCourse(state)
        const { requiredPolicy } = selectPolicyData(state)

        const data = {
            policy_version_accepted: requiredPolicy,
        }

        return dispatch(createUpdateUserAction(id, data))
    }
}

export function createUpdateImageAction(courseId, data) {
    return {
        type: IMAGE_UPDATE,
        request: {
            method: "post",
            url: `courses/${courseId}/profile`,
            data,
        },
    }
}

export function createGetFavouritesAction(courseId, searchTerm) {
    const url = searchTerm ?
        `courses/${courseId}/favorites?search=${searchTerm}` : `courses/${courseId}/favorites`

    return {
        type: GET_FAVOURITES,
        request: {
            method: "get",
            url,
        },
    }
}

export function createSetManagerTutorialSeenAction() {
    return ({
        type: SET_MANAGER_TUTORIAL_SEEN,
        request: {
            method: "post",
            url: "manager/tutorial",
            data: { tutorial_seen: true },
        },
    })
}

export function createSendDataDeletionRequest() {
    return ({
        type: DATA_DELETION_REQUEST,
        request: {
            method: "post",
            url: "users/delete_my_data",
        },
    })
}

export function createResetDataDeletionRequestion() {
    return ({
        type: RESET_STATUS_DATA_DELETION_REQUEST,
    })
}
export function createConfirmChecklistPopupAction() {
    return ({
        type: CONFIRM_POPUP_REQUEST,
        request: {
            method: "put",
            url: `users/checklist_info_popup_seen`,
        },
    })
}

