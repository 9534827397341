import memoize from "memoize-one"

export const getOneToOnes = memoize(previews => (
    previews.reduce((obj, preview) => {
        if (preview.other_users && preview.other_users.length === 2) {
            const key = preview.other_users.find(user => !user.is_self).user_id
            obj[key] = preview
        }
        return obj
    }, {})
))

export default {
    getOneToOnes,
}
