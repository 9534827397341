const constants = {
    trackingID: process.env.REACT_APP_ENV === "prod" ? "UA-29133681-11" : "UA-29133681-12",

    // getPage function will decide which page view to send to analytics, using these values
    pageViews: {
        loginScreen: "login_screen",
        recoverPasswordScreen: "recover_password_screen",
        courseListScreen: "course_list",
        checkinTabScreen: "checkin_tab",
        journeyTabScreen: "journey_tab",
        knowledgeTabScreen: "knowledge_tab",
        managerTabScreen: "manager_tab",
        userProfileScreen: "user_profile",
        editUserScreen: "edit_user_profile",
        languageScreen: "language_selector",
        storiesScreen: "story",
        favouritesScreen: "user_favourites",
        notificationsScreen: "user_notifications",
        hrFormScreen: "hr_forms",
        messagesScreen: "messages",
    },

    // Event Actions
    events: {
        openedChapter: "Opened_chapter",
        finishedChapter: "Finished_chapter",
        openedStory: "Opened_story",
        openedWelcomeVideo: "Opened_video_welcome_message",
        checkedChecklistItem: "Checked_checklist_item",
        uncheckedChecklistItem: "Unchecked_checklist_item",
        pressedInfoSpot: "Pressed_info_spot",
        favoritedPage: "Favorited_page",
        unfavoritedPage: "Unfavorited_page",
        openedKnowledgeItem: "Opened_knowledge_item",
        openedEditProfile: "Opened_edit_profile",
        edited_first_name: "Edited_first_name",
        edited_last_name: "Edited_last_name",
        edited_phone_number: "Edited_phone_number",
        edited_linkedin_url: "Edited_LinkedIn",
        edited_skype: "Edited_Skype",
        edited_description: "Edited_bio",
        calledColleague: "Called_a_colleague",
        emailedColleague: "Emailed_a_colleague",
        facebookColleague: "Checked_Facebook_of_colleague",
        instagramColleague: "Checked_Instagram_of_colleague",
        linkedInColleague: "Checked_LinkedIn_of_colleague",
        skypeColleague: "Checked_Skype_of_colleague",
        twitterColleague: "Checked_Twitter_of_colleague",
        openedMoreCoursesList: "Opened_more_courses_list",
        recoveredPassword: "Recovered_a_password",
        selectedCourse: "Selected_a_course",
        loggedOut: "Logged_out",
        switchToOldPlayer: "Switched_to_old_player",
        finishedStory: "Finished_story",

        editedManagerIntroduction: "Edited_manager_introduction",
        openedTipsAndTricks: "Opened_tips_and_tricks",
        viewedNewHireProfile: "Viewed_a_new_hires_profile",
        viewedNewHireProgress: "Viewed_a_new_hires_progress",
        viewedNewHireChecklist: "Viewed_a_new_hires_checklist",
        expandedNewHireProgress: "Expanded_a_new_hires_course_progress",
        searchedNewHires: "Searched_for_a_new_hire",
        acceptedNewHire: "Accepted_a_new_hire_request",
        rejectedNewHire: "Rejected_a_new_hire_request",
        openedTutorial: "Opened_the_manager_section_tutorial",

        createdConversation: "Create_new_conversation",
        createdGroup: "Create_group",
        selectGroupAvatar: "Select_group_avatar",
        sendMessage: "Send_message",
        sendAttachment: "Send_attachments",
        sendImage: "Send_image_attachments",
        sendGif: "Send_gif_attachments",
        sendPDF: "Send_pdf_attachments",
        searchMessages: "Search_message",
        searchColleagues: "Search_colleague",
        editGroup: "Edit_group",
        editGroupAvatar: "Edit_group_avatar",
        editGroupName: "Edit_group_name",
        editGroupMembers: "Edit_group_participant_list",
        removeParticipant: "Remove_participant",
        addParticipant: "Add_participant",
        removeGroup: "Remove_group",
        removeConversation: "Remove_my_conversation",

        submitFormAnswers: "Submit_form_answers",
        closeHrForm: "Close_hr_form",
        showHrForm: "Show_hr_form",
        useNavigationButtons: "Use_navigation_buttons",
    },

    // Event categories
    categories: {
        user: "User",
        story: "Story",
        chapter: "Chapter",
        checklist: "Checklist",
        courses: "Courses",
        knowledge: "Knowledge",
        colleagues: "Colleagues",
        manager: "Manager",
        messages: "Messages",
        hrForms: "HR_forms",
    },
}

export default constants
