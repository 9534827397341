import React from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { createBrowserHistory } from "history"
import persistStore from "redux-persist/lib/persistStore"
import { PersistGate } from "redux-persist/integration/react"
import { I18nextProvider } from "react-i18next"
import injectSheet from "react-jss"

// MUI Picker Provider
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider"
import MomentUtils from "material-ui-pickers/utils/moment-utils"

import configureI18n from "./configure/i18n"
import configureStore from "./redux/configureStore"
import Loading from "./lib/views/Loading/Loading"
import appicalTheme from "./ThemeProvider/appicalTheme"
import ThemeProvider from "./ThemeProvider"
import animations from "./lib/styles/animations"
import CustomFontProvider from "./lib/views/CustomFont/CustomFontProvider"

// eslint-disable-next-line no-undef,no-underscore-dangle
const initialState = window.__INITIAL_STATE__
const history = createBrowserHistory()

const store = configureStore(initialState, history)
const persistor = persistStore(store)
const i18n = configureI18n()

// Reset persisted state. DEBUG only.
// persistor.purge()

const AppProviderWrapper = ({ children }) => (
    <Provider store={store}>
        <ThemeProvider theme={appicalTheme}>
            <CustomFontProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <I18nextProvider i18n={i18n}>
                        <PersistGate loading={<Loading className="test" />} persistor={persistor}>
                            <ConnectedRouter history={history}>
                                {children}
                            </ConnectedRouter>
                        </PersistGate>
                    </I18nextProvider>
                </MuiPickersUtilsProvider>
            </CustomFontProvider>
        </ThemeProvider>
    </Provider>
)

AppProviderWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default injectSheet({
    ...animations,
})(AppProviderWrapper)
