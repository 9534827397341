import get from "lodash/get"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { SELECT_COURSE } from "../courses"
import { SELECT_LANGUAGE } from "../languages"

// Required variables
const initialState = {
    selectChapter: null,
    /* Holds the data */
    data: [],
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
}
export const reducerName = "chapters"

// Actions
export const CHAPTERS = createActionName(reducerName, "CHAPTERS")
export const SELECT_CHAPTER = createActionName(reducerName, "SELECT_CHAPTER")

// Action creators
export function createLoadingAction(storyId, callback) {
    return {
        type: CHAPTERS,
        request: {
            method: "get",
            url: `stories/${storyId}/chapters`,
        },
        callback: (dispatch, getState, payload) => {
            if (typeof callback === "function") {
                callback(payload.result)
            }
        },
    }
}

export function createSelectChapterAction(chapterId) {
    return {
        type: SELECT_CHAPTER,
        payload: chapterId,
    }
}

// Selectors
export const selectChapters = state => get(state, `${reducerName}.data`)
export const selectSelectedChapter = state => get(state, `${reducerName}.selectedChapter`)
export const selectChapter = (state, chapterId) => {
    const chapters = get(state, `${reducerName}.data`)
    return chapters.find(item => item.id === parseInt(chapterId, 10))
}
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)

// Reducer
const reducers = {
    [CHAPTERS + LOADING](state) {
        return {
            ...state, isLoading: true, loadingError: null, selectedChapter: null,
        }
    },
    [CHAPTERS + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [CHAPTERS + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SELECT_CHAPTER](state, payload) {
        return { ...state, selectedChapter: payload }
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
    [SELECT_COURSE]() { return initialState },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
