import { destroy, SubmissionError } from "redux-form"
import { push as navigate } from "connected-react-router"

import once from "lodash/once"
import get from "lodash/get"
import client from "../../../configure/client"
import {
    LOGIN,
    LOGOUT,
    SET_REDIRECT,
    DROP_SSO_RESPONSE,
    SET_SSO_RESPONSE,
    REFRESH,
    REFRESHING,
    SET_SWITCHING_TOKEN,
    EXPIRED_PASSWORD_UPDATED,
    TWO_FACTOR_SUCCESS,
    SET_GCM_TOKEN,
    SET_GCM_TOKEN_REQUEST,
    DELETE_GCM_TOKEN,
    selectAuthToken,
    selectGCMToken,
    SESSION_EXPIRED,
} from "./index"
import { LOADED } from "../../middleware/actions"

// Action creators
export function createRedirectUrlAction(url) {
    return { type: SET_REDIRECT, payload: url }
}

export function createSsoResponseAction(ssoData) {
    return { type: SET_SSO_RESPONSE, payload: ssoData }
}

export function createDropSsoResponseAction() {
    return { type: DROP_SSO_RESPONSE }
}

export function createLoginAction(authentication) {
    return { type: LOGIN, payload: authentication }
}

export function createRefreshingAction() {
    return { type: REFRESHING }
}

export function createRefreshAction(authentication) {
    return { type: REFRESH, payload: authentication }
}

export function createSetSwitchingTokenAction(switchingToken) {
    return { type: SET_SWITCHING_TOKEN, payload: switchingToken }
}

export function createExpiredPasswordUpdatedAction() {
    return { type: EXPIRED_PASSWORD_UPDATED }
}

export function create2FASuccessAction() {
    return { type: TWO_FACTOR_SUCCESS }
}
export function createSetGCMTokenAction(token) {
    return (dispatch) => {
        dispatch({
            type: SET_GCM_TOKEN,
            payload: token,
        })

        dispatch({
            type: SET_GCM_TOKEN_REQUEST,
            request: {
                method: "POST",
                url: "account/gcm_token",
                data: {
                    token,
                    os: "web",
                    notification_version: 2,
                },
            },
        })
    }
}
export function createDeleteGCMTokenAction() {
    return (dispatch, getState) => {
        const currentGCMToken = selectGCMToken(getState())

        if (currentGCMToken) {
            dispatch({
                type: DELETE_GCM_TOKEN,
            })
        }
    }
}

// Async networking
export function ssoStart(formData) {
    return (dispatch) => {
        const data = Object.assign(formData, { new_player: 1 })

        return client.post("connect/sso_url", data)
            .then((result) => {
                // Redirect to the SSO login page provided by the endpoint.
                // eslint-disable-next-line no-undef
                window.location = result.data.endpoint
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err)
                dispatch(createSsoResponseAction({ email: formData.email, data: null }))
                // throw new SubmissionError({ _error: "Something went wrong" })
            })
    }
}

export function login(formData) {
    return (dispatch) => {
        const postData = {
            ...formData,
            grant_type: "password",
            scope: "offline_access",
        }

        return client.post("connect/token", postData)
            .then((result) => {
                dispatch(createLoginAction(result.data))

                // We have to manually destroy the redux form because
                // we want to persist the values to the password reset screen.
                dispatch(destroy("login"))
            })
            .catch((err) => {
                const error = get(err, "response.data.error")
                const errorMessages = {
                    account_locked: "main:account_locked_error",
                    account_no_course: "main:account_no_course_error",
                    account_archived: "main:account_archived_error",
                    default: "main:login_error",
                }
                const errorMessage = errorMessages[error] || errorMessages.default
                throw new SubmissionError({ password: errorMessage, _error: "main:generic_error" })
            })
    }
}

export function submitTwoFactorAuthentication(formData) {
    return (dispatch, getState) => {
        const authToken = selectAuthToken(getState())
        const data = {
            ...formData,
            disable_checked: get(formData, "disable_checked", false),
        }

        return client.request({
            method: "post",
            url: "connect/2fa",
            data,
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                dispatch(create2FASuccessAction())
            })
            .catch(() => {
                throw new SubmissionError({ login_code: "main:two_factor_auth_err" })
            })
    }
}

export function logout() {
    return (dispatch, state) => {
        const currentToken = selectAuthToken(state())
        const currentGCMToken = selectGCMToken(state())

        const doLogout = () => {
            dispatch({
                type: LOGOUT,
                request: {
                    method: "delete",
                    url: "account/logout",
                },
            })
        }

        if (currentGCMToken) {
            client.request({
                method: "delete",
                url: "account/gcm_token",
                data: {
                    token: currentGCMToken,
                    os: "web",
                },
                headers: { Authorization: `Bearer ${currentToken}` },
            }).then(() => {
                dispatch({ type: DELETE_GCM_TOKEN })
                doLogout()
            }).catch(() => {
                // Always log out even if this GCM call fails!!!!
                doLogout()
            })
        } else {
            doLogout()
        }
    }
}

export const sessionExpired = () => ({
    type: SESSION_EXPIRED,
})

export function executeTokenRefresh(refreshToken, dispatch, state, actionAfterRefresh) {
    const currentToken = selectAuthToken(state)
    const postData = {
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        scope: "offline_access",
    }

    if (!refreshToken) return dispatch(logout())

    dispatch(createRefreshingAction())

    return client.post("connect/token", postData)
        .then((result) => {
            dispatch(createRefreshAction(result.data))
            // If there is a function that needs to be called with the new
            // authentication token, call it now.
            if (actionAfterRefresh) {
                actionAfterRefresh(result.data.access_token)
            }
            return result.data.access_token
        })
        .catch((error) => {
            // the session has expired and the user needs to be logged out from the platform
            if (get(error, "response.status") === 440) {
                dispatch(navigate("/session-expired"))
                dispatch(sessionExpired())
                dispatch({
                    type: LOGOUT + LOADED,
                })
                return
            }

            if (currentToken !== selectAuthToken(state)) {
                // The token was already refreshed by another
                // request triggered at the same time.
                return
            }

            // Token refresh failed. Log the user out
            dispatch(logout())
        })
}

let refreshFunction = null

export function tokenRefresh(refreshToken, actionAfterRefresh) {
    return (dispatch, state) => {
        if (!refreshFunction) { // Ensure we only send one token refresh call at a time
            refreshFunction = once(executeTokenRefresh)
        }

        return refreshFunction(refreshToken, dispatch, state, actionAfterRefresh)
            .then((newToken) => {
                refreshFunction = null
                return newToken
            })
            .catch(() => {
                refreshFunction = null
            })
    }
}
