import get from "lodash/get"
import { reducerName } from "./index"

export const selectChecklist = (state) => {
    const checklist = get(state, `${reducerName}.data`)
    const mergedChecklist = {}
    const categories = []

    // If the checklist has not yet loaded, or the checklist is already merged, do nothing
    if (!checklist.checklists || !checklist.checklists.length) {
        return checklist
    }

    // If there are no sub categories, return one nameless category
    if (!checklist.sub_categories || !checklist.sub_categories.length) {
        mergedChecklist.sub_categories = [{
            id: 1,
            title: "",
            items: checklist.checklists,
        }]
    }

    // If there are any items with no category, add them to the checklist first under
    // a nameless category
    const itemsWithNoCategory = checklist.checklists.filter(item => (
        !item.checklist_category_id
    ))
    if (itemsWithNoCategory.length) {
        categories.push({
            id: `unnamedCategory${Date.now()}`,
            title: "",
            items: itemsWithNoCategory,
        })
    }

    // Merge the checklist items into their subcategories
    checklist.sub_categories.map((subCategory) => {
        const mergedSubcategory = { ...subCategory }
        mergedSubcategory.items = checklist.checklists.filter(item => (
            item.checklist_category_id === mergedSubcategory.id
        ))
        return categories.push(mergedSubcategory)
    })

    mergedChecklist.checklist_title = checklist.checklist_title
    mergedChecklist.categories = categories

    return mergedChecklist
}
export const checkedItems = (state) => {
    const checklist = get(state, `${reducerName}.data`)
    if (checklist && checklist.checklists) {
        let numberCheckedItems = checklist.checklists.filter(item => item.checked === true).length;
        return { "checked": numberCheckedItems, "unchecked": checklist.checklists.length - numberCheckedItems }
    }
    return { "checked": null, "unchecked": null }
}
export const checklistPreferences = (state) => {
    const checklistPreferences = get(state, `${reducerName}.preferences.show_completed_checklist_items`)
    return checklistPreferences

}
export const checklistExists = (state) => {
    const checklist = get(state, `${reducerName}.data`)
    if (checklist.checklists && checklist.checklists.length) {
        return true
    }
    return false
}
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
