import get from "lodash/get"
import uniqBy from "lodash/uniqBy"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { SELECT_COURSE } from "../courses"
import { SELECT_LANGUAGE } from "../languages"

// Required variables
const initialState = {
    data: {},
    isLoading: false,
    loadingError: null,
    hasFetched: false,
    currentCategory: null,
    uploadingImage: false,
    uploadingFile: false,
    hasSubmitted: false,
    submitting: false,
}
export const reducerName = "hrForm"

// Actions
export const HR_FORM = createActionName(reducerName, "HR_FORM")
export const SAVE = createActionName(reducerName, "SAVE")
export const SAVE_REQUEST = createActionName(reducerName, "SAVE_REQUEST")
export const SUBMIT = createActionName(reducerName, "SUBMIT")
export const SUBMIT_IMAGE = createActionName(reducerName, "SUBMIT_IMAGE")
export const SUBMIT_FILE = createActionName(reducerName, "SUBMIT_FILE")

// Action creators
export * from "./actionCreators"

// Selectors
export * from "./selectors"

// Reducer
const reducers = {
    [HR_FORM + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
            hasFetched: false,
        }
    },
    [HR_FORM + LOADED](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: payload.result,
            hasFetched: true,
        }
    },
    [HR_FORM + ERROR](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: payload.result,
            hasFetched: true,
        }
    },
    [SAVE](state, payload) {
        const currentQuestionId = payload.questionId
        const categories = get(state, "data.categories", [])
        const category = categories.find(
            ({ category_id }) => parseInt(category_id, 10) === parseInt(payload.categoryId, 10),
        )
        const newQuestions = category.category_questions.map((currentQuestion) => {
            let question = { ...currentQuestion }
            const newAnswer = payload.newAnswers.find(
                ({ question_id }) => question_id === question.id,
            )
            if (question && question.is_group && currentQuestion.id === currentQuestionId ) {
                const groupAnswers = payload.newAnswers
                question = {
                    ...question,
                    answer: {
                        ...question.answer,
                        ...groupAnswers,
                    },
                }
            }

            if (question.sub_question) {
                const subQuestion = question.sub_question.question
                const newSubAnswer = payload.newAnswers.find(
                    ({ question_id }) => question_id === subQuestion.id,
                )

                if (newSubAnswer) {
                    question = {
                        ...question,
                        sub_question: {
                            ...question.sub_question,
                            question: {
                                ...question.sub_question.question,
                                answer: {
                                    ...question.sub_question.question.answer,
                                    ...newSubAnswer,
                                },
                            },
                        },
                    }
                }
            }

            return {
                ...question,
                answer: {
                    ...question.answer,
                    ...newAnswer,
                },
            }
        })

        const newCategory = {
            ...category,
            category_questions: newQuestions,
        }

        return {
            ...state,
            data: {
                ...state.data,
                categories: uniqBy([newCategory, ...categories], "category_id"),
            },
        }
    },
    [SUBMIT_IMAGE + LOADING](state) {
        return {
            ...state,
            uploadingImage: true,
        }
    },
    [SUBMIT_IMAGE + LOADED](state) {
        return {
            ...state,
            uploadingImage: false,
        }
    },
    [SUBMIT_IMAGE + ERROR](state) {
        return {
            ...state,
            uploadingImage: false,
        }
    },

    [SUBMIT_FILE + LOADING](state) {
        return {
            ...state,
            uploadingFile: true,
        }
    },
    [SUBMIT_FILE + LOADED](state) {
        return {
            ...state,
            uploadingFile: false,
        }
    },
    [SUBMIT_FILE + ERROR](state) {
        return {
            ...state,
            uploadingFile: false,
        }
    },

    [SUBMIT + LOADING](state) {
        return {
            ...state,
            submitting: true,
        }
    },
    [SUBMIT + ERROR](state, payload) {
        return {
            ...state,
            submitting: false,
            loadingError: payload.result,
        }
    },
    [SUBMIT + LOADED](state) {
        return {
            ...state,
            submitting: false,
            hasSubmitted: true,
        }
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
    [SELECT_COURSE]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
