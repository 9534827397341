import "babel-polyfill"
import "canvas-toBlob"
import React from "react"
import ReactDOM from "react-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import Route from "react-router/Route"
import smoothscroll from "smoothscroll-polyfill"

import "./sentryConfig"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import AppProviderWrapper from "./AppProviderWrapper"
import App from "./App"
import pageTracker from "./analytics/pageTracker"
import "./analytics/initialize"
import "./index.css"

// Snowplow tracking
import SnowplowTracker from "./analytics/snowplowTracker"

smoothscroll.polyfill()
// eslint-disable-next-line no-undef,no-underscore-dangle
if (!window.MediaStream) window.MediaStream = () => null

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js") // Use the correct path
      .then((registration) => {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch((err) => {
        console.log("Service worker registration failed, error:", err);
      });
  } 

ReactDOM.render(
    (
        <AppProviderWrapper>
            <div className="App">
                <SnowplowTracker />
                <CssBaseline />
                <Route component={pageTracker(App)} />
            </div>
        </AppProviderWrapper>
    ),
    // eslint-disable-next-line no-undef
    document.getElementById("root"),
)

serviceWorkerRegistration.register()