import get from "lodash/get"
// import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { LOADED } from "../../middleware/actions"
import constants from "../../../constants"

// Required variables
const initialState = {
    /* Holds the user redux object */
    data: null,
    /* Retains if we checked the users email for sso */
    emailChecked: null,
    /* If the user is an sso user we can open the sso flow */
    ssoData: null,
    /* The url to redirect to after a successful login */
    redirectUrl: "/",
    /* Indicates that we are refreshing the token */
    isRefreshingAuthToken: false,
    /* Indicates that we are switching the token with another */
    switchingToken: false,
    /* Two factor authorization */
    twoFactorAuthorized: false,
    /* Indicates time that the token will expire */
    tokenExpirationTime: 0,
    /* Holds the current GCM Token */
    GCMToken: null,
}
export const reducerName = "authentication"

// Actions
export const SET_REDIRECT = createActionName(reducerName, "SET_REDIRECT")
export const SET_SSO_RESPONSE = createActionName(reducerName, "SET_SSO_RESPONSE")
export const DROP_SSO_RESPONSE = createActionName(reducerName, "DROP_SSO_RESPONSE")
export const LOGIN = createActionName(reducerName, "LOGIN")
export const LOGOUT = createActionName(reducerName, "LOGOUT")
export const REFRESHING = createActionName(reducerName, "REFRESHING")
export const REFRESH = createActionName(reducerName, "REFRESH")
export const SESSION_EXPIRED = createActionName(reducerName, "SESSION_EXPIRED")
export const SET_SWITCHING_TOKEN = createActionName(reducerName, "SET_SWITCHING_TOKEN")
export const EXPIRED_PASSWORD_UPDATED = createActionName(reducerName, "EXPIRED_PASSWORD_UPDATED")
export const TWO_FACTOR_SUCCESS = createActionName(reducerName, "TWO_FACTOR_SUCCESS")
export const INVALIDATE_TOKEN = createActionName(reducerName, "INVALIDATE_TOKEN")
export const SET_GCM_TOKEN = createActionName(reducerName, "SET_GCM_TOKEN")
export const SET_GCM_TOKEN_REQUEST = createActionName(reducerName, "SET_GCM_TOKEN_REQUEST")
export const DELETE_GCM_TOKEN = createActionName(reducerName, "DELETE_GCM_TOKEN")

// Action creators
export * from "./actionCreators"

// Selectors
export const selectIsLoggedIn = state => (!!get(state, `${reducerName}.data`))
export const selectAuthToken = state => get(state, `${reducerName}.data.access_token`)
export const selectRefreshToken = state => get(state, `${reducerName}.data.refresh_token`)
export const selectIsRefreshingAuthToken = state => get(state, `${reducerName}.isRefreshingAuthToken`)
export const selectIsSwitchingToken = state => get(state, `${reducerName}.switchingToken`)
export const selectEmailChecked = state => get(state, `${reducerName}.emailChecked`)
export const selectSsoData = state => get(state, `${reducerName}.ssoData`)
export const selectRedirectUrl = state => get(state, `${reducerName}.redirectUrl`)
export const selectRole = state => get(state, `${reducerName}.data.role`)
export const selectSuperUser = state => get(state, `${reducerName}.data.super_admin`)
export const selectHasExpiredPassword = state => get(state, `${reducerName}.data.expired_password`)
export const selectRequires2FA = state => get(state, `${reducerName}.data.two_fa_enabled`)
export const select2FAAuthorized = state => get(state, `${reducerName}.twoFactorAuthorized`)
export const selectTokenExpirationTime = state => get(state, `${reducerName}.tokenExpirationTime`)
export const selectNeedsStrongPassword = state => get(state, `${reducerName}.data.strong_password`)
export const selectGCMToken = state => get(state, `${reducerName}.GCMToken`)
export const selectCanAccessEditor = (state) => {
    const userRole = get(state, `${reducerName}.data.role`)
    const { viewer, manager } = constants.userRoles

    return userRole !== viewer && userRole !== manager
}

// Reducer
const reducers = {
    [SET_REDIRECT](state, payload) {
        return { ...state, redirectUrl: payload }
    },
    [SET_SSO_RESPONSE](state, payload) {
        if (state.data) { return state }
        return { ...state, emailChecked: payload.email, ssoData: payload.data }
    },
    [DROP_SSO_RESPONSE](state) {
        if (state.data) { return state }
        return { ...state, emailChecked: null, ssoData: null }
    },
    [LOGIN](state, payload) {
        return {
            ...state,
            data: { ...payload },
            emailChecked: null,
            ssoData: null,
            tokenExpirationTime: Date.now() + (payload.expires_in * 1000),
        }
    },
    [REFRESHING](state) {
        return {
            ...state,
            refreshingAuthToken: true,
        }
    },
    [REFRESH](state, payload) {
        return {
            ...state,
            data: { ...state.data, ...payload },
            refreshingAuthToken: false,
            tokenExpirationTime: Date.now() + (payload.expires_in * 1000),
        }
    },

    [SET_SWITCHING_TOKEN](state, payload) {
        const switchingToken = payload

        return {
            ...state,
            switchingToken,
        }
    },
    [EXPIRED_PASSWORD_UPDATED](state) {
        return {
            ...state,
            data: {
                ...state.data,
                expired_password: false,
            },
        }
    },
    [TWO_FACTOR_SUCCESS](state) {
        return {
            ...state,
            twoFactorAuthorized: true,
        }
    },
    [SET_GCM_TOKEN](state, payload) {
        return {
            ...state,
            GCMToken: payload,
        }
    },

    [DELETE_GCM_TOKEN](state) {
        return {
            ...state,
            GCMToken: null,
        }
    },

    // Reset reducers
    [SESSION_EXPIRED]() {
        return initialState
    },

    [LOGOUT + LOADED]() {
        return initialState
    },

    // DEBUG
    [INVALIDATE_TOKEN](state) {
        return {
            ...state,
            data: {
                ...state.data,
                access_token: "INVALID",
            },
        }
    },
}

export const reducer = createReducer(reducers, initialState)

// No need to register because authentication is always registered
// reducerRegistery.register(reducerName, reducer)
