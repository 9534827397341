/* eslint no-undef: 0 */
/* eslint no-unused-vars: 0 */
import get from "lodash/get"

export const isPushSupported = "serviceWorker" in navigator && "PushManager" in window

export function handleNotification(payload, loadNotifications, history) {
    function showNotification() {
        const { notification, data } = payload

        const company = process.env.REACT_APP_COMPANY_NAME
        const icon = `/images/${company}/192x192.png`

        const title = get(notification, "title", company)
        const options = {
            body: (notification && notification.body) || "",
            icon,
            vibrate: [
                500,
                110,
                500,
                110,
                450,
                110,
                200,
                110,
                170,
                40,
                450,
                110,
                200,
                110,
                170,
                40,
                500,
            ],
        }

        const pushNotification = new Notification(title, options)
        pushNotification.onclick = () => {
            // Try to extract course from notification data
            const notificationCourse = data && data["gcm.notification.course_id"]

            // Check to see if the course is in the URL
            let url = get(history, "location.pathname", "")
            const courseInURLRegex = /\/courses\/\d*\//
            const courseInUrl = courseInURLRegex.test(url)
            let courseId = null

            // If the course can be found in the notification data, redirect there
            if (notificationCourse) {
                courseId = notificationCourse
            } else if (courseInUrl) {
                // Otherwise if the course can be found from the url, redirect there
                if (url[0] === "/") {
                    url = url.substr(1)
                }
                // eslint-disable-next-line prefer-destructuring
                courseId = url.split("/")[1]
            }

            if (courseId !== null && !data) {
                history.push(`/courses/${courseId}/profile/notifications`)
            }

            if (courseId !== null && data) {
                let location = `/courses/${courseId}/profile/notifications`
                const notificationType = data["gcm.notification.type"] || data.type
                const storyUnlockedNotification = notificationType === "Story unlocked"
                const storyId = data["gcm.notification.story_id"] || data.story_id

                if (storyUnlockedNotification && storyId) {
                    // Try to extract chapterId from notification data
                    const chapterId = data["gcm.notification.chapter_id"] || data.chapter_id
                    location = chapterId
                        ? `/courses/${courseId}/stories/${storyId}/chapters/${chapterId}`
                        : `/courses/${courseId}/stories/${storyId}`
                } else {
                    // Try to extract conversationId from notification data
                    const conversationId = data["gcm.notification.conversation_id"] || data.conversation_id
                    location = conversationId
                        ? `/courses/${courseId}/messages/${conversationId}`
                        : location
                }

                history.push(location)
            }

            // Always close the notification on click
            pushNotification.close()
        }
    }

    // Check if the browser supports notifications
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification")
        return
    }

    // Check whether notification permissions have already been granted
    if (Notification.permission === "granted") {
        // If it is granted, then create the notification
        showNotification()
        loadNotifications()
        return
    }

    // Otherwise, we need to ask the user for permission
    if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
            // If the user accepts, create the notification
            if (permission === "granted") {
                showNotification()
                loadNotifications()
            }
        })
    }
}
