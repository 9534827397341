import get from "lodash/get"
import has from "lodash/has"
import { reducerName } from "."

export const selectCategory = (state) => {
    const category = get(state, `${reducerName}.data`)
    const mergedCategory = {
        ...category,
        sub_categories: [],
    }

    // If the category has not yet loaded, do nothing
    if (!category.sub_categories) {
        return category
    }

    // Create the category items
    if (category.people && category.people.length) {
        mergedCategory.items = category.people.map(person => ({
            ...person,
            title: `${person.first_name} ${person.last_name}`,
            sub_title: person.job_title,
            img: person.image_url,
        }))
    } else {
        mergedCategory.items = category.faq
            || category.glossary
            || category.files
            || category.signings
            || []
    }

    // If there are no sub categories, return one nameless category
    if (!category.sub_categories.length) {
        mergedCategory.sub_categories.push({
            id: 1,
            title: "",
            items: mergedCategory.items,
        })
        return mergedCategory
    }

    // If there are items with no sub categories, merge them into a nameless sub category
    const itemsWithNoCategory = mergedCategory.items.filter(item => (
        !item.sub_category_id
    ))
    if (itemsWithNoCategory.length) {
        mergedCategory.sub_categories.push({
            id: `unnamedCategory${Date.now()}`,
            title: "",
            items: itemsWithNoCategory,
        })
    }

    // Merge the remaining items into their correct sub categories
    category.sub_categories.map((subCategory) => {
        const mergedSubcategory = { ...subCategory }
        mergedSubcategory.items = mergedCategory.items.filter(item => (
            item.sub_category_id === mergedSubcategory.id
        ))
        if (!mergedSubcategory.items.length) return null
        return mergedCategory.sub_categories.push(mergedSubcategory)
    })

    return mergedCategory
}
export const selectItemFromCategory = (state, itemId) => {
    const category = selectCategory(state)
    const { items } = category

    if (get(state, `${reducerName}.isLoading`) || !items) return {}

    return items.filter(item => (
        (item.id && parseInt(item.id, 10) === parseInt(itemId, 10))
        || (item.title && item.title === itemId)
    ))[0]
}
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectIsSigningsFiles = state => has(get(state, `${reducerName}.data`), "signings")
export const selectSigningsFile = state => get(state, `${reducerName}.signings`)
