import React, { Suspense } from "react"
import lazyWithRetry from "./LazyRetry"
import PropTypes from "prop-types"
import { Switch, Route } from 'react-router-dom'
import Redirect from "react-router-dom/Redirect"
import Loading from "../lib/views/Loading/Loading"
import constants from "../constants"

const NotFoundPage = lazyWithRetry(() => import("../lib/views/NotFoundPage/NotFoundPage"))
const RouteLogin = lazyWithRetry(() => import("./RouteLogin"))
const RouteCourses = lazyWithRetry(() => import("./RouteCourses"))
const RouteRoles = lazyWithRetry(() => import("./RouteRoles"))
const RouteThemeEditor = lazyWithRetry(() => import("./RouteThemeEditor"))
const RouteCourse = lazyWithRetry(() => import("./RouteCourse"))
const RouteSwitch = lazyWithRetry(() => import("./RouteSwitch"))
const RouteCookiePolicy = lazyWithRetry(() => import("./RouteCookiePolicy"))
const RouteInviteExisting = lazyWithRetry(() => import("./RouteInviteExisting"))
const RouteInvite = lazyWithRetry(() => import("./RouteInvite"))
const RouteResetPassword = lazyWithRetry(() => import("./RouteResetPassword"))
const RouteSessionExpired = lazyWithRetry(() => import("./RouteSessionExpired"))
const LoginRedirect = lazyWithRetry(() => import("./LoginRedirect"))
const RouteUserDataDeletion = lazyWithRetry(() => import("./RouteUserDataDeletion"))

const AppRoutes = ({
    isLoggedIn, requires2FA, twoFactorAuthorized, hasExpiredPassword, redirectUrl,
}) => {
    // When not logged in, a user can only see login and invite related screens.
    if (!isLoggedIn) {
        return (
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/login" render={props => <RouteLogin {...props} />} />
                    <Route path="/cookie_policy" render={props => <RouteCookiePolicy {...props} />} />
                    <Route path="/session-expired" render={props => <RouteSessionExpired {...props} />} />
                    <Route path="/reset-password" render={props => <RouteResetPassword {...props} />} />
                    <Route path="/users/password" render={props => <RouteResetPassword fromEmail {...props} />} />
                    <Route path="/invite" render={props => <RouteInvite {...props} />} />
                    <Route path="/invite_existing_user" render={props => <RouteInviteExisting {...props} />} />
                    <Route path="/switch" hash="#:token" render={props => <RouteSwitch {...props} />} />
                    <Route path={constants.userDataDeletionUrl} render={props => <RouteUserDataDeletion {...props} />} > {/* /user-data-deletion */}
                        <Route path='/verified' render={props => <RouteUserDataDeletion {...props} />} /> {/* /user-data-deletion/verified */}
                    </Route>
                    <Route render={props => <LoginRedirect {...props} />} />
                </Switch>
            </Suspense>
        )
    }

    // If the user is logged in but still needs to pass the two factor authentication (2FA),
    // display only the 2FA form screen.
    if (requires2FA && !twoFactorAuthorized) {
        return (<Suspense fallback={<Loading />}><RouteLogin requires2FA /></Suspense>)
    }

    // If the user is logged in and has passed any required 2FA but their password has expired
    // and needs to be reset, display only the reset password screen allowing them to update
    // their password.
    if (hasExpiredPassword) {
        return (<Suspense fallback={<Loading />}><RouteResetPassword expiredPassword /></Suspense>)
    }

    // If the user is logged in, passed any required 2FA and has a non expired passowrd, allow
    // them to use the app!
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route path="/theme-editor" component={RouteThemeEditor} exact />
                <Route path="/cookie_policy" component={RouteCookiePolicy} />
                <Route path="/roles" component={RouteRoles} exact />
                <Route path="/courses" component={RouteCourses} exact />
                <Route path="/messages" component={props => <RouteCourses toMessages {...props} />} />
                <Route path="/courses/:courseId/:selectedTab" component={RouteCourse} />
                <Route path="/invite_existing_user" component={RouteInviteExisting} />

                <Route path="/launchApp" render={() => <Redirect to="/courses" />} />

                {/* Redirect fallbacks */}
                {/* Redirect a course to the default tab */}
                <Route
                    path="/courses/:courseId/"
                    render={({ match: { params: { courseId } } }) => {
                        const home = courseId === "-1" ? "manager" : "dashboard"
                        return <Suspense fallback={<Loading />}><Redirect to={`/courses/${courseId}/${home}`} /></Suspense>
                    }}
                    exact
                />
                {/* Redirect to the users previous location after login */}
                <Route path="/login" render={() => <Redirect to={redirectUrl} />} />
                <Route path="/switch" hash="#:token" component={RouteSwitch} />
                <Route path="/redirect" render={() => <Redirect to={redirectUrl} />} />
                {/* Redirect index to courses as the main entrypoint. */}
                <Route path="/invite" render={() => <Redirect to="/roles" />} />
                <Route path="/users/password" render={() => <Redirect to="/roles" />} />
                <Route path="/" render={() => (<Redirect to="/roles" />)} exact />
                <Route path={constants.userDataDeletionUrl} render={props => <RouteUserDataDeletion {...props} />} > {/* /user-data-deletion */}
                    <Route path="verified" render={props => <RouteUserDataDeletion {...props} />} /> {/* /user-data-deletion/verified */}
                </Route>
                <Route component={NotFoundPage} />
            </Switch>
        </Suspense>
    )
}

AppRoutes.propTypes = {
    classes: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    requires2FA: PropTypes.bool,
    twoFactorAuthorized: PropTypes.bool,
    hasExpiredPassword: PropTypes.bool,
    redirectUrl: PropTypes.string.isRequired,
}
AppRoutes.defaultProps = {
    isLoggedIn: false,
    requires2FA: false,
    twoFactorAuthorized: false,
    hasExpiredPassword: false,
}

export default AppRoutes
