import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import orderBy from "lodash/orderBy"
import moment from "moment"
import { createSelector } from "reselect"

import { reducerName } from "./index"
import { calculateCategoryProgress } from "./helpers"
import constants from "../../../constants"

export const selectForm = state => get(state, `${reducerName}.data`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectHasFetched = state => get(state, `${reducerName}.hasFetched`)
export const selectUploadingImage = state => get(state, `${reducerName}.uploadingImage`)
export const selectUploadingFile = state => get(state, `${reducerName}.uploadingFile`)
export const selectHasSubmitted = state => get(state, `${reducerName}.hasSubmitted`)
export const selectIsSubmitting = state => get(state, `${reducerName}.submitting`)

export const selectCategories = (state) => {
    const categories = get(state, `${reducerName}.data.categories`, [])

    return categories.map((category) => {
        const progress = calculateCategoryProgress(category)

        return {
            ...category,
            progress,
        }
    })
}

export const selectOrderedCategories = createSelector(selectCategories, categories => orderBy(
    categories,
    [
        (category) => {
            const { progress } = category

            if (progress === 100) {
                return -1
            }

            return progress
        },
    ],
    ["desc"],
))

export const selectCategory = (state, categoryId) => {
    const categories = selectCategories(state)

    if (!categories || categories.length === 0 || !categoryId) return null

    return categories.find(
        category => parseInt(category.category_id, 10) === parseInt(categoryId, 10),
    )
}

export const selectCategoryPreviousAnswers = (state, categoryId) => {
    const category = selectCategory(state, categoryId)
    const questions = get(category, "category_questions")
    const previousAnswers = {}

    if (!category || !questions || questions.length < 1) return previousAnswers

    questions.forEach((question) => {
        if (!isEmpty(question.answer)) {
            let answer = question.answer.text_answer
                || question.answer.multiple_choice_answer
                || question.answer.image_url
            if (question.answer_type === constants.hrFormAnswerTypes.date) {
                answer = moment(answer).format("DD[/]MM[/]YYYY")
            }
            previousAnswers[`field-${question.id}`] = answer

            const isSubQuestion = get(question, "sub_question", null)
            if (isSubQuestion) {
                const subQuestion = question.sub_question.question
                let subAnswer = subQuestion.answer.text_answer
                    || subQuestion.answer.multiple_choice_answer
                    || subQuestion.answer.image_url
                if (subQuestion.answer_type === constants.hrFormAnswerTypes.date) {
                    subAnswer = moment(subAnswer).format("DD[/]MM[/]YYYY")
                }
                previousAnswers[`field-${subQuestion.id}`] = subAnswer
            }
        }
    })

    return previousAnswers
}

export const selectIsLastCategory = (state, categoryId) => {
    const categories = selectCategories(state).filter(
        ({ id }) => id !== categoryId,
    )
    if (!categories || categories.length === 0) return false

    const someCategoriesIncomplete = categories.some(
        ({ progress }) => progress !== 100,
    )

    return !someCategoriesIncomplete
}

export const selectAllAnswers = (state) => {
    const categories = selectCategories(state)
    const questions = categories.flatMap(
        ({ category_questions }) => category_questions,
    )
    const answers = questions
        .filter(
            ({ answer_type }) => answer_type !== constants.hrFormAnswerTypes.image,
        )
        .map(({ answer }) => answer)

    return answers
}

export const selectNextCategory = (state) => {
    const categories = selectCategories(state)
    const nextCategory = categories.find(category => category.progress < 100)

    return get(nextCategory, "category_id", null)
}
