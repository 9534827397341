import { currentCompany, publicUrl } from "../constants"

const customTheme = () => {
    switch (currentCompany) {
    case "nn": return {
        primary: "#EE7F00",
        contrast: "#EE7F00",
        contrastText: "#EE7F00",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "rgba(255, 255, 255, 0.7)",
        loginButton: "#EE7F00",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "0.7",
    }
    case "partou": return {
        primary: "#532950",
        contrast: "#ED6C84",
        contrastText: "#ED6C84",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#000000",
        loginButton: "#ED6C84",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#000000",
        resetPasswordButton: "#532950",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
    }
    case "tno": return {
        primary: "#000000",
        contrast: "#649EC9",
        contrastText: "#649EC9",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "rgba(0, 0, 0, 0.6)",
        loginButton: "#649EC9",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#649EC9",
        resetPasswordBg2: "#649EC9",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "rgba(0, 0, 0, 0.6)",
        resetPasswordButtonDark: null,
    }
    case "olvg": return {
        primary: "#0F3195",
        contrast: "#0F3195",
        contrastText: "#FFFFFF",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "rgba(0, 0, 0, 0.7)",
        loginButton: "#0F3195",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "rgba(0, 0, 0, 0.7)",
        resetPasswordButton: "#0F3195",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
    }
    case "ns": return {
        primary: "#FFC917",
        contrast: "#0079D3",
        contrastText: "#003082",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "rgba(0, 0, 0, 0.7)",
        loginButton: "#FFC917",
        loginButtonText: "rgba(0, 0, 0, 0.7)",
        loginButtonDark: null,

        resetPasswordBg1: "#FFC917",
        resetPasswordBg2: "#FFC917",
        resetPasswordBgText: "rgba(0, 0, 0, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "rgba(0, 0, 0, 0.7)",
        resetPasswordButtonDark: null,
    }
    case "heineken": return {
        primary: "#205527",
        contrast: "#205527",
        contrastText: "#205527",
        loginBg1: null,
        loginBg2: null,
        loginBgText: "#205527",
        loginButton: "#205527",
        loginButtonText: "rgba(255, 255, 255, 0.7)",
        loginButtonDark: null,
        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#205527",
        resetPasswordButtonText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "1",
    }
    case "vanlanschot": return {
        primary: "#00585b",
        contrast: "#FFFFFF",
        contrastText: "#00585b",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "rgba(255, 255, 255, 0.7)",
        loginButton: "#00585b",
        loginButtonText: "rgba(255, 255, 255, 0.7)",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#00585b",
        resetPasswordButtonText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
    }
    case "bakertilly": return {
        primary: "#002A3A",
        contrast: "#002A3A",
        contrastText: "#002A3A",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "rgba(0, 0, 0, 0.7)",
        loginButton: "#002A3A",
        loginButtonText: "rgba(255, 255, 255, 0.7)",
        loginButtonDark: null,

        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "rgba(0, 0, 0, 0.7)",
        resetPasswordButton: "#002A3A",
        resetPasswordButtonText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
    }
    case "landal": return {
        primary: "#82BA26",
        contrast: "#82BA26",
        contrastText: "#82BA26",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "rgba(255, 255, 255, 0.6)",
        loginButton: "#82BA26",
        loginButtonText: "rgba(255, 255, 255, 0.7)",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.6)",
        resetPasswordButton: "#82BA26",
        resetPasswordButtonText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "0.5",
    }
    case "rolandberger": return {
        primary: "#F5A241",
        contrast: "#0092AC",
        contrastText: "#0092AC",
        loginBg1: "#F5A241",
        loginBg2: "#F5A241",
        loginBgText: "rgba(0, 0, 0, 0.7)",
        loginButton: "#0092AC",
        loginButtonText: "rgba(255, 255, 255, 1)",
        loginButtonDark: null,

        resetPasswordBg1: "#0092AC",
        resetPasswordBg2: "#0092AC",
        resetPasswordBgText: "rgba(0, 0, 0, 0.7)",
        resetPasswordButton: "#F5A241",
        resetPasswordButtonText: "rgba(255, 255, 255, 1)",
        resetPasswordButtonDark: null,
    }
    case "hp": return {
        primary: "#0096D6",
        contrast: "#0096D6",
        contrastText: "rgba(0, 0, 0, 0.7)",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "rgba(0, 0, 0, 0.7)",
        loginButton: "#006FE6",
        loginButtonText: "rgba(255, 255, 255, 1)",
        loginButtonDark: null,

        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "rgba(0,0,0,0.7)",
        resetPasswordButton: "#0096D6",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
    }
    case "motion": return {
        primary: "#75C01F",
        contrast: "#00963F",
        contrastText: "#FFFFFF",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "#FFFFFF",
        loginButton: "#00963F",
        loginButtonText: "rgba(255, 255, 255, 1)",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#00963F",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.jpg)`,
    }
    case "dt": return {
        primary: "#C10F59",
        contrast: "#FFFFFF",
        contrastText: "#FFFFFF",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "#FFFFFF",
        loginButton: "#FFFFFF",
        loginButtonText: "#C10F59",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#C10F59",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
    }
    case "ep": return {
        primary: "#001489",
        contrast: "#001489",
        contrastText: "#001489",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "#FFFFFF",
        loginButton: "#80a0cb",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "0.7",
    }
    case "nn-cz": return {
        primary: "#ea650d",
        contrast: "#ea650d",
        contrastText: "#ea650d",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "rgba(255, 255, 255, 0.7)",
        loginButton: "#ea650d",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "0.8",
    }
    case "nn-sk": return {
        primary: "#EE7F00",
        contrast: "#EE7F00",
        contrastText: "#EE7F00",
        loginBg1: "#FFFFFF",
        loginBg2: "#000000",
        loginBgText: "rgba(255, 255, 255, 0.7)",
        loginButton: "#EE7F00",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "0.6",
    }
    case "nn-tr": return {
        primary: "#EE7F00",
        contrast: "#EE7F00",
        contrastText: "#EE7F00",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "rgba(255, 255, 255, 0.7)",
        loginButton: "#EE7F00",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "0.7",
    }
    case "nn-es": return {
        primary: "#EE7F00",
        contrast: "#EE7F00",
        contrastText: "#EE7F00",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#EE7F00",
        loginButton: "#EE7F00",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "rgba(255, 255, 255, 0.7)",
        resetPasswordButton: "#FFFFFF",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,
    }
    case "capgemini": return {
        primary: "#353F49",
        contrast: "#353F49",
        contrastText: "#96989A",
        loginBg1: "#ECECEC",
        loginBg2: "#ECECEC",
        loginBgText: "#000000",
        loginButton: "#0070AD",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#ECECEC",
        resetPasswordBg2: "#ECECEC",
        resetPasswordBgText: "rgba(0,0,0,0.7)",
        resetPasswordButton: "#0070AD",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImageOpacity: "1",
    }
    case "worldline": return {
        primary: "#353F49",
        contrast: "#353F49",
        contrastText: "#96989A",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#FFFFFF",
        loginButton: "#0070AD",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#0070AD",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,

        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.jpg)`,
        loginBackgroundImageOpacity: "1",
    }
    case "antonius": return {
        primary: "#353F49",
        contrast: "#353F49",
        contrastText: "#96989A",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#000000",
        loginButton: "#55283c",
        loginButtonText: "#FFFFFF",
        loginButtonDark: null,

        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#000000",
        resetPasswordButton: "#55283c",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
    }
    case "kpmg": return {
        primary: "#00BAB3",
        contrast: "#00BAB3",
        contrastText: "#fff",
        loginBg1: null,
        loginBg2: null,
        loginBgText: "#fff",
        loginButton: "#00BAB3",
        loginButtonText: "#FFFFFF",
        loginButtonDark: "#00BAB3",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#fff",
        resetPasswordButton: "#00BAB3",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "1",
    }
    case "legerdesheils": return {
        primary: "#e8231a",
        contrast: "#F4F2EF",
        contrastText: "#fff",
        loginBg1: null,
        loginBg2: "#FFFFFF",
        loginBgText: "#F4F2EF",
        loginButton: "#e8231a",
        loginButtonText: "#F4F2EF",
        loginButtonDark: "#e8231a",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#e8231a",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "1",
    }
    case "sanofi": return {
        primary: "rgba(0, 106, 255, 1)",
        contrast: "rgba(0, 106, 255, 1)",
        loginBg1: "rgba(255, 255, 255, 1)",
        loginBg2: null,
        loginBgText: null,
        loginButton: null,
        loginButtonText: null,
        loginButtonDark: null,

        resetPasswordBg1: "rgba(255, 255, 255, 1)",
        resetPasswordBg2: null,
        resetPasswordBgText: "rgba(0, 0, 0, 0.87)",
        resetPasswordButton: null,
        resetPasswordButtonText: null,
        resetPasswordButtonDark: null,
    }
    case "ministerievandefensie": return {
        primary: "#E17000",
        contrast: "#E17000",
        loginBg1: "#f9f9f9",
        loginBg2: "#f9f9f9",
        loginBgText: "#007BC7",
        loginButton: "#007BC7",
        loginButtonText: null,
        loginButtonDark: null,

        resetPasswordBg1: "#f9f9f9",
        resetPasswordBg2: "#f9f9f9",
        resetPasswordBgText: "rgba(0, 0, 0, 0.87)",
        resetPasswordButton: "#007BC7",
        resetPasswordButtonText: null,
        resetPasswordButtonDark: null,
    }
    case "asml": return {
        primary: "#273478",
        contrast: "#998D5C",
        contrastText: "#998D5C",
        loginBg1: null,
        loginBg2: "#998D5C",
        loginBgText: "#F4F2EF",
        loginButton: "#273478",
        loginButtonText: "#F4F2EF",
        loginButtonDark: "#273478",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#273478",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.jpeg)`,
        loginBackgroundImageOpacity: "1",
    }
    case "allianzpartners": return {
        primary: "#003781",
        contrast: "#007AB3",
        contrastText: "#007AB3",
        loginBg1: null,
        loginBg2: "#007AB3",
        loginBgText: "#003781",
        loginButton: "#003781",
        loginButtonText: "#F4F2EF",
        loginButtonDark: "#003781",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#003781",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "1",
    }
    case "swisslog": return {
        primary: "#D10007",
        contrast: "#004654",
        contrastText: "#004654",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#D10007",
        loginButton: "#D10007",
        loginButtonText: "#F4F2EF",
        loginButtonDark: "#D10007",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#FFFFFF",
        resetPasswordButton: "#D10007",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
        loginBackgroundImageOpacity: "1",
    }
    case "arkin": return {
        primary: "#edc500",
        contrast: "#968f82",
        contrastText: "#968f82",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#FFFFFF",
        loginButton: "#edc500",
        loginButtonText: "rgba(0, 0, 0, 0.87)",
        loginButtonDark: "#edc500",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "rgba(0, 0, 0, 0.87)",
        resetPasswordButton: "#edc500",
        resetPasswordButtonText: "rgba(0, 0, 0, 0.87)",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.jpeg)`,
        loginBackgroundImageOpacity: "1",
    }
    case "allianzgi": return {
        primary: "#003087",
        contrast: "rgba(0, 0, 0, 0.87)",
        contrastText: "rgba(0, 0, 0, 0.87)",
        loginBg1: "#FFFFFF",
        loginBg2: "#FFFFFF",
        loginBgText: "#FFFFFF",
        loginButton: "#003087",
        loginButtonText: "#FFFFFF",
        loginButtonDark: "#003087",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "rgba(0, 0, 0, 0.87)",
        resetPasswordButton: "#003087",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.jpeg)`,
        loginBackgroundImageOpacity: "1",
    }
    case "ergomed": return {
        primary: "#003265",
        contrast: "#003265",
        contrastText: "#fff",
        loginBg1: "#003265",
        loginBg2: "#003265",
        loginBgText: "#fff",
        loginButton: "#009EE0",
        loginButtonText: "#FFFFFF",
        loginButtonDark: "#009EE0",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#fff",
        resetPasswordButton: "#009EE0",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
    }  
    case "wijzijnjong": return {
        primary: "#009cdf",
        contrast: "#289448",
        contrastText: "#fffff",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "#FFFFFF",
        loginButton: "#009CDF",
        loginButtonText: "#FFFFFF",
        loginButtonDark: "#009CDF",
        resetPasswordBg1: "#FFFFFF",
        resetPasswordBg2: "#FFFFFF",
        resetPasswordBgText: "#ffffff",
        resetPasswordButton: "#009CDF",
        resetPasswordButtonText: "#FFFFFF",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.webp)`,
        loginBackgroundImageOpacity: "0.7",
    }
    case "vdlgroep": return {
        primary: "#002c51",
        contrast: "#f39200",
        contrastText: "#fff",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "#fff",
        loginButton: "#ffffff",
        loginButtonText: "#000",
        loginButtonDark: "#009EE0",
        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "#fff",
        resetPasswordButton: "#ffffff",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.webp)`,
        loginBackgroundImageOpacity: "0.7",
    }
    case "alterdomus": return {
        primary: "#E74315",
        contrast: "#6F9BB1",
        contrastText: "#fff",
        loginBg1: "#000000",
        loginBg2: "#000000",
        loginBgText: "#fff",
        loginButton: "#E74315",
        loginButtonText: "#fff",
        loginButtonDark: "#E74315",
        labelColor: "#ffffff",
        resetPasswordBg1: "#000000",
        resetPasswordBg2: "#000000",
        resetPasswordBgText: "#fff",
        resetPasswordButton: "#ffffff",
        resetPasswordButtonText: "#000000",
        resetPasswordButtonDark: null,
        loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.webp)`,
        loginBackgroundImageOpacity: "0.7",
    }
    default:
        return {
            primary: "rgba(0, 106, 255, 1)",
            contrast: "rgba(255, 108, 92, 1)",
            loginBg1: "rgba(255, 255, 255, 1)",
            loginBg2: null,
            loginBgText: null,
            loginButton: "rgba(0, 106, 255, 1)",
            loginButtonText: null,
            loginButtonDark: null,

            resetPasswordBg1: null,
            resetPasswordBg2: null,
            resetPasswordBgText: null,
            resetPasswordButton: null,
            loginBackgroundImage: `url(${publicUrl}/images/${currentCompany}/background.png)`,
            resetPasswordButtonText: null,
            resetPasswordButtonDark: null,

        }
    }
}

export default customTheme
