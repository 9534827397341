import axios from "axios"
import constants from "../constants"

export const configureClient = baseURL => axios.create({
    baseURL,
    responseType: "json",
    withCredentials: true,

    headers: {
        common: {
            "API-VERSION": "13",
            Accept: "application/json",
        },
        post: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    },
})

export default configureClient(constants.baseUrl)
