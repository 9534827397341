import get from "lodash/get"
import { SubmissionError } from "redux-form"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT, selectAuthToken, createExpiredPasswordUpdatedAction } from "../authentication"
import client from "../../../configure/client"

// Required variables
const initialState = {
    loginData: null,
    submittedRequest: false,
    needsStrongPassword: false,
    isLoading: false,
    loadingError: null,
}
export const reducerName = "resetPassword"

// Actions
export const SET_LOGIN_DATA = createActionName(reducerName, "SET_LOGIN_DATA")
export const SET_REQUEST_SUBMITTED = createActionName(reducerName, "SET_REQUEST_SUBMITTED")
export const CLEAR_DATA = createActionName(reducerName, "CLEAR_DATA")
export const CHECK_REQUIREMENTS = createActionName(reducerName, "CHECK_REQUIREMENTS")

// Action creators
export function createSetLoginDataAction(loginData) {
    return { type: SET_LOGIN_DATA, payload: loginData }
}
export function createSetRequestSubmittedAction() {
    return { type: SET_REQUEST_SUBMITTED }
}
export function createClearDataAction() {
    return { type: CLEAR_DATA }
}
export function createCheckPasswordRequirementsAction(token) {
    return ({
        type: CHECK_REQUIREMENTS,
        request: {
            method: "get",
            url: `/user/change_password/requirements?reset_password_token=${token}`,
        },
    })
}

// Async networking
export function changePassword(formData) {
    return function (dispatch) {
        return client.post("/user/change_password", formData)
            .then((result) => {
                dispatch(createSetLoginDataAction(result.data))
                dispatch(createSetRequestSubmittedAction())
            })
            .catch((error) => {
                const errorType = get(error, "response.data.error_description") || get(error, "response.data.error")

                if (errorType === "Reset password token is invalid") {
                    throw new SubmissionError({ password: "main:reset_password_token_error" })
                }
                if (errorType === "Reset password token has expired, please request a new one") {
                    throw new SubmissionError({ password: "main:reset_password_token_error" })
                }
                if (errorType === "Password was used previously.") {
                    throw new SubmissionError({ password: "main:reset_password_reuse_error" })
                }
                if (errorType === "Your account has been de-activated. Please contact your manager if you would like to regain access to your account.") {
                    throw new SubmissionError({ password: "main:reset_password_no_course_error" })
                }

                throw new SubmissionError({ password: "main:generic_error" })
            })
    }
}

export function changeExpiredPassword(data) {
    return function (dispatch, getState) {
        const authToken = selectAuthToken(getState())

        return client.request({
            method: "post",
            url: "/user/change_expired_password",
            data,
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then(() => {
                dispatch(createExpiredPasswordUpdatedAction())
            })
            .catch((error) => {
                const errorType = get(error, "response.data.error_description")

                if (errorType === "Reset password token is invalid") {
                    throw new SubmissionError({ password: "main:reset_password_token_error" })
                }
                if (errorType === "Password was used previously.") {
                    throw new SubmissionError({ password: "main:reset_password_reuse_error" })
                }

                throw new SubmissionError({ password: "main:generic_error" })
            })
    }
}

export function requestPasswordChange(formData) {
    return function (dispatch) {
        return client.post("account/recover_password", { email: formData.username })
            .then(() => {
                dispatch(createSetRequestSubmittedAction())
            })
            .catch(() => {
                throw new SubmissionError({ username: "main:email_error", _error: "main:generic_error" })
            })
    }
}

// Selectors
export const selectLoginData = state => get(state, `${reducerName}.loginData`)
export const selectHasSubmittedRequest = state => get(state, `${reducerName}.submittedRequest`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectNeedsStrongPassword = state => get(state, `${reducerName}.needsStrongPassword`)

// Reducer
const reducers = {
    [SET_LOGIN_DATA](state, payload) {
        return {
            ...state,
            loginData: payload,
        }
    },
    [SET_REQUEST_SUBMITTED](state) {
        return {
            ...state,
            submittedRequest: true,
        }
    },
    [CLEAR_DATA]() {
        return initialState
    },
    [CHECK_REQUIREMENTS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [CHECK_REQUIREMENTS + LOADED](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            needsStrongPassword: payload.result.strong_password,
        }
    },
    [CHECK_REQUIREMENTS + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
