import get from "lodash/get"
import isArray from "lodash/isArray"
import keys from "lodash/keys"
import uuid from "uuid"
import { isMobileOnly } from "react-device-detect"
import constants from "../../../constants"

const createImageModule = (input, hasTitle) => ({
    id: uuid(),
    type: constants.pageModules.image,
    url: get(input, "data.image_url") || get(input, "data.mobile_image_url"),
    mobileUrl: get(input, "data.mobile_image_url") || get(input, "data.image_url"),
    alt: get(input, "data.image_alt_text") || get(input, "data.mobile_image_alt_text"),
    mobileAlt: get(input, "data.mobile_image_alt_text") || get(input, "data.image_alt_text"),
    hasTitle,
})

const createTextModule = (input) => ({
    id: uuid(),
    type: constants.pageModules.text,
    title: get(input, "name"),
    description: get(input, "description"),
})

const createTitleModule = input => ({
    id: uuid(),
    type: constants.pageModules.text,
    title: get(input, "name"),
})

const createDescriptionModule = input => ({
    id: uuid(),
    type: constants.pageModules.text,
    description: get(input, "description"),
})

const createTextWithImageModule = input => ({
    id: uuid(),
    type: constants.pageModules.textWithImage,
    title: get(input, "name"),
    description: get(input, "description"),
    url: get(input, "data.image_url") || get(input, "data.mobile_image_url"),
    mobileUrl: get(input, "data.mobile_image_url") || get(input, "data.image_url"),
    alt: get(input, "data.image_alt_text") || get(input, "data.mobile_image_alt_text"),
    mobileAlt: get(input, "data.mobile_image_alt_text") || get(input, "data.image_alt_text")
})

const createVideoModule = input => ({
    id: uuid(),
    type: constants.pageModules.video,
    url: get(input, "data.video_url"),
    thumbnailUrl: get(input, "data.thumbnail_url"),
    autoplay: get(input, "data.autoplay"),
    subtitles: get(input, "data.video_subtitles_url"),
})

const createEmbedModule = input => ({
    id: uuid(),
    type: constants.pageModules.embed,
    code: get(input, "data.embed_code"),
})

const createScoreModule = input => ({
    id: uuid(),
    type: constants.pageModules.score,
    title: input.name,
    description: input.description,
})

const createInfoSpotModule = input => ({
    id: uuid(),
    type: constants.pageModules.infoSpot,
    image: {
        url: get(input, "data.image_url"),
        mobileUrl: get(input, "data.mobile_image"),
        height: get(input, "data.original_image_height"),
        width: get(input, "data.original_image_width"),
        alt: get(input, "data.image_alt_text") || get(input, "data.mobile_image_alt_text"),
        mobileAlt: get(input, "data.mobile_image_alt_text") || get(input, "data.image_alt_text")
    },
    spots: get(input, "data.spots"),
})

const createMultipleChoiceModule = input => ({
    id: uuid(),
    type: constants.pageModules.multipleChoice,
    isCheckbox: get(input, "data.multi_choice", false),
    choices: get(input, "data.multi_choice_answers", []),
})

const createLikertModule = input => ({
    id: uuid(),
    type: constants.pageModules.likert,
    choices: get(input, "data.likert_scale_answers", []),
})

const createMultipleChoiceImageModule = input => ({
    id: uuid(),
    type: constants.pageModules.multipleChoiceImage,
    isCheckbox: get(input, "data.multi_choice", false),
    choices: get(input, "data.multi_choice_image_answers", []),
})

const createAssignModule = input => ({
    id: uuid(),
    type: constants.pageModules.assign,
    groupA: get(input, "data.group_a", []),
    groupB: get(input, "data.group_b", []),
    choices: get(input, "data.assign_to_group_answers", []),
})

const createPhotoEssayModule = () => ({
    id: uuid(),
    type: constants.pageModules.photoEssay,
})

const createEssayModule = () => ({
    id: uuid(),
    type: constants.pageModules.essay,
})

const createOrderModule = (input, answers) => ({
    id: uuid(),
    type: constants.pageModules.order,
    items: answers.map(item => ({
        id: item.id,
        title: item.draggable || item.answer,
        imageUrl: item.image_url,
        videoUrl: item.video_url,
        dropTitle: item.fixed,
        alt: item.image_alt_text,

    })),
})

const createOrderTextModule = input => createOrderModule(input, (get(input, "data.right_order_text_answers") || []))
const createOrderImageModule = input => createOrderModule(input, (get(input, "data.order_image_answers") || []))
const createOrderVideoModule = input => createOrderModule(input, (get(input, "data.order_video_answers") || []))

const converter = {
    textPage: input => ([
        createTextModule(input),
    ]),

    imagePage: (input) => {
        if (!isMobileOnly) {
            return [
                createTextModule(input),
                createImageModule(input),
            ]
        }

        const hasDescription = !!get(input, "description")
        const hasTitle = !!get(input, "name")
        const mobileImagePage = []

        if (hasTitle) mobileImagePage.push(createTitleModule(input))
        mobileImagePage.push(createImageModule(input, hasTitle))
        if (hasDescription) mobileImagePage.push(createDescriptionModule(input))

        return mobileImagePage
    },

    textWithImagePage: input => ([
        createTextWithImageModule(input),
    ]),

    videoPage: input => ([
        createTitleModule(input),
        createVideoModule(input),
        createDescriptionModule(input),
    ]),

    textWithModules: (modules, input) => (
        [createTextModule(input),
        ...modules.map(module => module(input))]
    ),

    [constants.pageTypes.image]: input => converter.imagePage(input),
    [constants.pageTypes.imageText]: input => converter.textWithImagePage(input),
    [constants.pageTypes.video]: input => converter.videoPage(input),
    [constants.pageTypes.embed]: input => converter.textWithModules([createEmbedModule], input, true),
    [constants.pageTypes.social]: input => converter.textWithModules([createEmbedModule], input, true),
    [constants.pageTypes.score]: input => [createScoreModule(input)],
    [constants.pageTypes.infospots]: input => converter.textWithModules(
        [createInfoSpotModule],
        input,
        true,
    ),

    [constants.pageTypes.multipleChoice]: input => converter.textWithModules(
        [createMultipleChoiceModule],
        input,
        true,
    ),

    [constants.pageTypes.multipleChoiceImage]: input => converter.textWithModules(
        [createMultipleChoiceImageModule],
        input,
        true,
    ),

    [constants.pageTypes.likert]: input => converter.textWithModules(
        [createLikertModule],
        input,
        true,
    ),


    [constants.pageTypes.photoEssay]: input => converter.textWithModules(
        [createPhotoEssayModule],
        input,
        true,
    ),

    [constants.pageTypes.essay]: input => converter.textWithModules(
        [createEssayModule],
        input,
        true,
    ),

    [constants.pageTypes.ranking]: input => converter.textWithModules(
        [createOrderTextModule],
        input,
        true,
    ),

    [constants.pageTypes.orderImage]: input => converter.textWithModules(
        [createOrderImageModule],
        input,
        true,
    ),

    [constants.pageTypes.orderVideo]: input => converter.textWithModules(
        [createOrderVideoModule],
        input,
        true,
    ),

    [constants.pageTypes.assign]: input => converter.textWithModules(
        [createAssignModule],
        input,
        true,
    ),

    default: input => converter.textPage(input),
}

const correctConverter = {
    multiChoice: (answers, correctAnswers) => {
        if (!isArray(answers) || !isArray(correctAnswers)) {
            return constants.answerStatus.neutral
        }

        const answerIds = answers.map(it => parseInt(it, 10))
            .sort()
        const correctAnswersIds = correctAnswers.filter(it => it.correct)
            .map(it => parseInt(it.id, 10))
            .sort()

        if (String(answerIds) !== String(correctAnswersIds)) {
            return constants.answerStatus.incorrect
        }

        return constants.answerStatus.correct
    },

    order: (answers, options) => {
        const res = (
            options.length === Object.keys(answers).length
            && Object.keys(answers).reduce((result, key) => (
                result
                // If the key does not match the value the answer is not matched properly
                && `${answers[key]}` === key
            ), true)
        )
        return (res) ? constants.answerStatus.correct : constants.answerStatus.incorrect
    },

    [constants.pageTypes.multipleChoice]: (page) => {
        const answers = get(page, "answer.multi_choice_answers")
        const correctAnswers = get(page, "data.multi_choice_answers")

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral
        return correctConverter.multiChoice(answers, correctAnswers)
    },
    [constants.pageTypes.multipleChoiceImage]: (page) => {
        const answers = get(page, "answer.multi_choice_image_answers")
        const correctAnswers = get(page, "data.multi_choice_image_answers")

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral
        return correctConverter.multiChoice(answers, correctAnswers)
    },
    [constants.pageTypes.essay]: (page) => {
        if (get(page, "data.mandatory_correct") === true
            && (get(page, "answer.text") === null || get(page, "answer.text") === "")) {
            return constants.answerStatus.incorrect
        }

        return constants.answerStatus.neutral
    },
    [constants.pageTypes.photoEssay]: (page) => {
        if (get(page, "data.mandatory_correct") === true
            && (get(page, "answer.text") === null || get(page, "answer.text") === "")) {
            return constants.answerStatus.incorrect
        }

        return constants.answerStatus.neutral
    },

    [constants.pageTypes.likert]: (page) => {
        const answers = get(page, "answer.likert_answers")
        const correctAnswers = get(page, "data.likert_scale_answers")

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral

        return (correctAnswers.reduce((result, correct) => {
            const optionId = parseInt(get(correct, "id", "0"), 10)
            const answer = parseInt(get(correct, "answer"), 10)
            const userAnswer = parseInt(get(answers, `[${optionId}]`), 10)

            return result && answer === userAnswer
        }, true) && constants.answerStatus.correct) || constants.answerStatus.incorrect
    },

    [constants.pageTypes.assign]: (page) => {
        const defaultAnswers = { group_a: [], group_b: [] }
        const prevAnswers = keys(get(page, "answer", {})).length < 2 ? defaultAnswers : get(page, "answer", {})
        const answers = get(page, "data.assign_to_group_answers", [])

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral

        return (answers.reduce((result, answer) => {
            const answerId = get(answer, "id", 0)
            const group = get(answer, "group")

            return result && (prevAnswers[group] && prevAnswers[group].includes(answerId))
        }, true) && constants.answerStatus.correct) || constants.answerStatus.incorrect
    },

    [constants.pageTypes.ranking]: (page) => {
        const correctAnswers = get(page, "data.right_order_text_answers")

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral
        return correctConverter.order(get(page, "answer.order_answers") || {}, correctAnswers)
    },
    [constants.pageTypes.orderVideo]: (page) => {
        const correctAnswers = get(page, "data.order_video_answers")

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral
        return correctConverter.order(get(page, "answer.order_answers") || {}, correctAnswers)
    },
    [constants.pageTypes.orderImage]: (page) => {
        const correctAnswers = get(page, "data.order_image_answers")

        if (get(page, "data.neutral", true)) return constants.answerStatus.neutral
        return correctConverter.order(get(page, "answer.order_answers") || {}, correctAnswers)
    },

    default: () => constants.answerStatus.neutral,
}

const answerConverter = {
    multiChoice: (page, choices, prevAnswer) => {
        const state = {}

        choices.forEach((choice) => {
            state[choice.id] = prevAnswer.includes(choice.id) ? "1" : "0"
        })

        return {
            updateTime: get(page, "answer.update_time"),
            state,
        }
    },

    order: (page) => {
        let answer = get(page, "answer.order_answers")
        if (!answer || answer === "") {
            answer = {}
        }

        return ({
            updateTime: get(page, "answer.update_time"),
            state: answer,
        })
    },

    [constants.pageTypes.photoEssay]: page => ({
        updateTime: get(page, "answer.update_time"),
        image: get(page, "answer.image_url"),
        state: {
            text: get(page, "answer.text"),
        },
    }),

    [constants.pageTypes.essay]: page => ({
        updateTime: get(page, "answer.update_time"),
        state: {
            text: get(page, "answer.text"),
        },
    }),

    [constants.pageTypes.multipleChoice]: (page) => {
        const choices = get(page, "data.multi_choice_answers", [])
        const prevAnswer = get(page, "answer.multi_choice_answers", [])
        return answerConverter.multiChoice(page, choices, prevAnswer)
    },

    [constants.pageTypes.multipleChoiceImage]: (page) => {
        const choices = get(page, "data.multi_choice_image_answers", [])
        const prevAnswer = get(page, "answer.multi_choice_image_answers", [])
        return answerConverter.multiChoice(page, choices, prevAnswer)
    },

    [constants.pageTypes.likert]: (page) => {
        const prevAnswer = get(page, "answer.likert_answers", [])

        return {
            updateTime: get(page, "answer.update_time"),
            state: {
                ...prevAnswer,
            },
        }
    },

    [constants.pageTypes.assign]: (page) => {
        const updateTime = get(page, "answer.update_time", null)
        const groupA = get(page, "answer.group_a", [])
        const groupB = get(page, "answer.group_b", [])

        return {
            updateTime,
            state: {
                group_a: groupA || [],
                group_b: groupB || [],
            },
        }
    },

    [constants.pageTypes.ranking]: page => answerConverter.order(page),
    [constants.pageTypes.orderVideo]: page => answerConverter.order(page),
    [constants.pageTypes.orderImage]: page => answerConverter.order(page),

    default: page => ({ updateTime: get(page, "answer.update_time") }),
}

const isFeedbackPage = (page) => {
    const feedbackText = get(page, "data.explanation")
    const isFeedback = get(page, "data.feedback", false)
    const isNeutral = get(page, "data.neutral", true)

    if (isFeedback) {
        return !isNeutral || (isNeutral && !!feedbackText)
    }

    return false
}

/**
 * Convert json from the api to usable object
 * @param inputPage
 */
const pageConverter = (inputPage) => {
    const pageType = get(inputPage, "page_type", constants.pageTypes.text)

    return {
        id: get(inputPage, "id"),
        info: get(inputPage, "info"),
        feedbackText: {
            1: get(inputPage, "data.correct_explanation"),
            2: get(inputPage, "data.incorrect_explanation"),
            3: get(inputPage, "data.explanation"),
        },
        pageType,

        // Meta data
        isExcludedFromScore: get(inputPage, "data.exclude_from_total_score", true),
        isCorrect: (correctConverter[pageType] || correctConverter.default)(inputPage),

        isCorrectMandatory: get(inputPage, "data.mandatory_correct", false),
        isFeedbackPage: isFeedbackPage(inputPage),
        isNeutralFeedback: get(inputPage, "data.neutral", true),

        weight: get(inputPage, "score", 0),
        isFavorite: get(inputPage, "is_favorite", false),
        isPrimaryPage: (
            pageType === constants.pageTypes.multipleChoice
            || pageType === constants.pageTypes.multipleChoiceImage
            || pageType === constants.pageTypes.likert
            || pageType === constants.pageTypes.ranking
            || pageType === constants.pageTypes.assign
            || pageType === constants.pageTypes.orderImage
            || pageType === constants.pageTypes.orderVideo
        ),

        // Cannot be empty answer
        mandatoryAnswer: (get(inputPage, "data.mandatory_correct") && (
            pageType === constants.pageTypes.essay
            || pageType === constants.pageTypes.photoEssay
        )),

        // Modules
        modules: (converter[pageType] || converter.default)(inputPage),

        isDraft: get(inputPage, "status", "Live") === "Draft",

        // CurrentAnswer
        answer: (answerConverter[pageType] || answerConverter.default)(inputPage),

        // Tags
        glossaryTags: get(inputPage, "glossary_tags"),
        // Direct Links
        directLinks: get(inputPage, "direct_links"),
        // imageText: image and test alignment
        imageAlign: get(inputPage, "data.left_align"),
        // text input placeholder
        placeholderText: get(inputPage, "data.placeholder_text"),

        // Label for Essay and Photo EssayModule
        label: get(inputPage, "data.label_text"),

        visualFeedback: get(inputPage, "data.visual_feedback", false),

        // Scoring criteria for score page
        scoringCriteria: get(inputPage, "data.scoring_criteria", 0),

        // leaderboard score
        userLeaderboardScore: get(inputPage, "user_leaderboard_score", 0),

        //timeout in secs
        timeoutInSeconds: get (inputPage, "timeout_in_seconds", 0),

        pageData: get (inputPage, "data")
    }
}

export default pageConverter
