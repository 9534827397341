import get from "lodash/get"
import pull from "lodash/pull"
import keys from "lodash/keys"
import reducerRegistery from "../../ReducerRegistery"
import { createReducer } from "../../utility"
import { LOGOUT } from "../authentication"
import { LOADING, LOADED, ERROR } from "../../middleware/actions"
import { SELECT_COURSE } from "../courses"
import {
    reducerName,
    ADD_MESSAGE,
    CREATE_GROUP,
    EDIT_GROUP,
    MEMBERS,
    MESSAGES,
    PREVIEWS,
    SEND_MESSAGE,
    UPDATE_CONVERSATION,
    ADD_PREVIEW,
    REMOVE_PREVIEW,
    EDIT_PREVIEW,
    MARK_AS_READ,
    UPDATE_CONVERSATION_STATUS,
    MARK_TUTORIAL_AS_SEEN,
    TUTORIAL_SEEN,
} from "./actions"
import { selectUser } from "../user"
import { getOneToOnes } from "./helpers"

// Required variables
const initialState = {
    members: [],
    messages: {},
    previews: [],
    currentConversation: {
        conversationName: null,
        conversationId: null,
        members: null,
        avatar: null,
        channelId: null,
        status: null,
    },
    privateChannel: null,
    error: null,
    isLoadingMembers: false,
    isLoadingPreviews: false,
    isLoadingMessages: false,
    removingConversation: false,
    messagesError: null,
    sendingMessage: false,
    creatingGroup: false,
    editingGroup: false,
    tutorialSeen: false,
    hasSeenTutorial: false,
}

// Selectors
export const selectMembers = state => get(state, `${reducerName}.members`)
export const selectConversationName = state => get(state, `${reducerName}.currentConversation.conversationName`)
export const selectConversationId = state => get(state, `${reducerName}.currentConversation.conversationId`)
export const selectCurrentConversationMembers = state => get(state, `${reducerName}.currentConversation.members`)
export const selectAvatar = state => get(state, `${reducerName}.currentConversation.avatar`)
export const selectCurrentChannel = state => get(state, `${reducerName}.currentConversation.channelId`)
export const selectCurrentConversationStatus = state => get(state, `${reducerName}.currentConversation.status`)
export const selectPreviews = state => get(state, `${reducerName}.previews`)
export const selectOneToOneConversations = (state) => {
    const allPreviews = selectPreviews(state)
    return getOneToOnes(allPreviews)
}
export const selectPrivateChannel = state => get(state, `${reducerName}.privateChannel`)
export const selectLoadingMembers = state => get(state, `${reducerName}.isLoadingMembers`)
export const selectLoadingPreviews = state => get(state, `${reducerName}.isLoadingPreviews`)
export const selectLoadingMessages = state => get(state, `${reducerName}.isLoadingMessages`)
export const selectIsRemovingConversation = state => get(state, `${reducerName}.removingConversation`)
export const selectMessages = (state) => {
    const conversation = selectConversationId(state)
    return get(state, `${reducerName}.messages.${conversation}`, [])
}
export const selectLoadedConversations = state => keys(get(state, `${reducerName}.messages`))
export const selectIsSendingMessage = state => get(state, `${reducerName}.sendingMessage`)
export const selectIsCreatingGroup = state => get(state, `${reducerName}.creatingGroup`)
export const selectIsEditingGroup = state => get(state, `${reducerName}.editingGroup`)
export const selectHasUnreadMessages = (state) => {
    const conversations = selectPreviews(state)
    const user = selectUser(state)
    if (!conversations || conversations.length === 0 || !user) return false

    return conversations.some(({ last_message_seen, last_message_user_id, status }) => (
        last_message_seen === false && last_message_user_id !== user.user_chat_id && status === 1
    ))
}
export const selectTutorialSeen = state => get(state, `${reducerName}.tutorialSeen`)

// Actions
export * from "./actions"

// Action creators
export * from "./actionCreators"

// Reducer
const reducers = {
    [MEMBERS + LOADING](state) {
        return { ...state, isLoadingMembers: true, error: null }
    },
    [MEMBERS + LOADED](state, payload) {
        return {
            ...state,
            members: payload.result,
            isLoadingMembers: false,
        }
    },
    [MEMBERS + ERROR](state, payload) {
        return { ...state, isLoadingMembers: false, error: payload.result }
    },
    [PREVIEWS + LOADING](state) {
        return { ...state, isLoadingPreviews: true, error: null }
    },
    [PREVIEWS + LOADED](state, payload) {
        return {
            ...state,
            privateChannel: payload.result.private_notifications,
            previews: payload.result.conversations,
            isLoadingPreviews: false,
        }
    },
    [PREVIEWS + ERROR](state, payload) {
        return { ...state, isLoadingPreviews: false, error: payload.result }
    },
    [MESSAGES + LOADING](state) {
        return { ...state, isLoadingMessages: true, messagesError: null }
    },
    [MESSAGES + LOADED](state, payload) {
        const conversation = payload.originalPayload
        return {
            ...state,
            messages: {
                ...state.messages,
                [conversation]: payload.result.messages.reverse(),
            },
            isLoadingMessages: false,
        }
    },
    [MESSAGES + ERROR](state, payload) {
        return { ...state, isLoadingMessages: false, messagesError: payload.result }
    },
    [SEND_MESSAGE + LOADING](state) {
        return {
            ...state,
            sendingMessage: true,
        }
    },
    [SEND_MESSAGE + ERROR](state) {
        return {
            ...state,
            sendingMessage: false,
        }
    },
    [SEND_MESSAGE + LOADED](state, payload) {
        const newChannelId = payload.result.channel_id
        const newConversationId = payload.result.conversation_id

        return {
            ...state,
            currentConversation: {
                ...state.currentConversation,
                conversationId: newConversationId,
                channelId: newChannelId,
            },
            sendingMessage: false,
        }
    },
    [CREATE_GROUP + LOADING](state) {
        return {
            ...state,
            conversationId: null,
            creatingGroup: true,
        }
    },
    [CREATE_GROUP + ERROR](state) {
        return {
            ...state,
            creatingGroup: false,
        }
    },
    [CREATE_GROUP + LOADED](state, payload) {
        return {
            ...state,
            creatingGroup: false,
            currentConversation: {
                ...state.currentConversation,
                channelId: payload.result.channel_id,
                conversationId: payload.result.id,
                conversationName: payload.result.name,
                members: payload.result.members,
                status: 1,
            },
        }
    },
    [EDIT_GROUP + LOADING](state) {
        return {
            ...state,
            editingGroup: true,
        }
    },
    [EDIT_GROUP + ERROR](state) {
        return {
            ...state,
            editingGroup: false,
        }
    },
    [EDIT_GROUP + LOADED](state, payload) {
        const { originalPayload: { conversationId, dataToUpdate } } = payload
        const previews = [...state.previews]
        const indexOfPreview = previews.findIndex(preview => (preview.id === conversationId && preview.other_users.length > 2))
        const oldPreview = previews[indexOfPreview]

        previews[indexOfPreview] = {
            ...oldPreview,
            channel_name: get(dataToUpdate, "name", oldPreview.channel_name),
            channel_avatar: get(dataToUpdate, "avatar", oldPreview.channel_avatar),
            other_users: get(dataToUpdate, "users", oldPreview.other_users),
        }

        return {
            ...state,
            currentConversation: {
                ...state.currentConversation,
                conversationName: get(dataToUpdate, "name", state.currentConversation.conversationName),
                avatar: get(dataToUpdate, "avatar", state.currentConversation.avatar),
                members: get(dataToUpdate, "users", state.currentConversation.members),
            },
            previews,
            editingGroup: false,
        }
    },
    [UPDATE_CONVERSATION](state, conversation) {
        return {
            ...state,
            currentConversation: {
                ...state.currentConversation,
                ...conversation,
            },
        }
    },
    [ADD_MESSAGE](state, data) {
        const conversation = data.conversation_id || data.message.conversation_id
        const newMessage = {
            message_id: data.message.id,
            timestamp: data.message.created_at,
            message_filename: get(data, "message.attachment.filename"),
            message_size: get(data, "message.attachment.size"),
            message_text: data.message.body,
            message_url: get(data, "message.attachment.url"),
            sender_id: data.message.sender_id,
            type: data.message.message_type,
        }

        const messages = {
            ...state.messages,
            [conversation]: [...get(state, `messages.${conversation}`, []), newMessage],
        }

        return { ...state, messages }
    },
    [ADD_PREVIEW](state, data) {
        const previews = [...state.previews]
        const { message } = data
        let newPreviews = previews

        const isGroup = get(data, "other_users", []).length > 2

        // if user not exists in previews list then add it
        const isPreviewExists = previews.find(preview => preview.id === message?.conversation_id)
        if (!isPreviewExists) {
            const newPreview = {
                channel_avatar: data.group_avatar || data.user_avatar,
                channel_id: data.channel_id,
                channel_name: data.group_name || `${data.user_first_name} ${data.user_last_name}`,
                id: data.group_id || message.conversation_id,
                last_message: get(message, "body", isGroup ? "This group has been created" : null),
                last_message_timestamp: get(message, "created_at", isGroup ? Date.now() : null),
                last_message_user_id: get(message, "sender_id", null),
                last_message_type: get(message, "message_type", isGroup ? 4 : 1),
                last_message_seen: get(data, "last_message_seen", false),
                other_users: data.other_users,
            }
            newPreviews = [newPreview, ...previews]
        }

        return { ...state, previews: newPreviews }
    },
    [REMOVE_PREVIEW](state, data) {
        const conversationId = get(data, "group_id")
        const previews = state.previews.filter(preview => (
            preview.id !== conversationId
        ))

        return {
            ...state,
            previews,
        }
    },
    [EDIT_PREVIEW](state, data) {
        const groupId = data.group_id
        const channelId = data.channel_id
        const previews = [...state.previews]
        const { message } = data
        let newPreviews = previews

        const previewToUpdate = previews.find((preview) => {
            if (groupId) {
                return (preview.channel_id === `private-group-${groupId}`)
            }
            return preview.channel_id === channelId
        })

        if (!previewToUpdate) {
            return { ...state }
        }

        const newPreview = {
            ...previewToUpdate,
            last_message: message.body,
            last_message_timestamp: message.created_at,
            last_message_user_id: message.sender_id,
            last_message_type: message.message_type,
            last_message_seen: get(data, "last_message_seen", false),
            status: previewToUpdate.status === 2 ? 2 : 1,
        }

        newPreviews = [newPreview, ...pull(previews, previewToUpdate)]

        return { ...state, previews: newPreviews }
    },
    [MARK_AS_READ + LOADED](state) {
        const currentConversation = get(state, "currentConversation.conversationId")
        const newPreviews = [...state.previews]
        const newPreviewIndex = newPreviews.findIndex(({ id }) => id === currentConversation)
        newPreviews[newPreviewIndex] = {
            ...newPreviews[newPreviewIndex],
            last_message_seen: true,
        }

        return {
            ...state,
            previews: newPreviews,
        }
    },
    [UPDATE_CONVERSATION_STATUS + LOADING](state) {
        return {
            ...state,
            removingConversation: true,
        }
    },
    [UPDATE_CONVERSATION_STATUS + ERROR](state) {
        return {
            ...state,
            removingConversation: false,
        }
    },
    [UPDATE_CONVERSATION_STATUS + LOADED](state, payload) {
        const { originalPayload: { conversationId, value } } = payload

        // Update the previews
        const previews = [...state.previews]
        const indexOfPreview = previews.findIndex(preview => preview.id === conversationId)
        const oldPreview = previews[indexOfPreview]
        previews[indexOfPreview] = {
            ...oldPreview,
            is_archived: value,
        }

        // Update the current conversation
        const removedConversationIsCurrent = (
            state.currentConversation.conversationId === conversationId
        )
        let newCurrentConversation = {
            ...state.currentConversation,
        }
        if (value && removedConversationIsCurrent) {
            newCurrentConversation = initialState.currentConversation
        } else if (removedConversationIsCurrent) {
            newCurrentConversation = {
                ...state.currentConversation,
                is_archived: value,
            }
        }

        return {
            ...state,
            currentConversation: newCurrentConversation,
            previews,
            removingConversation: false,
        }
    },
    [MARK_TUTORIAL_AS_SEEN](state, payload) {
        return {
            ...state,
            tutorialSeen: payload,
        }
    },

    [TUTORIAL_SEEN + LOADING](state) {
        return {
            ...state,
            hasSeenTutorial: false,
        }
    },
    [TUTORIAL_SEEN + ERROR](state) {
        return {
            ...state,
            hasSeenTutorial: false,
        }
    },
    [TUTORIAL_SEEN + LOADED](state) {
        return {
            ...state,
            hasSeenTutorial: true,
        }
    },
    // Reset reducers
    [SELECT_COURSE](state) {
        return {
            ...initialState,
            tutorialSeen: state.tutorialSeen,
        }
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
