/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/browser"
import { getToken, onMessageListener } from "../firebaseSetup"
import { handleNotification } from "../push"
import { changeLanguage } from "../configure/i18n"
import AppRoutes from "./AppRoutes"
import connect from "./connect"
import CacheBuster from "./CacheBuster"
import { sentryConfig } from "../sentryConfig"

export const isPushSupported = "serviceWorker" in navigator && "PushManager" in window
function App({
    isLoggedIn,
    setGCMToken,
    appLanguage,
    loadNotifications,
    history,
    redirectUrl,
    hasExpiredPassword,
    requires2FA,
    twoFactorAuthorized,
    user,
    loadUserPreferences,
    userPreferences
}) {
    const [token, setToken] = useState(null)

    useEffect(() => {
        if (!token && isPushSupported) {
            getToken(setToken)
            onMessageListener().then((payload) => {
                handleNotification(payload, loadNotifications, history)
            }).catch(err => console.log("failed: ", err))
        } else {
            setGCMToken(token)
        }

        if (appLanguage) changeLanguage(appLanguage)

        if (isPushSupported) {
            loadNotifications()
        }
    }, [token, appLanguage, setGCMToken, loadNotifications, history])


    useEffect(() => {
        if (user && sentryConfig?.enabled) {
            Sentry.setUser({
                id: user.id,
                email: user.email,
            });
        }
        if (isLoggedIn) {
            // Load user preferences
            loadUserPreferences();
        }
    }, [user, isLoggedIn, loadUserPreferences]);

    useEffect(() => {
        if (userPreferences) {
            if (userPreferences.appLanguageIdentifier) {
                changeLanguage(userPreferences.appLanguageIdentifier);
            }
        }
    }, [userPreferences]);


    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null
                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload()
                }
                return (
                    <AppRoutes
                        isLoggedIn={isLoggedIn}
                        redirectUrl={redirectUrl}
                        hasExpiredPassword={hasExpiredPassword}
                        requires2FA={requires2FA}
                        twoFactorAuthorized={twoFactorAuthorized}
                    />
                )
            }}
        </CacheBuster>
    )
}

App.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    setGCMToken: PropTypes.func.isRequired,
    loadNotifications: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    appLanguage: PropTypes.string,
    redirectUrl: PropTypes.string,
    hasExpiredPassword: PropTypes.bool,
    requires2FA: PropTypes.bool,
    twoFactorAuthorized: PropTypes.bool,
    user: PropTypes.object,
    loadUserPreferences: PropTypes.func.isRequired,
    userPreferences: PropTypes.object
}

App.defaultProps = {
    redirectUrl: "/",
    hasExpiredPassword: false,
    requires2FA: false,
    twoFactorAuthorized: false,
    appLanguage: null,
    user: null,
    userPreferences: {
        appLanguageIdentifier: 'en',
    }
}

export default connect(App)
