import get from "lodash/get"
import orderBy from "lodash/orderBy"
import uniqBy from "lodash/uniqBy"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { selectSelectedCourse } from "../courses"
import { SELECT_LANGUAGE } from "../languages"

// Required variables
const initialState = {
    leaderboard: {
        data: {
            overall: [],
            individual: [],
        },
        topThree: {
            overall: [],
            individual: [],
        },
        isLoading: false,
        loadingError: null,
    },
    individuals: {
        isLoading: false,
        loadingError: null,
        nextPage: 1,
    },
    search: {
        isLoading: false,
        loadingError: null,
    },
    user: {
        data: {},
        isLoading: false,
        loadingError: null,
    },
    anonymity: {
        data: {},
        isLoading: false,
        loadingError: null,
    },
}

export const reducerName = "leaderboard"

// Actions
export const LEADER_BOARD = createActionName(reducerName, "LEADER_BOARD")
export const SEARCH_LEADER_BOARD = createActionName(reducerName, "SEARCH_LEADER_BOARD")
export const AFTER_SEARCH_LEADER_BOARD = createActionName(reducerName, "AFTER_SEARCH_LEADER_BOARD")
export const MORE_LEADER_BOARD = createActionName(reducerName, "MORE_LEADER_BOARD")
export const LEADER_BOARD_PROFILE = createActionName(reducerName, "LEADER_BOARD_PROFILE")
export const LEADER_BOARD_ANONYMITY = createActionName(reducerName, "LEADER_BOARD_ANONYMITY")

// Action creators
export const createLoadLeaderboardAction = callback => (dispatch, getState) => {
    const course = selectSelectedCourse(getState())
    const { id } = course
    dispatch({
        type: LEADER_BOARD,
        request: {
            method: "get",
            url: `courses/${id}/leaderboards`,
        },
        callback,
    })
}

export const createLoadAfterSearchLeaderboardAction = callback => (dispatch, getState) => {
    const course = selectSelectedCourse(getState())
    const { id } = course
    dispatch({
        type: AFTER_SEARCH_LEADER_BOARD,
        request: {
            method: "get",
            url: `courses/${id}/leaderboards`,
        },
        callback,
    })
}

export const createLoadMoreLeaderboardAction = (type = "", page = "", query = "", callback) => (dispatch, getState) => {
    if (page === null) return
    const course = selectSelectedCourse(getState())
    const { id } = course
    let url = `courses/${id}/leaderboards`
    if (type) {
        url = `${url}/${type}`
    }
    if (page) {
        url = `${url}?page=${page}`
    }
    if (query) {
        url = `${url}&search=${query}`
    }
    dispatch({
        type: !query ? MORE_LEADER_BOARD : SEARCH_LEADER_BOARD,
        request: {
            method: "get",
            url,
        },
        callback,
        payload: {
            type,
        },
    })
}

export const createLoadLeaderboardUserAction = (userId, callback) => (dispatch, getState) => {
    const course = selectSelectedCourse(getState())
    const { id } = course
    dispatch({
        type: LEADER_BOARD_PROFILE,
        request: {
            method: "get",
            url: `courses/${id}/leaderboards/${userId}/profile`,
        },
        callback,
    })
}

export const createAnonymityLeaderboardUserAction = (data, callback) => (dispatch, getState) => {
    const course = selectSelectedCourse(getState())
    const { id } = course
    dispatch({
        type: LEADER_BOARD_ANONYMITY,
        request: {
            method: "post",
            url: `courses/${id}/leaderboards/toggle_anonymity?hide_group=${data}`,
        },
        callback,
    })
}

// Selectors
export const selectLeaderboardData = state => get(state, `${reducerName}.leaderboard.data`)
export const selectLeaderboardTopThree = state => get(state, `${reducerName}.leaderboard.topThree`)
export const selectLeaderboardLoading = state => get(state, `${reducerName}.leaderboard.isLoading`)
export const selectLeaderboardError = state => get(state, `${reducerName}.leaderboard.loadingError`)

export const selectLeaderboardUserData = state => get(state, `${reducerName}.user.data`)
export const selectLeaderboardUserIsLoading = state => get(state, `${reducerName}.user.isLoading`)
export const selectLeaderboardUserError = state => get(state, `${reducerName}.user.loadingError`)

export const selectAnonymityLeaderboardUserData = state => get(state, `${reducerName}.anonymity.data`)
export const selectAnonymityLeaderboardUserIsLoading = state => get(state, `${reducerName}.anonymity.isLoading`)
export const selectAnonymityLeaderboardUserError = state => get(state, `${reducerName}.anonymity.loadingError`)

export const selectIndividualsLeaderboardNextPage = state => get(state, `${reducerName}.individuals.nextPage`, "1")
export const selectIndividualsLeaderboardIsLoading = state => get(state, `${reducerName}.individuals.isLoading`)
export const selectIndividualsLeaderboardError = state => get(state, `${reducerName}.individuals.loadingError`)

export const selectSearchLeaderboardIsLoading = state => get(state, `${reducerName}.search.isLoading`)
export const selectSearchLeaderboardError = state => get(state, `${reducerName}.search.loadingError`)

// Reducer
const reducers = {
    [LEADER_BOARD + LOADING](state) {
        return {
            ...state,
            leaderboard: {
                ...state.leaderboard,
                isLoading: true,
                loadingError: null,
            },
        }
    },
    [LEADER_BOARD + LOADED](state, payload) {
        const { result } = payload
        return {
            ...state,
            leaderboard: {
                ...state.leaderboard,
                isLoading: false,
                loadingError: null,
                data: {
                    ...result,
                    overall: result.overall.slice(3),
                    individual: result.individual.slice(3),
                },
                topThree: {
                    overall: result.overall.slice(0, 3),
                    individual: result.individual.slice(0, 3),
                },
            },
        }
    },
    [LEADER_BOARD + ERROR](state, payload) {
        return {
            ...state,
            leaderboard: {
                ...state.leaderboard,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    [AFTER_SEARCH_LEADER_BOARD + LOADING](state) {
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: true,
                loadingError: null,
            },
        }
    },
    [AFTER_SEARCH_LEADER_BOARD + LOADED](state, payload) {
        const { result } = payload
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: false,
                loadingError: null,
            },
            leaderboard: {
                ...state.leaderboard,
                data: {
                    ...result,
                    overall: result.overall.slice(3),
                    individual: result.individual.slice(3),
                },
                topThree: {
                    overall: result.overall.slice(0, 3),
                    individual: result.individual.slice(0, 3),
                },
            },
        }
    },
    [AFTER_SEARCH_LEADER_BOARD + ERROR](state, payload) {
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    [MORE_LEADER_BOARD + LOADING](state) {
        return {
            ...state,
            individuals: {
                ...state.individuals,
                isLoading: true,
                loadingError: null,
            },
        }
    },
    [MORE_LEADER_BOARD + LOADED](state, payload) {
        const { originalPayload: { type }, result } = payload
        let data = [
            ...state.leaderboard.data[type],
            ...result[type],
        ]
        data = uniqBy(data, "id")
        data = orderBy(data, ["position"], ["asc"])
        return {
            ...state,
            individuals: {
                ...state.individuals,
                isLoading: false,
                loadingError: null,
                nextPage: result.next_page,
            },
            leaderboard: {
                ...state.leaderboard,
                data: {
                    ...state.leaderboard.data,
                    [type]: data,
                },
            },
        }
    },
    [MORE_LEADER_BOARD + ERROR](state, payload) {
        return {
            ...state,
            individuals: {
                ...state.individuals,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    [SEARCH_LEADER_BOARD + LOADING](state) {
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: true,
                loadingError: null,
            },
            individuals: {
                ...state.individuals,
                nextPage: 1,
            },
        }
    },
    [SEARCH_LEADER_BOARD + LOADED](state, payload) {
        const { originalPayload: { type }, result } = payload
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: false,
                loadingError: null,
            },
            individuals: {
                ...state.individuals,
                nextPage: result.next_page,
            },
            leaderboard: {
                ...state.leaderboard,
                data: {
                    ...state.leaderboard.data,
                    [type]: result[type],
                },
            },
        }
    },

    [SEARCH_LEADER_BOARD + ERROR](state, payload) {
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    [LEADER_BOARD_PROFILE + LOADING](state) {
        return {
            ...state,
            user: {
                ...state.user,
                isLoading: true,
                loadingError: null,
            },
        }
    },
    [LEADER_BOARD_PROFILE + LOADED](state, payload) {
        return {
            ...state,
            user: {
                ...state.user,
                isLoading: false,
                loadingError: null,
                data: payload.result,
            },
        }
    },
    [LEADER_BOARD_PROFILE + ERROR](state, payload) {
        return {
            ...state,
            user: {
                ...state.user,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    [LEADER_BOARD_ANONYMITY + LOADING](state) {
        return {
            ...state,
            anonymity: {
                ...state.anonymity,
                isLoading: true,
                loadingError: null,
            },
        }
    },
    [LEADER_BOARD_ANONYMITY + LOADED](state, payload) {
        return {
            ...state,
            anonymity: {
                ...state.anonymity,
                isLoading: false,
                loadingError: null,
                data: payload.result,
            },
            user: {
                ...state.user,
                data: {
                    ...state.user.data,
                    ...payload.result,
                },
            },

        }
    },
    [LEADER_BOARD_ANONYMITY + ERROR](state, payload) {
        return {
            ...state,
            anonymity: {
                ...state.anonymity,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
