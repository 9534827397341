import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { SELECT_COURSE } from "../courses"
import { LOGOUT } from "../authentication"
import get from "lodash/get"

// Required variables
const initialState = {
    /* Holds the user redux object */
    data: null,
    /* Holds the favourites */
    favourites: null,
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
    /* send data deletion request */
    isDataDeleteRequestSend: false,
}
export const reducerName = "user"

// Actions
export const USER = createActionName(reducerName, "USER")
export const USER_UPDATE = createActionName(reducerName, "USER_UPDATE")
export const IMAGE_UPDATE = createActionName(reducerName, "IMAGE_UPDATE")
export const GET_FAVOURITES = createActionName(reducerName, "GET_FAVOURITES")
export const SET_MANAGER_TUTORIAL_SEEN = createActionName(reducerName, "SET_TUTORIAL_SEEN")
export const DATA_DELETION_REQUEST = createActionName(reducerName, "DATA_DELETION_REQUEST")
export const RESET_STATUS_DATA_DELETION_REQUEST = createActionName(reducerName, "RESET_STATUS_DATA_DELETION_REQUEST")
export const CONFIRM_POPUP_REQUEST = createActionName(reducerName, "CONFIRM_POPUP_REQUEST")

// Action creators
export * from "./actionCreators"

// Selectors
export * from "./selectors"

// Reducer
const reducers = {
    [USER + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [USER + LOADED](state, payload) {
        const dataDeletionRequestedAt = get(payload, "result.data_deletion_requested_at")
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...payload.result,
                dataDeletionRequestedAt,
            },
        }
    },
    [USER + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },
    [DATA_DELETION_REQUEST + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [DATA_DELETION_REQUEST + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },
    [DATA_DELETION_REQUEST + LOADED](state, payload) {
        const dataDeletionRequestedAt = get(payload, "result.data_deletion_requested_at")
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...state.data,
                dataDeletionRequestedAt,
            },
            isDataDeleteRequestSend: true,
        }
    },
    [RESET_STATUS_DATA_DELETION_REQUEST](state) {
        return { ...state, isDataDeleteRequestSend: false }
    },
    [USER_UPDATE + LOADED](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...state.data,
                ...payload.result,
            },
        }
    },
    [IMAGE_UPDATE + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [GET_FAVOURITES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [GET_FAVOURITES + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, favourites: payload.result,
        }
    },
    [GET_FAVOURITES + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },
    [SET_MANAGER_TUTORIAL_SEEN + LOADED](state) {
        return {
            ...state,
            data: {
                ...state.data,
                manager_tutorial_seen: true,
            },
        }
    },
    [CONFIRM_POPUP_REQUEST + LOADED](state) {
        return {
            ...state,
            data: {
                ...state.data,
                show_checklist_info_popup: false,
            },
        }
    },
    // CONFIRM_POPUP_REQUEST

    [SELECT_COURSE]() { return initialState },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
