import React from "react"
import PropTypes from "prop-types"

export const CustomFontContext = React.createContext(() => {})

export class CustomFontProvider extends React.Component {
    state = {
        fonts: [],
    }

    loadFont = url => this.setState((prevState) => {
        if (prevState.fonts.indexOf(url) > -1) { return prevState }

        return { fonts: [...prevState.fonts, url] }
    })

    render() {
        return (
            <div>
                { this.state.fonts.map(fontUrl => (
                    <link key={fontUrl} href={fontUrl} type="text/css" rel="stylesheet" />
                ))}
                <CustomFontContext.Provider value={this.loadFont}>
                    {this.props.children}
                </CustomFontContext.Provider>
            </div>
        )
    }
}

CustomFontProvider.propTypes = {
    children: PropTypes.node,
}

CustomFontProvider.defaultProps = {
    children: undefined,
}

export default CustomFontProvider
