import get from "lodash/get"
import groupBy from "lodash/groupBy"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"

// Required variables
const initialState = {
    /* Holds the user redux object */
    data: [],
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,

    /* hold a reference to the selected course id */
    selectedCourseId: null,
    /* hold an array of the course IDs which have sent analytics data during this session */
    coursesWithAnalyticsSubmitted: [],

    /* Has an initial course load been completed? */
    initialLoadComplete: false,

    /* hold a reference to the selected course rating data */
    isSuccessfullySubmitRating: false,

    /* hold a reference to the search data */
    courseWideSearch: {
        data: {},
        isLoading: false,
        loadingError: null,
        isSearchEmpty: false,
    },
}
export const reducerName = "courses"

// Actions
export const COURSES = createActionName(reducerName, "COURSES")
export const LOAD_SPECIFIC_COURSE = createActionName(reducerName, "LOAD_SPECIFIC_COURSE")
export const SELECT_COURSE = createActionName(reducerName, "SELECT_COURSE")
export const UPDATE_COURSE = createActionName(reducerName, "UPDATE_COURSE")
export const SEND_ANALYTICS = createActionName(reducerName, "SEND_ANALYTICS")
export const SUBMIT_RATING = createActionName(reducerName, "SUBMIT_RATING")
export const RESET_SUBMIT_RATING = createActionName(reducerName, "RESET_SUBMIT_RATING")
export const COURSE_WIDE_SEARCH = createActionName(reducerName, "COURSE_WIDE_SEARCH")
export const COURSE_WIDE_SEARCH_EMPTY = createActionName(reducerName, "COURSE_WIDE_SEARCH_EMPTY")

// Action creators
export * from "./actionCreators"

// Selectors
export const selectCourses = state => get(state, `${reducerName}.data`)
export const selectCoursesByOrganisation = (state) => {
    const courses = selectCourses(state)
    return groupBy(courses, "customer_id")
}
export const selectSelectedCourse = (state) => {
    const selectedId = get(state, `${reducerName}.selectedCourseId`)

    if (selectedId === -1) return { id: -1 }

    if (!selectedId) return undefined

    return get(state, `${reducerName}.data`).find(course => course.id === selectedId)
}
export const selectInitialLoadComplete = state => get(state, `${reducerName}.initialLoadComplete`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectShouldSendAnalytics = (state) => {
    const alreadySent = get(state, `${reducerName}.coursesWithAnalyticsSubmitted`, [])
    const selectedId = get(state, `${reducerName}.selectedCourseId`)
    if (!selectedId) return false

    return !alreadySent.includes(selectedId)
}
export const selectIsSuccessfullySubmitRating = state => get(state, `${reducerName}.isSuccessfullySubmitRating`)

export const selectCourseWideSearchData = state => get(state, `${reducerName}.courseWideSearch.data`)
export const selectCourseWideSearchIsLoading = state => get(state, `${reducerName}.courseWideSearch.isLoading`)
export const selectCourseWideSearchLoadingError = state => get(state, `${reducerName}.courseWideSearch.loadingError`)
export const selectCourseWideSearchIsEmpty = state => get(state, `${reducerName}.courseWideSearch.isSearchEmpty`)

// Reducer
const reducers = {
    [COURSES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [COURSES + LOADED](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: payload.result,
            initialLoadComplete: true,
        }
    },
    [COURSES + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [LOAD_SPECIFIC_COURSE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [LOAD_SPECIFIC_COURSE + LOADED](state, payload) {
        const course = [payload.result]

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: course,
        }
    },
    [LOAD_SPECIFIC_COURSE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [UPDATE_COURSE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [UPDATE_COURSE + LOADED](state, payload) {
        const updatedCourse = payload.result
        const courses = state.data.map(course => (course.id === updatedCourse.id ? updatedCourse : course))

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: courses,
        }
    },
    [UPDATE_COURSE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SUBMIT_RATING + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [SUBMIT_RATING + LOADED](state) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            isSuccessfullySubmitRating: true,
        }
    },
    [SUBMIT_RATING + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [RESET_SUBMIT_RATING](state) {
        return { ...state, isSuccessfullySubmitRating: false }
    },

    [SEND_ANALYTICS + LOADING](state, payload) {
        const newAnalyticsSentCourses = state.coursesWithAnalyticsSubmitted
        newAnalyticsSentCourses.push(payload)

        return {
            ...state,
            coursesWithAnalyticsSubmitted: newAnalyticsSentCourses,
        }
    },

    [SELECT_COURSE](state, payload) {
        if (!payload) {
            return {
                ...state,
                selectedCourse: null,
            }
        }
        return {
            ...state,
            selectedCourseId: payload,
        }
    },

    [COURSE_WIDE_SEARCH + LOADING](state) {
        return {
            ...state,
            courseWideSearch: {
                data: {},
                isLoading: true,
                loadingError: null,
                isSearchEmpty: false,
            },
        }
    },
    [COURSE_WIDE_SEARCH + LOADED](state, payload) {
        return {
            ...state,
            courseWideSearch: {
                ...state.courseWideSearch,
                data: payload.result,
                isLoading: false,
                loadingError: null,
                isSearchEmpty: false,
            },
        }
    },
    [COURSE_WIDE_SEARCH + ERROR](state, payload) {
        return {
            ...state,
            courseWideSearch: {
                ...state.courseWideSearch,
                data: {},
                isLoading: false,
                loadingError: payload.result,
                isSearchEmpty: false,
            },
        }
    },

    [COURSE_WIDE_SEARCH_EMPTY](state) {
        return {
            ...state,
            courseWideSearch: {
                data: {},
                isLoading: false,
                loadingError: null,
                isSearchEmpty: true,
            },
        }
    },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)
