import memoize from "memoize-one"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

export const calculateCategoryProgress = memoize((category) => {
    const questions = get(category, "category_questions", []);

    if (!questions || questions.length === 0) return 100;

    const totalQuestions = questions.reduce((total, question) => {
        if (question.group_questions) {
            return total + question.group_questions.length;
        }
        return total + 1;
    }, 0);

    const answeredQuestions = questions.reduce((total, question) => {
        if (question.group_questions) {
            if(question.answer){
              return  total += Object.keys(question.answer).length;
            }else{
                total += question.group_questions.reduce((groupTotal, groupQuestion) => {
                    return groupTotal + (groupQuestion.answer && !isEmpty(groupQuestion.answer) ? 1 : 0);
                }, 0);
            }
        }
        return total + (isEmpty(question.answer) ? 0 : 1);
    }, 0);

    return Math.ceil((answeredQuestions / totalQuestions) * 100);
});

export default calculateCategoryProgress
