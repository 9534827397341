import { isMobile, isTablet } from "react-device-detect"
import get from "lodash/get"
import {
    COURSES,
    LOAD_SPECIFIC_COURSE,
    UPDATE_COURSE,
    SELECT_COURSE,
    SEND_ANALYTICS,
    selectSelectedCourse,
    SUBMIT_RATING,
    RESET_SUBMIT_RATING,
    COURSE_WIDE_SEARCH,
    COURSE_WIDE_SEARCH_EMPTY,
} from "."

export function createLoadingAction() {
    return {
        type: COURSES,
        request: {
            method: "get",
            url: "courses",
        },
    }
}

export function createLoadCourseAction(courseId) {
    return {
        type: LOAD_SPECIFIC_COURSE,
        request: {
            method: "get",
            url: `courses/${courseId}`,
        },
    }
}

export function createUpdateCourseAction(courseId) {
    return {
        type: UPDATE_COURSE,
        request: {
            method: "get",
            url: `courses/${courseId}`,
        },
    }
}

export function createSelectCourseAction(courseId) {
    return {
        type: SELECT_COURSE,
        payload: courseId,
    }
}

export function createSendAnalyticsAction() {
    return (dispatch, getState) => {
        const course = selectSelectedCourse(getState())
        const isDesktop = !isMobile
        const deviceType = isTablet ? 3 : 2

        dispatch({
            type: SEND_ANALYTICS,
            payload: course.id,
            request: {
                method: "post",
                url: `courses/${course.id}/analytics`,
                data: {
                    device_type: isDesktop ? 1 : deviceType,
                },
            },
        })
    }
}

export function createSubmitRatingAction(courseId, rating) {
    return {
        type: SUBMIT_RATING,
        request: {
            method: "post",
            url: `courses/${courseId}/save_rating`,
            data: {
                rating,
            },
        },
    }
}

export function createResetSubmitRatingAction() {
    return {
        type: RESET_SUBMIT_RATING,
    }
}

export function createLoadCourseWithCallbackAction(courseId, callback) {
    return {
        type: LOAD_SPECIFIC_COURSE,
        request: {
            method: "get",
            url: `courses/${courseId}`,
        },
        callback,
    }
}

export const createCourseWideSearchAction = (query, callback) => (dispatch, getState) => {
    const course = selectSelectedCourse(getState())
    const courseId = get(course, "id", -1)
    dispatch({
        type: COURSE_WIDE_SEARCH,
        request: {
            method: "get",
            url: `courses/${courseId}/search?text=${query}`,
        },
        callback,
    })
}

export const createCourseWideSearchEmptyAction = () => ({
    type: COURSE_WIDE_SEARCH_EMPTY,
})
