import get from "lodash/get"
import jsonToFormData from "json-form-data"
import { createReducer } from "../../utility"
import reducerRegistery from "../../ReducerRegistery"
import reducers from "./reducers"
import {
    reducerName, CHECK_INVITATION, VALIDATE_ACCOUNT, CREATE_ACCOUNT, RESET, SET_STEP,
} from "./actions"

export const initialState = {
    step: 1,
    isLoading: false,
    invitation: null,
    groups: [],
    askFirstDay: false,
    strongPassword: false,
    fixedEmail: false,
    userData: {
        accessToken: null,
        refreshToken: null,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        firstDay: null,
        image: null,
        role: null,
        super_admin: null,
    },
}

export const selectWizardStep = state => get(state, `${reducerName}.step`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectInvitation = state => get(state, `${reducerName}.invitation`)
export const selectPolicyLink = state => get(state, `${reducerName}.policyLink`)
export const selectShouldAskFirstDay = state => get(state, `${reducerName}.askFirstDay`)
export const selectShouldAskStrongPassword = state => get(state, `${reducerName}.strongPassword`)
export const selectIsFixedEmail = state => get(state, `${reducerName}.fixedEmail`)
export const selectGroups = (state) => {
    const groups = get(state, `${reducerName}.groups`)
    return (groups || []).map(group => ({ id: group.id, value: group.title }))
}

export const selectAccessToken = state => get(state, `${reducerName}.userData.accessToken`)
export const selectRefreshToken = state => get(state, `${reducerName}.userData.refreshToken`)

export const selectUserEmail = state => get(state, `${reducerName}.userData.email`)
export const selectUserPassword = state => get(state, `${reducerName}.userData.password`)
export const selectUserFirstName = state => get(state, `${reducerName}.userData.firstName`)
export const selectUserLastName = state => get(state, `${reducerName}.userData.lastName`)
export const selectUserFirstDay = state => get(state, `${reducerName}.userData.firstDay`)
export const selectUserImage = state => get(state, `${reducerName}.userData.image`)
export const selectRole = state => get(state, `${reducerName}.userData.role`)
export const selectSuperUser = state => get(state, `${reducerName}.userData.super_admin`)

export * from "./actions"

export function createInvitationAction({ invitationCode }) {
    const cleanCode = invitationCode.trim()
    return {
        type: CHECK_INVITATION,
        payload: {
            code: cleanCode,
        },
        request: {
            method: "get",
            url: `/account/invite?invitation_code=${encodeURIComponent(cleanCode)}`,
        },
    }
}

export function createValidateAction(data) {
    return {
        type: VALIDATE_ACCOUNT,
        payload: {
            email: data.email,
            password: data.password,
        },
        request: {
            method: "put",
            url: "/account/validate",
            data,
        },
    }
}

export function createAccountAction(data) {
    const groups = data.selected_groups
    delete data.selected_groups

    const formData = jsonToFormData(data)

    // PAKI wants precisely this
    if (groups) {
        groups.forEach(group => formData.append("selected_groups[]", group))
    }

    if (data.image) {
        formData.append("image", data.image, "avatar.jpg")
    }

    return {
        type: CREATE_ACCOUNT,
        payload: {
            firstName: data.first_name,
            lastName: data.last_name,
        },
        request: {
            method: "post",
            url: "/account",
            data: formData,
        },
    }
}

export function createSetStepAction(step) {
    return {
        type: SET_STEP,
        payload: step,
    }
}

export function createResetAction() {
    return { type: RESET }
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
