import { applyMiddleware, compose, createStore } from "redux"
import persistCombineReducers from "redux-persist/lib/persistCombineReducers"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import formReducer from "redux-form/lib/reducer"
import createFilter from "redux-persist-transform-filter"
import { routerMiddleware, connectRouter } from "connected-react-router"

import client from "../configure/client"
import reducerRegistery from "./ReducerRegistery"
import createApiClient from "./middleware/apiClient"
import requestQueue from "./middleware/requestQueue"
import { formHandlerMiddleware } from "./middleware/formHandler"
import { reducerName as authReducerName, reducer as authReducer } from "./reducers/authentication"
import { reducerName as coursesReducerName, reducer as coursesReducer } from "./reducers/courses"
import { reducerName as firstLoadReducerName, reducer as firstLoadReducer } from "./reducers/firstLoad"
import { reducerName as notificationsReducerName, reducer as notificationsReducer } from "./reducers/notifications"
import { reducerName as languagesReducerName, reducer as languagesReducer } from "./reducers/languages"
import { reducerName as messagesReducerName, reducer as messagesReducer } from "./reducers/messages"

// Code splitting is disabled so I need to include all reducers here so they can add themselves.
import { reducerName as ChapterReducer } from "./reducers/chapters"
import { reducerName as ChecklistReducer } from "./reducers/checklist"
import { reducerName as InfoCatsReducer } from "./reducers/informationCategories"
import { reducerName as InfoCatReducer } from "./reducers/informationCategory"
import { reducerName as PagesReducer } from "./reducers/pages"
import { reducerName as StoriesReducer } from "./reducers/stories"
import { reducerName as UserReducer } from "./reducers/user"
import { reducerName as InvitationReducer } from "./reducers/invitation"
import { reducerName as JoinCoursesReducer } from "./reducers/joinCourses"
import { reducerName as ResetPasswordReducer } from "./reducers/resetPassword"
import { reducerName as FollowersReducer } from "./reducers/followers"
import { reducerName as ManagerReducer } from "./reducers/manager"
import { reducerName as HrFormReducer } from "./reducers/hrForm"
import { reducerName as OrganisationsReducer } from "./reducers/organisations"
import { reducerName as LeaderboardReducer } from "./reducers/leaderboard"
import { reducerName as UserDataDeletion } from "./reducers/userDataDeletion"
import { reducerName as UserPreferencesReducer } from "./reducers/userPreferences"

// Build server won't build with unused vars
[
    ChapterReducer,
    ChecklistReducer,
    InfoCatReducer,
    InfoCatsReducer,
    PagesReducer,
    StoriesReducer,
    UserReducer,
    InvitationReducer,
    JoinCoursesReducer,
    ResetPasswordReducer,
    FollowersReducer,
    ManagerReducer,
    HrFormReducer,
    OrganisationsReducer,
    LeaderboardReducer,
    UserDataDeletion,
    UserPreferencesReducer,
].find(() => true)

export const saveAuthFilter = createFilter(
    authReducerName,
    ["data", "redirectUrl", "twoFactorAuthorized", "tokenExpirationTime"],
)

export const loadAuthFilter = createFilter(
    authReducerName,
    null,
    ["data", "twoFactorAuthorized", "tokenExpirationTime"],
)

export const saveLanguageFilter = createFilter(
    languagesReducerName,
    ["appLanguage"],
)

export const loadLanguageFilter = createFilter(
    languagesReducerName,
    null,
    ["appLanguage"],
)

export const saveMessagesFilter = createFilter(
    messagesReducerName,
    ["tutorialSeen"],
)

export const loadMessagesFilter = createFilter(
    messagesReducerName,
    null,
    ["tutorialSeen"],
)

const storageConfig = {
    key: "root",
    storage,
    whitelist: [
        authReducerName,
        firstLoadReducerName,
        notificationsReducerName,
        languagesReducerName,
        messagesReducerName,
    ],
    transforms: [
        saveAuthFilter,
        loadAuthFilter,
        saveLanguageFilter,
        loadLanguageFilter,
        saveMessagesFilter,
        loadMessagesFilter,
    ],
}

const configureStore = (initialState = {}, history) => {
    // Add default reducers
    reducerRegistery.register("router", connectRouter(history))
    reducerRegistery.register("form", formReducer)
    reducerRegistery.register(authReducerName, authReducer)
    reducerRegistery.register(coursesReducerName, coursesReducer)
    reducerRegistery.register(firstLoadReducerName, firstLoadReducer)
    reducerRegistery.register(notificationsReducerName, notificationsReducer)
    reducerRegistery.register(languagesReducerName, languagesReducer)
    reducerRegistery.register(messagesReducerName, messagesReducer)


    const reducers = persistCombineReducers(storageConfig, reducerRegistery.getReducers())

    // eslint-disable-next-line no-undef,no-underscore-dangle
    const composeWithDevToolsExtension = process.env.REACT_APP_ENV === "dev" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    const composeEnhancers = (typeof composeWithDevToolsExtension === "function") ? composeWithDevToolsExtension : compose

    const middleware = composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            thunk,
            requestQueue,
            createApiClient(client),
            formHandlerMiddleware,
        ),
    )

    const store = createStore(reducers, initialState, middleware)

    return store
}

export default configureStore
