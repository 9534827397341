import { darken, getLuminance, lighten } from "@material-ui/core/styles/colorManipulator"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import isEmpty from "lodash/isEmpty"
import appicalTheme from "./appicalTheme"
import { languageIsRTL } from "../configure/i18n"

function getTextContrastColor(color) {
    return (getLuminance(color) > 0.5) ? appicalTheme.black : appicalTheme.white
}

// material-ui-pickers Override styles
const overrides = (input) => {
    const currentCompany = process.env.REACT_APP_COMPANY_NAME
    let data = {}
    if (currentCompany === "appical") {
        data = {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: input.contrast,
                },
            },
            MuiPickersYear: {
                root: {
                    "&:focus": {
                        color: input.contrast,
                    },
                },
                selected: {
                    color: input.contrast,
                },
            },
            MuiPickersDay: {
                selected: {
                    backgroundColor: input.contrast,
                    "&:hover": {
                        backgroundColor: input.contrast,
                    },
                },
                current: {
                    color: input.contrast,
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: input.contrast,
                },
            },
        }
    }

    data.MuiTooltip = {
        tooltip: {
            backgroundColor: "#111111"
        }
    }

    return data
}

export function convertToMuiTheme(input) {
    return createMuiTheme({
        appicalTheme: input,
        direction: languageIsRTL() ? "rtl" : "ltr",
        palette: {
            primary: {
                main: input.primary,
                light: input.primaryLight,
                dark: input.primaryDark,
                contrastText: input.primaryText,
            },
            secondary: {
                main: input.contrast,
                light: input.contrastLight,
                dark: input.contrastDark,
                contrastText: input.contrastText,
            },
        },
        typography: {
            useNextVariants: true,
            fontFamily: "'Open Sans',Roboto,'Helvetica Neue',Arial,sans-serif",
            h6: {
                fontSize: "1.625rem",
                fontWeight: 400,
                lineHeight: "2.063rem",
            },
            h1: {
                fontSize: "1.625rem",
                fontWeight: 400,
                lineHeight: "2.063rem",
            },
            h2: {
                fontSize: "1.625rem",
                fontWeight: 400,
                lineHeight: "2.063rem",
            },
            h3: {
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: "1.5rem",
            },
            subtitle1: {
                fontSize: "1.25rem",
                fontWeight: 400,
                lineHeight: "1.5rem",
            },
            body2: {
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: "1.5rem",
            },
           
            button: {
                fontSize: "0.875rem",
                fontWeight: 400,
                textTransform: "capitalize",
            },
            caption: {
                fontSize: "0.75rem",
                fontWeight: 400,
            },
        },
        props: {
            // The component name ⚛️
            MuiButtonBase: {
                // The property to apply
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        overrides: overrides(input),
    })
}

export function fillMissingThemeValues(input) {
    // Set all possible values based on the input
    const theme = { ...appicalTheme }

    // -- BASIC

    // Required values. Fallback to standard appical colors
    theme.primary = input.primary || appicalTheme.primary
    theme.contrast = input.contrast || appicalTheme.contrast

    // The rest can be inferred from the other values or overridden by the user.
    theme.contrastOnWhite = input.contrastOnWhite || theme.contrast

    // -- ADVANCED
    theme.primaryDark = darken(theme.primary, 0.12) // Dark and light versions are always inferred
    theme.primaryLight = lighten(theme.primary, 0.12)
    theme.primaryText = input.primaryText || getTextContrastColor(theme.primary) // Goes black or white for maximum contrast
    theme.primaryBackground = input.primaryBackground

    theme.contrastDark = darken(theme.contrast, 0.12) // Dark and light versions are always inferred
    theme.contrastLight = lighten(theme.contrast, 0.12)
    theme.contrastText = input.contrastText || getTextContrastColor(theme.contrast)

    theme.mainBg = input.mainBg || lighten(theme.primary, 0.9)

    theme.success = input.success || appicalTheme.success // Take these values from the default ThemeProvider unless specified in the input
    theme.successText = theme.white
    theme.successDark = darken(theme.success, 0.12)

    theme.error = input.error || appicalTheme.error
    theme.errorText = getTextContrastColor(theme.error)
    theme.score = input.score || appicalTheme.score
    theme.scoreText = getTextContrastColor(theme.score)

    theme.checkmark = input.checkmark || theme.success // Normally this is the success color

    // -- DASHBOARD
    theme.welcomeBg = input.welcomeBg || theme.primary
    theme.welcomeBgText = input.welcomeBgText || getTextContrastColor(theme.welcomeBg)
    theme.checklistBg = input.checklistBg || lighten(theme.primary, 0.8)

    // -- PAGES
    theme.bottomNavBg = input.bottomNavBg || lighten(theme.primary, 0.8)

    // -- LOGIN
    theme.useLoginGradient = input.useLoginGradient || true

    theme.loginBg1 = input.loginBg1 || theme.primary
    theme.loginBg2 = input.loginBg2 || lighten(theme.loginBg1, 0.2)
    theme.loginBgText = input.loginBgText || getTextContrastColor(theme.loginBg1)
    theme.loginButton = input.loginButton || theme.contrast
    theme.loginButtonText = input.loginButtonText || getTextContrastColor(theme.loginButton)
    theme.loginButtonDark = darken(theme.loginButton, 0.12)

    theme.resetPasswordBg1 = input.resetPasswordBg1 || theme.contrast
    theme.resetPasswordBg2 = input.resetPasswordBg2 || lighten(theme.resetPasswordBg1, 0.2)
    theme.resetPasswordBgText = input.resetPasswordBgText
        || getTextContrastColor(theme.resetPasswordBg1)
    theme.resetPasswordButton = input.resetPasswordButton || theme.primary
    theme.resetPasswordButtonText = input.resetPasswordButtonText
        || getTextContrastColor(theme.resetPasswordButton)
    theme.resetPasswordButtonDark = darken(theme.resetPasswordButton, 0.12)

    return theme
}

export function createThemeFromPakiApi(input) {
    const theme = {
        primary: (!isEmpty(input.primary)) ? input.primary : appicalTheme.primary,
        contrast: (!isEmpty(input.contrast)) ? input.contrast : appicalTheme.contrast,
        contrastOnWhite: (!isEmpty(input.contrast_text))
            ? input.contrast_text : appicalTheme.contrast,
        primaryBackground: input.primary_background,
    }

    return fillMissingThemeValues(theme)
}
