import get from "lodash/get"
import filter from "lodash/filter"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { SELECT_LANGUAGE } from "../languages"
import { selectSelectedCourse } from "../courses"

// Required variables
const initialState = {
    /* Holds the user redux object */
    data: {},
    /* Are we loading new category items */
    isLoading: false,
    /* Loading category items resulted in an error */
    loadingError: null,
    signings: null,
    item_viewed: {
        data: {},
        isLoading: false,
        loadingError: null,
    },
}
export const reducerName = "informationCategory"

// Actions
export const CATEGORY = createActionName(reducerName, "CATEGORY")
export const SIGNINGS = createActionName(reducerName, "SIGNINGS")
export const REMOVE_SIGNINGS = createActionName(reducerName, "REMOVE_SIGNINGS")
export const CATEGORY_ITEM_VIEWED = createActionName(reducerName, "CATEGORY_ITEM_VIEWED")

// Action creators
export function createLoadingAction(catId) {
    return {
        type: CATEGORY,
        request: {
            method: "get",
            url: `information_categories/${catId}`,
        },
    }
}

export function createLoadingSigningAction(catId, id) {
    return {
        type: SIGNINGS,
        request: {
            method: "get",
            url: `information_categories/${catId}/${id}`,
        },
    }
}

export function createRemoveSigningAction(id) {
    return {
        type: REMOVE_SIGNINGS,
        payload: { id },
    }
}

export const createItemViewedAction = (type, id, callback) => (dispatch, getState) => {
    const course = selectSelectedCourse(getState())
    if (!course.show_leaderboard) return false
    dispatch({
        type: CATEGORY_ITEM_VIEWED,
        request: {
            method: "post",
            url: `courses/${course.id}/information_categories/item_viewed`,
            data: {
                item_type: type,
                item_id: id,
            },
        },
        callback,
    })
}

// Selectors
export * from "./selectors"

// Reducer
const reducers = {
    [CATEGORY + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [CATEGORY + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [CATEGORY + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SIGNINGS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [SIGNINGS + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, signings: payload.result,
        }
    },
    [SIGNINGS + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },
    [REMOVE_SIGNINGS](state, payload) {
        const data = get(state, "data.signings")
        return {
            ...state,
            data: {
                ...state.data,
                signings: filter(data, val => val.id !== payload.id),
            },
        }
    },

    [CATEGORY_ITEM_VIEWED + LOADING](state) {
        return {
            ...state,
            item_viewed: {
                ...state.item_viewed,
                isLoading: true,
                loadingError: null,
            },
        }
    },
    [CATEGORY_ITEM_VIEWED + LOADED](state, payload) {
        return {
            ...state,
            item_viewed: {
                ...state.item_viewed,
                isLoading: false,
                data: payload.result,
            },
        }
    },
    [CATEGORY_ITEM_VIEWED + ERROR](state, payload) {
        return {
            ...state,
            item_viewed: {
                ...state.item_viewed,
                isLoading: false,
                loadingError: payload.result,
            },
        }
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
