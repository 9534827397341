import get from "lodash/get"
import includes from "lodash/includes"
import jsonToFormData from "json-form-data"
import {
    selectOneToOneConversations,
    selectCurrentConversationMembers,
    selectConversationId,
    selectLoadedConversations,
    ADD_MESSAGE,
    CREATE_GROUP,
    EDIT_GROUP,
    MEMBERS,
    MESSAGES,
    PREVIEWS,
    SEND_MESSAGE,
    UPDATE_CONVERSATION,
    ADD_PREVIEW,
    REMOVE_PREVIEW,
    EDIT_PREVIEW,
    MARK_AS_READ,
    UPDATE_CONVERSATION_STATUS,
    MARK_TUTORIAL_AS_SEEN,
    TUTORIAL_SEEN,
} from "./index"
import { selectUser } from "../user"

export function createLoadMembersAction() {
    return {
        type: MEMBERS,
        request: {
            method: "get",
            url: "chat/members",
        },
    }
}
export function createLoadPreviewsAction() {
    return {
        type: PREVIEWS,
        request: {
            method: "get",
            url: "chat/conversations",
        },
    }
}
export function createLoadMessagesAction() {
    return (dispatch, getState) => {
        const conversationId = selectConversationId(getState())
        if (!conversationId) return

        dispatch({
            type: MESSAGES,
            request: {
                method: "get",
                url: `chat/conversations/${conversationId}/messages`,
            },
            payload: conversationId,
        })
    }
}
export function createAddMessageAction(data) {
    return {
        type: ADD_MESSAGE,
        payload: data,
    }
}
export function createAddPreviewAction(data) {
    return ({
        type: ADD_PREVIEW,
        payload: data,
    })
}
export function createRemovePreviewAction(data) {
    return ({
        type: REMOVE_PREVIEW,
        payload: data,
    })
}
export function createEditPreviewAction(data) {
    return (dispatch, getState) => {
        const currentConversation = selectConversationId(getState())
        const loadedChannels = selectLoadedConversations(getState())

        const conversationId = data.conversation_id || data.message.conversation_id

        if (conversationId !== currentConversation && includes(loadedChannels, conversationId)) {
            dispatch({
                type: ADD_MESSAGE,
                payload: data,
            })
        }
        dispatch({
            type: EDIT_PREVIEW,
            payload: data,
        })
    }
}
export function createUpdateConversationAction(conversation) {
    return {
        type: UPDATE_CONVERSATION,
        payload: conversation,
    }
}
export function createOpenOneToOneConversationAction(user, onComplete) {
    return (dispatch, getState) => {
        const self = selectUser(getState())
        const existingOneToOnes = selectOneToOneConversations(getState())
        const existingConversation = existingOneToOnes[user.user_chat_id]
        let conversation = null

        if (existingConversation) {
            conversation = {
                channelId: existingConversation.channel_id,
                members: existingConversation.other_users,
                conversationName: existingConversation.channel_name,
                avatar: existingConversation.channel_avatar,
                conversationId: existingConversation.id,
                status: 1,
            }
        } else {
            const newConversationMembers = [{ ...user, user_id: user.user_chat_id }, self]
            conversation = {
                members: newConversationMembers,
                conversationName: `${user.first_name} ${user.last_name}`,
                avatar: user.image_url,
                conversationId: "new",
                status: 1,
                channelId: null,
            }
        }

        dispatch({
            type: UPDATE_CONVERSATION,
            payload: conversation,
        })
        if (onComplete) onComplete(conversation)
    }
}
export function createCreateGroupAction(users, name, avatar) {
    let data = {
        group: {
            name,
        },
    }

    if (avatar) {
        data = jsonToFormData(data)
        data.append("group[avatar]", avatar, "avatar.png")
        users.forEach((user) => {
            data.append("group[users][]", `${user}`)
        })
    } else {
        data = {
            ...data,
            group: {
                ...data.group,
                users,
            },
        }
    }

    return {
        type: CREATE_GROUP,
        request: {
            method: "post",
            url: "chat/groups",
            data,
        },
    }
}
export function createEditGroupAction(conversationId, newGroupData) {
    const { name, avatar, users } = newGroupData
    const dataToUpdate = {}
    const data = jsonToFormData({
        group: {},
    })

    if (name) {
        data.append("group[name]", name)
        dataToUpdate.name = name
    }

    if (avatar) {
        data.append("group[avatar]", avatar, "avatar.png")
        dataToUpdate.avatar = URL.createObjectURL(avatar)
    }

    if (users) {
        users.forEach((user) => {
            data.append("group[users][]", `${user.user_id}`)
        })
        dataToUpdate.users = users
    }

    return {
        type: EDIT_GROUP,
        request: {
            method: "put",
            url: `chat/groups/${conversationId}`,
            data,
        },
        payload: {
            conversationId,
            dataToUpdate,
        },
    }
}
export function createSendMessageAction(values) {
    const { message, attachment } = values
    return (dispatch, getState) => {
        const state = getState()
        const user = selectUser(state)
        const members = selectCurrentConversationMembers(state)
        const conversationId = selectConversationId(state)
        const isGroup = members && members.length > 2

        let data = isGroup
            ? {
                message_to: "group",
                message: {
                    to: conversationId,
                    body: message || ".",
                },
            } : {
                message_to: "user",
                message: {
                    to: members
                        .find(member => member.user_id !== get(user, "user_chat_id"))
                        .user_id,
                    body: message || ".",
                },
            }

        if (attachment) {
            data = jsonToFormData(data)
            data.append("message[attachment]", attachment, attachment.name || "attachment.jpg")
        }

        dispatch({
            type: SEND_MESSAGE,
            request: {
                method: "post",
                url: "chat/conversations",
                data,
            },
        })
    }
}
export function createMarkAsReadAction(messageId, conversationId) {
    return {
        type: MARK_AS_READ,
        request: {
            method: "put",
            url: `chat/messages/${messageId}`,
            data: conversationId
                ? {
                    message_read: true,
                    group_id: conversationId,
                } : {
                    message_read: true,
                },
        },
    }
}
export function createChangeStatusAction(conversationId, status) {
    return {
        type: UPDATE_CONVERSATION_STATUS,
        request: {
            method: "put",
            url: `chat/conversations/${conversationId}/archive?value=${status}`,
        },
        payload: {
            conversationId,
            value: status,
        },
    }
}

export function createMarkTutorialAsSeenAction(payload) {
    return {
        type: MARK_TUTORIAL_AS_SEEN,
        payload,
    }
}

export function createTutorialSeenAction() {
    return {
        type: TUTORIAL_SEEN,
        request: {
            method: "post",
            url: "chat/messages/tutorial_seen",
        },
        callback: (dispatch) => {
            dispatch(createMarkTutorialAsSeenAction(true))
        },
    }
}
