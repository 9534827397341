/* istanbul ignore file */
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { firebaseConfig } from './constants';

export const isPushSupported = 'serviceWorker' in navigator && 'PushManager' in window;

firebase.initializeApp(firebaseConfig.init);
const messaging = isPushSupported ? firebase.messaging() : null;

// eslint-disable-next-line import/prefer-default-export
export const getToken = (setTokenFound) => {
  if (messaging) {
    messaging
      .getToken({ vapidKey: firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  } else {
    console.log('Push notifications are not supported.');
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });
