import reducerRegistry from "../../ReducerRegistery";
import { createReducer } from "../../utility";
import { CLEAR_DATA, SET_IS_REQUEST_SENT, TOGGLE_IS_LOADING, SET_ERROR_MESSAGE, SET_IS_CONFIRMATION_EMAIL_SENT } from "./actionCreatores"

// Define initial state
export const initialState = {
    isLoading: false,
    errorMessage: "",
    isRequestSent: false,
    isConfirmationEmailSent: false,
    defaultErrorMessage: "Oops! It looks like something went wrong. Please try again later."
};

// Reducer name
export const reducerName = "userDataDeletion";

// Actions
export * from "./actionCreatores"

// Selectors
export * from "./selectors"

// Reducer
const reducers = {
    [SET_IS_REQUEST_SENT]: state => ({ ...state, isRequestSent: true }),
    [SET_IS_CONFIRMATION_EMAIL_SENT]: state => ({ ...state, isConfirmationEmailSent: true }),
    [TOGGLE_IS_LOADING]: state => ({ ...state, isLoading: !state.isLoading }),
    [SET_ERROR_MESSAGE]: (state, payload) => ({ ...state, errorMessage: payload }),
    [CLEAR_DATA]: () => initialState
};

export const reducer = createReducer(reducers, initialState);

reducerRegistry.register(reducerName, reducer);
