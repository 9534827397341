import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import {
    CHECK_INVITATION, VALIDATE_ACCOUNT, CREATE_ACCOUNT, RESET, SET_STEP,
} from "./actions"

import { initialState } from "."

export default {
    [CHECK_INVITATION + LOADING](state, payload) {
        return {
            ...state,
            isLoading: true,
            invitation: payload.code,
        }
    },

    [CHECK_INVITATION + LOADED](state, payload) {
        const {
            groups,
            strong_password,
            should_ask_first_day,
            email,
            first_name,
            last_name,
            first_day,
            policy_link,
        } = payload.result

        return {
            ...state,
            step: 2,
            isLoading: false,
            groups,
            askFirstDay: should_ask_first_day,
            strongPassword: strong_password,
            fixedEmail: !!email,
            policyLink: policy_link,
            userData: {
                ...state.userData,
                email,
                firstDay: first_day,
                firstName: first_name,
                lastName: last_name,
                image: null,
            },
        }
    },

    [CHECK_INVITATION + ERROR](state) {
        return {
            ...state,
            isLoading: false,
        }
    },

    [VALIDATE_ACCOUNT + LOADING](state, payload) {
        return {
            ...state,
            isLoading: true,
            userData: {
                ...state.userData,
                ...payload,
            },
        }
    },

    [VALIDATE_ACCOUNT + LOADED](state) {
        return {
            ...state,
            step: 3,
            isLoading: false,
        }
    },

    [VALIDATE_ACCOUNT + ERROR](state) {
        return {
            ...state,
            isLoading: false,
        }
    },

    [CREATE_ACCOUNT + LOADING](state, payload) {
        return {
            ...state,
            isLoading: true,
            userData: {
                ...state.userData,
                ...payload,
            },
        }
    },

    [CREATE_ACCOUNT + LOADED](state, payload) {
        const {
            access_token,
            refresh_token,
            image_url,
            role,
            super_admin,
        } = payload.result

        return {
            ...state,
            isLoading: false,
            step: 4,
            userData: {
                ...state.userData,
                accessToken: access_token,
                refreshToken: refresh_token,
                image: image_url,
                role,
                super_admin,
            },
        }
    },

    [CREATE_ACCOUNT + ERROR](state) {
        return {
            ...state,
            isLoading: false,
        }
    },

    [SET_STEP](state, payload) {
        return {
            ...state,
            step: payload,
        }
    },

    [RESET]() {
        return initialState
    },
}
