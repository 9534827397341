import get from "lodash/get"
import { createActionName, createReducer } from "../../utility"
import reducerRegistery from "../../ReducerRegistery"
import { LOGOUT, SESSION_EXPIRED } from "../authentication"
import { LOADED } from "../../middleware/actions"


// Holds requests that could not be executed because the auth token was refreshing

// Required variables
const initialState = {
    /* Holds request actions in the queue until the token is refreshed */
    queue: [],
}
export const reducerName = "requestQueue"

// Actions
export const ADD_REQUEST = createActionName(reducerName, "ADD_REQUEST")
export const FLUSH_REQUEST_QUEUE = createActionName(reducerName, "FLUSH_REQUEST_QUEUE")

// Action creators
export function createAddRequestAction(requestAction) {
    return { type: ADD_REQUEST, payload: requestAction }
}

export function createFlushQueueAction() {
    return { type: FLUSH_REQUEST_QUEUE }
}

// Selectors
export const selectRequests = state => get(state, `${reducerName}.queue`)

// Reducer
const reducers = {
    [ADD_REQUEST](state, payload) {
        // No use adding the same requests multiple times
        const requestAction = payload.type
        if (state.queue.find(request => request.type === requestAction)) {
            return state
        }

        return {
            ...state,
            queue: [...state.queue, payload],
        }
    },

    // reset state
    [LOGOUT + LOADED]() {
        return initialState
    },
    [FLUSH_REQUEST_QUEUE]() { return initialState },

    [SESSION_EXPIRED]() { return initialState },
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
