import { connect } from "react-redux"
import {
    createRedirectUrlAction,
    createSetGCMTokenAction,
    selectIsLoggedIn,
    selectRedirectUrl,
    selectHasExpiredPassword,
    selectRequires2FA,
    select2FAAuthorized,
} from "../redux/reducers/authentication"
import {
    createLoadNotificationsAction,
    selectHasUnreadNotifications,
} from "../redux/reducers/notifications"
import {
    selectAppLanguage,
} from "../redux/reducers/languages"

import {
    selectUser,
} from "../redux/reducers/user"

import { createLoadUserPreferences, selectUserPreferences } from "../redux/reducers/userPreferences"

const mapDispatchToProps = {
    setRedirectUrl: createRedirectUrlAction,
    setGCMToken: createSetGCMTokenAction,
    loadNotifications: createLoadNotificationsAction,
    loadUserPreferences: createLoadUserPreferences,
}

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    redirectUrl: selectRedirectUrl(state),
    hasExpiredPassword: selectHasExpiredPassword(state),
    requires2FA: selectRequires2FA(state),
    twoFactorAuthorized: select2FAAuthorized(state),
    appLanguage: selectAppLanguage(state),
    user: selectUser(state),
    hasUnreadNotifications: selectHasUnreadNotifications(state),
    userPreferences: selectUserPreferences(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
