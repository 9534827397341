import reducerRegistery from "../../ReducerRegistery";
import { createReducer } from "../../utility";
import { ERROR, LOADED, LOADING } from "../../middleware/actions";

import { RESET_USER_PREFERENCES, USER_PREFERENCES, UPDATE_USER_PREFERENCES } from "./actionCreatores";


// Define initial state for user preferences
export const initialState = {
    id: null,
    userId: null,
    showCompletedChecklistItems: false,
    appLanguageIdentifier: "en",
    isLoading: false,
    error: ''
}

// Reducer name
export const reducerName = "userPreferences";

// Actions
export * from "./actionCreatores"

// Selectors
export * from "./selectors"

// Reducer
const reducers = {
    [USER_PREFERENCES + LOADING]: state => {
        return {
            ...state,
            isLoading: true
        }
    },
    [USER_PREFERENCES + LOADED]: (state, { result }) => {
        return {
            ...state,
            id: result.id,
            userId: result.user_id,
            showCompletedChecklistItems: result.show_completed_checklist_items,
            appLanguageIdentifier: result.app_language_identifier,
            isLoading: false
        }

    },
    [USER_PREFERENCES + ERROR]: (state, { result }) => {
        const errorDescription = result?.response?.data?.error_description || "Error loading user preferences"
        return {
            ...state,
            error: errorDescription,
            isLoading: false
        }
    },
    [RESET_USER_PREFERENCES]: () => initialState,
    [UPDATE_USER_PREFERENCES + LOADING]: state => ({ ...state, isLoading: true }),
    [UPDATE_USER_PREFERENCES + LOADED]: (state, { result }) => {
        return {
            ...state,
            id: result.id,
            userId: result.user_id,
            showCompletedChecklistItems: result.show_completed_checklist_items,
            appLanguageIdentifier: result.app_language_identifier,
            isLoading: false
        }
    },
    [UPDATE_USER_PREFERENCES + ERROR]: (state, { result }) => {
        const errorDescription = result?.response?.data?.error_description || "Error updating user preferences"
        return {
            ...state,
            error: errorDescription,
            isLoading: false
        }
    }
}

export const reducer = createReducer(reducers, initialState);

reducerRegistery.register(reducerName, reducer);