import jsonToFormData from "json-form-data"

import get from "lodash/get"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { LOADING, LOADED, ERROR } from "../../middleware/actions"
import { SELECT_COURSE } from "../courses"
import { LOGOUT } from "../authentication"

// Required variables
const initialState = {
    data: null,
    isLoading: {
        newHires: false,
        newHireDetail: false,
        introduction: false,
        tipsAndTricks: false,
        todo: false,
        unapproved: false,
        approveNewHire: false,
        availableManagers: false,
        managersForward: false,
        pendingNewHires: false,
        confirmedNewHires: false,
        newHireStories: false,
        newHireChapters: false,
    },
    errors: null,
    newHireSearchTerm: null,
    tabTitle: null,
    isFirstLoad: false,
    isEssay: false,
    ispopUpOpen: false
}
export const reducerName = "manager"

// Selectors
export * from "./selectors"

// Actions
export const GET_NEW_HIRES = createActionName(reducerName, "GET_NEW_HIRES")
export const GET_PENDING_NEW_HIRES = createActionName(reducerName, "GET_PENDING_NEW_HIRES")
export const GET_CONFIRMED_NEW_HIRES = createActionName(reducerName, "GET_CONFIRMED_NEW_HIRES")
export const PROFILE_OPEN = createActionName(reducerName, "PROFILE_OPEN")
export const GET_NEW_HIRE_DETAIL = createActionName(reducerName, "GET_NEW_HIRE_DETAIL")
export const GET_TODO_LIST = createActionName(reducerName, "GET_TODO_LIST")
export const TODO_CHECK_ITEM = createActionName(reducerName, "TODO_CHECK_ITEM")
export const LOAD_INTRODUCTION = createActionName(reducerName, "LOAD_INTRODUCTION")
export const UPDATE_INTRODUCTION = createActionName(reducerName, "UPDATE_INTRODUCTION")
export const GET_TIPS_AND_TRICKS = createActionName(reducerName, "GET_TIPS_AND_TRICKS")
export const GET_UNAPPROVED_HIRES = createActionName(reducerName, "GET_UNAPPROVED_HIRES")
export const APPROVE_NEW_HIRE = createActionName(reducerName, "APPROVE_NEW_HIRE")
export const REMOVE_NEW_HIRE = createActionName(reducerName, "REMOVE_NEW_HIRE")
export const SHOW_ESSAY = createActionName(reducerName, "SHOW_ESSAY")
export const UPDATE_NEW_HIRE = createActionName(reducerName, "UPDATE_NEW_HIRE")
export const HIDE_ESSAY = createActionName(reducerName, "HIDE_ESSAY")
export const AVAILABLE_MANAGERS = createActionName(reducerName, "AVAILABLE_MANAGERS")
export const MANAGERS_FORWARD = createActionName(reducerName, "MANAGERS_FORWARD")
export const GET_STORIES = createActionName(reducerName, "GET_STORIES")
export const GET_STORY_CHAPTERS = createActionName(reducerName, "GET_STORY_CHAPTERS")
export const SEND_INTRODUCTION = createActionName(reducerName, "SEND_INTRODUCTION")
export const POPUP_OPEN = createActionName(reducerName, "POPUP_OPEN")
// Action creators
export function createGetIntroductionAction(callback) {
    return {
        type: LOAD_INTRODUCTION,
        request: {
            method: "get",
            url: "manager/introduction",
        },
        callback,
    }
}

export function createGetTodosAction(callback) {
    return (dispatch) => {
        dispatch({
            type: GET_TODO_LIST,
            request: {
                url: "manager/checklists",
                method: "GET",
            },
            callback,
        })
    }
}

export function createTodosCheckItemAction(item) {
    return (dispatch) => {
        dispatch({
            type: TODO_CHECK_ITEM,
            request: {
                method: "post",
                url: `manager/checklists/${item.id}`,
                data: {
                    user_id: item.user_id,
                    checked: !item.checked === true ? "1" : "0",
                },
            },
        })
    }
}

export function createPostIntroductionAction(data, file, type, callback) {
    let values = data

    values = jsonToFormData(data)
    if (file && type === "image") {
        values.append("image", file)
    }
    if (file && type === "video") {
        values.append("video", file)
    }

    return {
        type: UPDATE_INTRODUCTION,
        request: {
            method: "post",
            url: "manager/introduction",
            data: values,
            onUploadProgress(progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                callback(percentCompleted)
            },
        },
    }
}

export function createGetNewHiresAction(searchTerm, callback) {
    return {
        type: GET_CONFIRMED_NEW_HIRES,
        request: {
            method: "get",
            url: searchTerm ? `manager/newhires?search=${searchTerm}` : "manager/newhires",
        },
        callback,
    }
}

export function createGetPendingNewHiresAction(searchTerm, pageId = 1, callback) {
    return {
        type: GET_PENDING_NEW_HIRES,
        payload: {
            urlType: searchTerm ? "search" : "normal",
        },
        request: {
            method: "get",
            url: searchTerm ? `manager/newhires/pending?search=${searchTerm}&page=${pageId}` : `manager/newhires/pending?page=${pageId}`,
        },
        callback,
    }
}

export function createGetConfirmedNewHiresAction(searchTerm, pageId, callback) {
    return {
        type: GET_CONFIRMED_NEW_HIRES,
        payload: {
            urlType: searchTerm ? "search" : "normal",
        },
        request: {
            method: "get",
            url: searchTerm ? `manager/newhires/confirmed?search=${searchTerm}&page=${pageId}` : `manager/newhires/confirmed?page=${pageId}`,
        },
        callback,
    }
}

export function createNewHireDetailAction(id, callback) {
    return {
        type: GET_NEW_HIRE_DETAIL,
        request: {
            method: "get",
            url: `manager/newhires/${id}/profile`,
        },
        callback,
    }
}
export function createUpdateNewHireAction(id) {
    return {
        type: UPDATE_NEW_HIRE,
        payload: {
            updatedHireId: id,

        },
    }
}
export function createPostApprovalAction(newhire, approved, sendIntroMessage, callback) {
    return {
        request: {
            method: "post",
            url: `manager/newhires/${newhire}/manager_approved`,
            data: {
                // Please, don't ask...
                approved: approved ? true : "false",
                send_intro_message: sendIntroMessage,
            },
        },
        callback,
    }
}

export function createNewHireApprovalAction(id, callback) {
    return {
        type: APPROVE_NEW_HIRE,
        request: {
            method: "post",
            url: "manager/newhires",
            data: { id },
        },
        callback,
    }
}

export function createNewHireRemovalAction(id, callback) {
    return {
        type: REMOVE_NEW_HIRE,
        request: {
            method: "delete",
            url: `manager/newhires/${id}`,
        },
        callback,
    }
}

export function createGetTipsAndTricksAction(lng, searchTerm) {
    const params = []
    if (lng) {
        params.push(`language_id=${lng}`)
    }
    if (searchTerm) {
        params.push(`search=${searchTerm}`)
    }
    return {
        type: GET_TIPS_AND_TRICKS,
        request: {
            method: "get",
            url: `manager/tips?${params.join("&")}`,
        },
    }
}

export function createGetUnapprovedHiresAction(callback) {
    return {
        type: GET_UNAPPROVED_HIRES,
        request: {
            method: "get",
            url: "manager/newhires/list",
        },
        callback,
    }
}

export function createShowEssayAction() {
    return {
        type: SHOW_ESSAY,
    }
}

export function createHideEssayAction() {
    return {
        type: HIDE_ESSAY,
    }
}

export function createGetAvailableManagersAction() {
    return {
        type: AVAILABLE_MANAGERS,
        request: {
            method: "get",
            url: "manager/newhires/available_managers",
        },
    }
}

// manager id event email first name last name
export function createSubmitManagerForwardAction(newHireId, data, callback) {
    return {
        type: MANAGERS_FORWARD,
        request: {
            method: "post",
            url: `manager/newhires/${newHireId}/forward`,
            data,
        },
        callback,
    }
}

export function createSelectCourseStories(newHireId, courseId, callback) {
    return {
        type: GET_STORIES,
        request: {
            method: "get",
            url: `manager/newhires/${newHireId}/courses/${courseId}/stories`,
        },
        payload: {
            newHireId,
            courseId,
        },
        callback,
    }
}

export function createSelectStoryChapters(newHireId, courseId, storyId, callback) {
    return {
        type: GET_STORY_CHAPTERS,
        request: {
            method: "get",
            url: `manager/newhires/${newHireId}/stories/${storyId}/chapters`,
        },
        payload: {
            newHireId,
            courseId,
            storyId,
        },
        callback,
    }
}

export function createSendIntroductionMessage(newHireId, approved, send_intro_message, callback) {
    return {
        type: SEND_INTRODUCTION,
        request: {
            method: "post",
            url: `manager/newhires/${newHireId}/send_introduction_message`,
        },
        payload: {
            newHireId,
            approved,
            send_intro_message,
        },
        callback,
    }
}
export function createSetprofileOpen(isOpen) {
    return {
        type: PROFILE_OPEN,
        payload: {
            isOpen,
        },
    }
}
export function createIsPopUpOpen(ispopUpOpen){
    return {
        type: POPUP_OPEN,
        payload: {
            ispopUpOpen,
        },
    }
}
// Reducer
const reducers = {
    [SHOW_ESSAY](state) {
        return {
            ...state,
            isEssay: true,
        }
    },
    [POPUP_OPEN](state, payload){
        return {
            ...state,
            ispopUpOpen: payload.ispopUpOpen,
        }
    },

    [UPDATE_NEW_HIRE](state, payload) {
        return {
            ...state,
            updatedHireId: payload.updatedHireId,
        }
    },
    [HIDE_ESSAY](state) {
        return {
            ...state,
            isEssay: false,
        }
    },
    [APPROVE_NEW_HIRE + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, approveNewHire: true },
            data: {
                ...state.data,
                approveNewHire: false,
            },
        }
    },

    [APPROVE_NEW_HIRE + LOADED](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, approveNewHire: false },
            data: {
                ...state.data,
                approveNewHire: true,
            },
        }
    },

    [APPROVE_NEW_HIRE + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, approveNewHire: false },
        }
    },

    [REMOVE_NEW_HIRE + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, removeNewHire: true },
        }
    },

    [REMOVE_NEW_HIRE + LOADED](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, removeNewHire: false },
        }
    },

    [REMOVE_NEW_HIRE + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, removeNewHire: false },
        }
    },

    [GET_UNAPPROVED_HIRES + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, unapproved: true },
        }
    },

    [GET_UNAPPROVED_HIRES + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, unapproved: false },
            data: {
                ...state.data,
                unapprovedHires: payload.result,
            },
        }
    },

    [GET_UNAPPROVED_HIRES + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, unapproved: false },
        }
    },

    [GET_NEW_HIRES + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, newHires: true },
        }
    },

    [GET_NEW_HIRES + LOADED](state, payload) {
        const newHires = get(payload, "result.new_hires", [])
        return {
            ...state,
            isLoading: { ...state.isLoading, newHires: false },
            data: {
                ...state.data,
                newHires,
            },
        }
    },

    [GET_NEW_HIRES + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, newHires: false },
        }
    },

    [GET_PENDING_NEW_HIRES + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, pendingNewHires: true },
        }
    },

    [GET_PENDING_NEW_HIRES + LOADED](state, payload) {
        const pendingNewHires = get(payload, "result.new_hires", [])
        const nextPage = get(payload, "result.next_page", 0)
        const totalCount = get(payload, "result.total_count", 0)
        const previousNewHires = get(state, "data.pendingNewHires.newHires", [])
        const urlType = get(payload, "originalPayload.urlType", "normal")
        return {
            ...state,
            isLoading: { ...state.isLoading, pendingNewHires: false },
            data: {
                ...state.data,
                pendingNewHires: {
                    ...state.data.pendingNewHires,
                    nextPage,
                    totalCount,
                    newHires: urlType === "normal" ? [...previousNewHires, ...pendingNewHires] : pendingNewHires,
                },
            },
        }
    },

    [GET_PENDING_NEW_HIRES + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, pendingNewHires: false },
        }
    },

    [GET_CONFIRMED_NEW_HIRES + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, confirmedNewHires: true },
        }
    },

    [GET_CONFIRMED_NEW_HIRES + LOADED](state, payload) {
        const confirmedNewHires = get(payload, "result.new_hires", [])
        const nextPage = get(payload, "result.next_page", null)
        const totalCount = get(payload, "result.total_count", 0)
        const previousNewHires = get(state, "data.confirmedNewHires.newHires", [])
        const urlType = get(payload, "originalPayload.urlType", "normal")
        return {
            ...state,
            isLoading: { ...state.isLoading, confirmedNewHires: false },
            data: {
                ...state.data,
                confirmedNewHires: {
                    ...state.data.confirmedNewHires,
                    nextPage,
                    totalCount,
                    newHires: urlType === "normal" ? [...previousNewHires, ...confirmedNewHires] : confirmedNewHires,
                },
            },
        }
    },

    [GET_CONFIRMED_NEW_HIRES + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, confirmedNewHires: false },
        }
    },

    [GET_NEW_HIRE_DETAIL + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireDetail: true },
        }
    },

    [GET_NEW_HIRE_DETAIL + LOADED](state, payload) {
        const previousConfirmedNewHires = get(state, "data.confirmedNewHires.newHires", [])
        const previousPendingNewHires = get(state, "data.pendingNewHires.newHires", [])
        const updatedNewHire = get(payload, "result", {})
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireDetail: false },
            data: {
                ...state.data,
                confirmedNewHires: {
                    ...state.data.confirmedNewHires,
                    newHires: previousConfirmedNewHires.map(newHire => (newHire.id === updatedNewHire.id ? updatedNewHire : newHire)),
                },
                pendingNewHires: {
                    ...state.data.pendingNewHires,
                    newHires: previousPendingNewHires.map(newHire => (newHire.id === updatedNewHire.id ? updatedNewHire : newHire)),
                },
            },
        }
    },

    [GET_NEW_HIRE_DETAIL + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, newHireDetail: false },
        }
    },

    [LOAD_INTRODUCTION + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, introduction: true },
        }
    },

    [LOAD_INTRODUCTION + LOADED](state, payload) {
        if (payload.result) {
            return {
                ...state,
                isLoading: { ...state.isLoading, introduction: false },
                data: {
                    ...state.data,
                    introduction: payload.result,
                },
            }
        }
        return {
            ...state,
            isLoading: { ...state.isLoading, introduction: false },
            data: {
                ...state.data,
            },
        }
    },

    [LOAD_INTRODUCTION + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, introduction: false },
        }
    },

    [GET_TODO_LIST + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, todo: true },
        }
    },

    [GET_TODO_LIST + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, todo: false },
            data: {
                ...state.data,
                todo: payload.result,
            },
        }
    },

    [GET_TODO_LIST + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, todo: false },
        }
    },

    [TODO_CHECK_ITEM + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, todo: true },
        }
    },

    [TODO_CHECK_ITEM + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, todo: false },
            data: {
                ...state.data,
                todo: payload.result,
            },
        }
    },

    [TODO_CHECK_ITEM + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, todo: false },
        }
    },

    [UPDATE_INTRODUCTION + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, introduction: true },
        }
    },

    [UPDATE_INTRODUCTION + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, introduction: false },
            data: {
                ...state.data,
                introduction: payload.result,
            },
        }
    },

    [UPDATE_INTRODUCTION + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, introduction: false },
        }
    },

    [GET_TIPS_AND_TRICKS + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, tipsAndTricks: true },
        }
    },

    [GET_TIPS_AND_TRICKS + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, tipsAndTricks: false },
            data: {
                ...state.data,
                tipsAndTricks: payload.result,
            },
            tabTitle: payload.result.tab_title,
        }
    },

    [GET_TIPS_AND_TRICKS + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, tipsAndTricks: false },
        }
    },

    [AVAILABLE_MANAGERS + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, availableManagers: true },
        }
    },

    [AVAILABLE_MANAGERS + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, availableManagers: false },
            data: {
                ...state.data,
                availableManagers: payload.result,
            },
        }
    },

    [AVAILABLE_MANAGERS + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, availableManagers: false },
        }
    },

    [MANAGERS_FORWARD + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, managersForward: true },
        }
    },

    [MANAGERS_FORWARD + LOADED](state, payload) {
        return {
            ...state,
            isLoading: { ...state.isLoading, managersForward: false },
            data: {
                ...state.data,
                managersForward: payload.result,
            },
        }
    },

    [MANAGERS_FORWARD + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, managersForward: false },
        }
    },

    [SEND_INTRODUCTION + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, introduction: true },
            data: {
                ...state.data,
            },
        }
    },

    [SEND_INTRODUCTION + LOADED](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, introduction: false },
            data: {
                ...state.data,
            },
        }
    },

    [SEND_INTRODUCTION + ERROR](state, payload) {
        return {
            ...state,
            errors: payload.result,
            isLoading: { ...state.isLoading, introduction: false },
        }
    },
    // get manager course stories details
    [GET_STORIES + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireStories: true },

        }
    },
    [GET_STORIES + LOADED](state, payload) {
        const confirmedNewHires = get(state, "data.confirmedNewHires.newHires", [])
        const pendingNewHires = get(state, "data.pendingNewHires.newHires", [])
        const newHireId = get(payload, "originalPayload.newHireId", -1) // set -1 if not found any new hire id
        const courseId = get(payload, "originalPayload.courseId", -1)
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireStories: false },
            data: {
                ...state.data,
                confirmedNewHires: {
                    ...state.data.confirmedNewHires,
                    newHires: confirmedNewHires.map((newHire) => {
                        if (newHire.id === newHireId) {
                            return {
                                ...newHire,
                                courses_status: newHire.courses_status.map(course => (
                                    course.id === courseId
                                        ? {
                                            ...course,
                                            stories: payload.result,
                                        } : course)),
                            }
                        }
                        return newHire
                    }),
                },
                pendingNewHires: {
                    ...state.data.pendingNewHires,
                    newHires: pendingNewHires.map((newHire) => {
                        if (newHire.id === newHireId) {
                            return {
                                ...newHire,
                                courses_status: newHire.courses_status.map(course => (
                                    course.id === courseId
                                        ? {
                                            ...course,
                                            stories: payload.result,
                                        } : course)),
                            }
                        }
                        return newHire
                    }),
                },
            },
        }
    },
    [GET_STORIES + ERROR](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireStories: false },

        }
    },
    // get manager newHire story chapters
    [GET_STORY_CHAPTERS + LOADING](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireChapters: true },

        }
    },
    [GET_STORY_CHAPTERS + LOADED](state, payload) {
        let confirmedNewHires = get(state, "data.confirmedNewHires.newHires", [])
        let pendingNewHires = get(state, "data.pendingNewHires.newHires", [])
        const newHireId = get(payload, "originalPayload.newHireId")
        const courseId = get(payload, "originalPayload.courseId")
        const storyId = get(payload, "originalPayload.storyId")

        if (newHireId && courseId && storyId) {
            confirmedNewHires = {
                ...state.data.confirmedNewHires,
                newHires: confirmedNewHires.map((newHire) => {
                    if (newHire.id === newHireId) {
                        return {
                            ...newHire,
                            courses_status: newHire.courses_status.map(course => (
                                course.id === courseId
                                    ? {
                                        ...course,
                                        stories: course.stories.map(story => (
                                            story.id === storyId ? {
                                                ...story,
                                                chapters: payload.result,
                                            } : story)),
                                    } : course)),
                        }
                    }
                    return newHire
                }),
            }
            pendingNewHires = {
                ...state.data.pendingNewHires,
                newHires: pendingNewHires.map((newHire) => {
                    if (newHire.id === newHireId) {
                        return {
                            ...newHire,
                            courses_status: newHire.courses_status.map(course => (
                                course.id === courseId
                                    ? {
                                        ...course,
                                        stories: course.stories.map(story => (
                                            story.id === storyId ? {
                                                ...story,
                                                chapters: payload.result,
                                            } : story)),
                                    } : course)),
                        }
                    }
                    return newHire
                }),
            }
        }
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireChapters: false },
            data: {
                ...state.data,
                confirmedNewHires,
                pendingNewHires,
            },
        }
    },
    [GET_STORY_CHAPTERS + ERROR](state) {
        return {
            ...state,
            isLoading: { ...state.isLoading, newHireChapters: false },

        }
    },
    [SELECT_COURSE]() {
        return initialState
    },
    [PROFILE_OPEN](state, payload) {
        const profileOpen = payload.isOpen
        return {
            ...state,
            isOpen: profileOpen,

        }
    },
    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },

}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
