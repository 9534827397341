/* eslint-disable import/no-cycle */
import jsonToFormData from "json-form-data"
import {
    HR_FORM, SAVE, SAVE_REQUEST, SUBMIT, SUBMIT_IMAGE, SUBMIT_FILE,
} from "./index"
import { selectAllAnswers } from "./selectors"

export function createLoadingAction(courseId) {
    return {
        type: HR_FORM,
        request: {
            method: "get",
            url: `form?course_id=${courseId}`,
        },
    }
}
export function createSaveAction(submitToServer, categoryId, data, questionId) {
    return (dispatch) => {
        if (submitToServer) {
            dispatch({
                type: SAVE_REQUEST,
                request: {
                    method: "put",
                    url: `form/save/${categoryId}`,
                    data,
                },
            })
        }
        dispatch({
            type: SAVE,
            payload: {
                categoryId,
                newAnswers: data.answer_data,
                questionId: questionId
            },
        })
    }
}
export function createSubmitAction() {
    return (dispatch, getState) => {
        const answerData = selectAllAnswers(getState())

        dispatch({
            type: SUBMIT,
            request: {
                method: "post",
                url: "form",
                data: { answer_data: answerData },
            },
        })
    }
}
export function createSubmitImageAction(questionId, image) {
    const formData = jsonToFormData({
        question_id: questionId,
    })
    if (image) {
        formData.append("image", image, "hrFormImage.png")
    }

    return {
        type: SUBMIT_IMAGE,
        request: {
            method: "post",
            url: "form/save_image",
            data: formData,
        },
    }
}

export const createSubmitFileAction = (questionId, file) => {
    const formData = jsonToFormData({
        question_id: questionId,
    })
    if(file){
        formData.append("file", file, file.name)
    }

    return {
        type: SUBMIT_FILE,
        request: {
            method: "post",
            url: "form/save_file",
            data: formData,
        },
    }
}
