import get from "lodash/get"
import { SubmissionError } from "redux-form"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { LOADED } from "../../middleware/actions"
import { SELECT_COURSE } from "../courses"
import { LOGOUT, selectAuthToken, createLoginAction } from "../authentication"
import client from "../../../configure/client"
import handleInvitationCodeErrors from "./errorHandlers"

// Required variables
const initialState = {
    coursesToJoin: null,
    invitationCode: null,
    joinedCourse: false,
}
export const reducerName = "joinCourses"

// Actions
export const JOIN_COURSES = createActionName(reducerName, "JOIN_COURSES")
export const GET_COURSES_TO_JOIN = createActionName(reducerName, "GET_COURSES_TO_JOIN")
export const SET_INVITATION_CODE = createActionName(reducerName, "SET_INVITATION_CODE")
export const CLEAR = createActionName(reducerName, "CLEAR")

// Action creators
export function createJoinCoursesAction() {
    return { type: JOIN_COURSES }
}

export function createGetCoursesToJoinAction(courses) {
    return { type: GET_COURSES_TO_JOIN, payload: courses }
}

export function createSetInvitationCodeAction(code) {
    return { type: SET_INVITATION_CODE, payload: code }
}

export function createClearAction() {
    return { type: CLEAR }
}

// Async Networking
export function getCoursesToJoin(invitationCode) {
    return function (dispatch, state) {
        const currentToken = selectAuthToken(state())

        return client.request({
            method: "GET",
            url: `add_course?invitation_code=${invitationCode}`,
            headers: { Authorization: `Bearer ${currentToken}` },
        })
            .then((result) => {
                dispatch(createGetCoursesToJoinAction(result))
            })
            .catch((err) => {
                throw new SubmissionError(handleInvitationCodeErrors(err))
            })
    }
}

export function joinCourse(invitationCode) {
    return function (dispatch, state) {
        const currentToken = selectAuthToken(state())

        return client.request({
            method: "post",
            url: "add_course",
            data: { invitation_code: invitationCode },
            headers: { Authorization: `Bearer ${currentToken}` },
        })
            .then(() => {
                dispatch(createJoinCoursesAction())
            })
    }
}

export function joinCourseAndLogin(data) {
    return function (dispatch) {
        return client.post("account", data)
            .then((response) => {
                dispatch(createJoinCoursesAction())
                dispatch(createLoginAction(response.data))
            })
            .catch((err) => {
                throw new SubmissionError(handleInvitationCodeErrors(err))
            })
    }
}

// Selectors
export const selectCoursesToJoin = state => get(state, `${reducerName}.coursesToJoin`)
export const selectInvitationCode = state => get(state, `${reducerName}.invitationCode`)
export const selectJoinedCourse = state => get(state, `${reducerName}.joinedCourse`)

// Reducer
const reducers = {
    [GET_COURSES_TO_JOIN](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, coursesToJoin: payload.data,
        }
    },

    [JOIN_COURSES](state) {
        return { ...state, joinedCourse: true }
    },

    [SET_INVITATION_CODE](state, payload) {
        return { ...state, invitationCode: payload }
    },

    [CLEAR]() { return initialState },

    [SELECT_COURSE]() { return initialState },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
