import get from "lodash/get"
import includes from "lodash/includes"
import jsonToFormData from "json-form-data"
import reducerRegistery from "../../ReducerRegistery"
import {
    reducerName,
    PAGES,
    OPEN_PAGE,
    CLOSE_FEEDBACK,
    POST_PAGE,
    EMPTY,
    SET_FAVOURITE_REQUEST,
    SET_FAVOURITE,
} from "./actions"
import { createReducer } from "../../utility"
import findIndex from "lodash/findIndex"
import reducers from "./reducers"
import constants from "../../../constants"

// Required variables
export const pageStatus = {
    showing: 1,
    posting: 2,
    postSuccess: 3,
    postError: 4,
    notFound: 404,
}

export const initialState = {
    chapterId: null,
    /* Holds the data */
    data: null,
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,

    currentPage: null,
}

// Actions
export * from "./actions"

// Selectors
export const selectChapterId = state => get(state, `${reducerName}.chapterId`)
export const selectPages = state => get(state, `${reducerName}.data`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectTotalCorrectScore = (state, pageId) => {
    const getAllPages = get(state, `${reducerName}.data`, [])
    const indexOfCurrentPage = findIndex(getAllPages, { id: Number(pageId) })
    return getAllPages.slice(0, indexOfCurrentPage).reduce((total, page) => {
        const isValid = !page.isExcludedFromScore
            && (page.isCorrect === constants.answerStatus.correct)
        return isValid ? total + parseInt(page.weight, 10) : total
    }, 0)
}
export const selectLeaderBoardTotalCorrectScore = (state, pageId) => {
    const getAllPages = get(state, `${reducerName}.data`, [])
    const indexOfCurrentPage = findIndex(getAllPages, { id: Number(pageId) })
    return getAllPages.slice(0, indexOfCurrentPage).reduce((total, page) => {
        const isValid = !page.isExcludedFromScore
        return isValid ? total + parseInt(page.userLeaderboardScore, 10) : total
    }, 0)
}
export const selectTotalWeight = (state, pageId) => {
    const getAllPages = get(state, `${reducerName}.data`, [])
    const indexOfCurrentPage = findIndex(getAllPages, { id: Number(pageId) })
    return getAllPages.slice(0, indexOfCurrentPage).reduce((total, page) => {
        const isValid = !page.isExcludedFromScore
        return isValid ? total + parseInt(page.weight, 10) : total
    }, 0)
}
export const selectLeaderBoardCorrectWeight = (state, pageId) => {
    const getAllPages = get(state, `${reducerName}.data`, [])
    const indexOfCurrentPage = findIndex(getAllPages, { id: Number(pageId) })
    return getAllPages.slice(0, indexOfCurrentPage).reduce((total, page) => {
        const isValid = !page.isExcludedFromScore && page.userLeaderboardScore > 0
        return isValid ? total + parseInt(page.weight, 10) : total
    }, 0)
}
export const selectStartPage = pages => pages.reduce((startPage, page, index) => {
    if (startPage !== null) return startPage

    return !page.answer.updateTime ? index : startPage
}, null) || 0
export const selectCurrentPage = state => get(state, `${reducerName}.currentPage`)
export const selectIsFeedbackShown = state => (
    get(state, `${reducerName}.currentPage.status`) === pageStatus.postSuccess &&
    get(state, `${reducerName}.currentPage.page.isFeedbackPage`) === true
)

export const selectHasVideoBeenPlayed = (state, videoId) => {
    const videosPlayed = get(state, `${reducerName}.videosPlayed`)
    return includes(videosPlayed, videoId)
}

// Action creators
export function createOpenPageAction(pageId, timestamp) {
    return {
        type: OPEN_PAGE,
        payload: {
            id: pageId,
            openedAt: timestamp,
        },
    }
}

export function createPostAction(chapterId, page, data) {
    let formData = data

    if (page.pageType === constants.pageTypes.photoEssay && data.image) {
        if (typeof data.image === "string") {
            delete formData.image
        } else if (data.image) {
            formData = jsonToFormData(data)
            formData.append("image", data.image, "cropped.jpeg")
        }
    }

    return {
        type: POST_PAGE,
        payload: {
            id: page.id,
            data,
        },
        request: {
            method: "post",
            url: `chapters/${chapterId}/pages/${page.id}?desktop_answers=1`,
            data: formData,
        },
    }
}

export function createCloseFeedbackAction() {
    return { type: CLOSE_FEEDBACK }
}

export function createEmptyAction() {
    return { type: EMPTY }
}

export function createLoadPagesAction(chapterId) {
    return {
        type: PAGES,
        payload: chapterId,
        request: {
            method: "get",
            url: `chapters/${chapterId}/pages?desktop_answers=1`,
        },
    }
}

export function createLoadingAction(chapterId) {
    return (dispatch, getState) => {
        const state = getState()
        if (selectChapterId(state) !== chapterId) {
            // Opened a new chapter so we empty the reducer of all data
            dispatch(createEmptyAction())
            dispatch(createLoadPagesAction(chapterId))
            return
        }

        if (!selectPages(state)) {
            dispatch(createLoadPagesAction(chapterId))
        }
    }
}

export function createSetFavouriteAction(chapterId, pageId, isFavourite) {
    return (dispatch) => {
        dispatch({
            type: SET_FAVOURITE,
            payload: { pageId, isFavourite },
        })

        dispatch({
            type: SET_FAVOURITE_REQUEST,
            request: {
                method: "post",
                url: `chapters/${chapterId}/pages/${pageId}/favorite`,
                data: {
                    is_favorite: isFavourite ? 0 : 1,
                },
            },
        })
    }
}

// Reducer
export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
