/* istanbul ignore file */
import * as Sentry from "@sentry/browser"
import { version } from "../package.json"
const { REACT_APP_ENV, REACT_APP_SENTRY_DSN, REACT_APP_IS_SENTRY_ENABLED } = process.env;



/**
 * Configuration options for Sentry error tracking and performance monitoring based on different environments.
 *
 * @type {Object}
 * @property {string} dsn - Sentry Data Source Name (DSN) for error tracking.
 * @property {string} environment - Environment name (e.g., 'development', 'staging', 'production').
 * @property {boolean} enabled - Whether Sentry error tracking is enabled for the specified environment.
 * @property {number} tracesSampleRate - The percentage of transactions to be sent to Sentry for performance monitoring (0.0 to 1.0).
 *                                     A value of 1.0 means all transactions are sent, 0.0 means none are sent, and 0.5 means 50% are sent.
 */

 const sentryConfigList = {
    dev: {
        dsn: REACT_APP_SENTRY_DSN,
        environment: 'development',
        enabled: (String(REACT_APP_IS_SENTRY_ENABLED).toLowerCase() === 'true') || false,
        tracesSampleRate: 0.0 // No transactions are sent for performance monitoring in the development environment.
    },

    staging: {
        dsn: REACT_APP_SENTRY_DSN,
        environment: 'staging',
        enabled: true, // Always enable Sentry on Staging
        tracesSampleRate: 0.0 // No transactions are sent for performance monitoring in the staging environment.
    },

    prod: {
        dsn: REACT_APP_SENTRY_DSN,
        environment: 'production',
        enabled: true, // Always enable Sentry on prod
        tracesSampleRate: 0.5 // 50% of transactions are sent for performance monitoring in the production environment.
    },
};


export const sentryConfig = sentryConfigList[REACT_APP_ENV] || sentryConfigList.development;

if (sentryConfig?.enabled) {
    Sentry.init({
        dsn: sentryConfig.dsn,
        release: version,
        environment: sentryConfig.environment,
        tracesSampleRate: sentryConfig.tracesSampleRate,
        integrations: [Sentry.browserTracingIntegration()],
    })
}