import { createActionName } from "../../utility";
import { reducerName } from './index'


export const RESET_USER_PREFERENCES = createActionName(reducerName, "RESET_USER_PREFERENCES");
export const USER_PREFERENCES = createActionName(reducerName, "USER_PREFERENCES");
export const UPDATE_USER_PREFERENCES = createActionName(reducerName, "UPDATE_USER_PREFERENCES");



/**
 * Create an action to load user preferences.
 * @returns {Object} - The action object.
 */
export const createLoadUserPreferences = () => {
    return {
        type: USER_PREFERENCES,
        request: {
            method: "GET",
            url: "v1/user_preferences"
        },
    }
}


/**
 * Creates an action to update user preferences.
 *
 * @param {Object} data - The data containing the user preferences to be updated.
 * @returns {Object} An action object with the type and request properties.
 */
export const createUpdateUserPreferences = (data) => {
    return {
        type: UPDATE_USER_PREFERENCES,
        request: {
            method: "PUT",
            url: "v1/user_preferences",
            data
        },
    }
}


/**
 * Creates an action to update the user preferences app language identifier.
 * 
 * @param {string} appLanguageIdentifier - The app language identifier to be updated.
 * @returns {object} - The action object with the updated app language identifier.
 */
export const createUpdateUserPreferencesAppLanguageIdentifier = (appLanguageIdentifier) => {
    return createUpdateUserPreferences({ app_language_identifier: appLanguageIdentifier })
}