import get from "lodash/get"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { LOGOUT } from "../authentication"
import { LOADED } from "../../middleware/actions"


// Required variables
const initialState = {
    isFirstLoad: true,
    showingTutorial: false,
    isFirstLoadInfoSpot: true,
}
export const reducerName = "firstLoad"

// Actions
export const LOAD = createActionName(reducerName, "LOAD")
export const SHOW_TUTORIAL = createActionName(reducerName, "SHOW_TUTORIAL")
export const HIDE_TUTORIAL = createActionName(reducerName, "HIDE_TUTORIAL")
export const MARK_INFO_SPOTS_AS_SEEN = createActionName(reducerName, "MARK_INFO_SPOTS_AS_SEEN")

// Action creators
export function createLoadAction() {
    return {
        type: LOAD,
    }
}
export function createShowTutorialAction() {
    return {
        type: SHOW_TUTORIAL,
    }
}
export function createHideTutorialAction() {
    return {
        type: HIDE_TUTORIAL,
    }
}
export function createMarkInfoSpotsAsSeenAction() {
    return {
        type: MARK_INFO_SPOTS_AS_SEEN,
    }
}

// Selectors
export const selectIsFirstLoad = state => get(state, `${reducerName}.isFirstLoad`)
export const selectShowingTutorial = state => get(state, `${reducerName}.showingTutorial`)
export const selectIsFirstLoadInfoSpot = state => get(state, `${reducerName}.isFirstLoadInfoSpot`)

// Reducer
const reducers = {
    [LOAD](state) {
        return { ...state, isFirstLoad: false }
    },
    [SHOW_TUTORIAL](state) {
        return { ...state, showingTutorial: true }
    },
    [HIDE_TUTORIAL](state) {
        return { ...state, showingTutorial: false }
    },
    [MARK_INFO_SPOTS_AS_SEEN](state) {
        return { ...state, isFirstLoadInfoSpot: false }
    },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
