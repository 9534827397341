import { createActionName } from "../../utility"

export const reducerName = "pages"

export const EMPTY = createActionName(reducerName, "EMPTY")
export const PAGES = createActionName(reducerName, "PAGES")
export const OPEN_PAGE = createActionName(reducerName, "OPEN_PAGE")
export const POST_PAGE = createActionName(reducerName, "POST_PAGE")
export const CLOSE_FEEDBACK = createActionName(reducerName, "CLOSE_FEEDBACK")
export const SET_FAVOURITE = createActionName(reducerName, "SET_FAVOURITE")
export const SET_FAVOURITE_REQUEST = createActionName(reducerName, "SET_FAVOURITE")