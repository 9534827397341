import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { selectSelectedCourse } from "../courses"
import { SELECT_LANGUAGE } from "../languages"

// Required variables
const initialState = {

    /* Holds the user redux object */
    data: {},
    /* Are we loading new checklist items */
    isLoading: false,
    /* Loading checklist items resulted in an error */
    loadingError: null,
}
export const reducerName = "checklist"

// Actions
export const CHECKLIST = createActionName(reducerName, "CHECKLIST")
export const PREFERENCES = createActionName(reducerName, "PREFERENCES")
export const CHECK_ITEM_REQUEST = createActionName(reducerName, "CHECK_ITEM_REQUEST")
export const CHECK_ITEM = createActionName(reducerName, "CHECK_ITEM")
export const SET_CHECKLIST_PREFERENCE_REQUEST = createActionName(reducerName, "SET_CHECKLIST_PREFERENCE_REQUEST")
export const SET_CHECKLIST_PREFERENCE = createActionName(reducerName, "SET_CHECKLIST_PREFERENCE")

// Action creators
export function createLoadingAction() {
    return (dispatch, getState) => {
        const course = selectSelectedCourse(getState())

        dispatch({
            type: CHECKLIST,
            request: {
                method: "get",
                url: `courses/${course.id}/checklists`,
            },
        })
    }
}
export function createLoadingPreferencesAction() {
    return (dispatch) => {
        dispatch({
            type: PREFERENCES,
            request: {
                method: "get",
                url: `user_preferences`,
            },
        })
    }
}

export function createCheckItemAction(item) {
    return (dispatch, getState) => {
        const course = selectSelectedCourse(getState())

        dispatch({
            type: CHECK_ITEM,
            payload: item,
        })

        dispatch({
            type: CHECK_ITEM_REQUEST,
            request: {
                method: "post",
                url: `courses/${course.id}/checklists/${item.id}`,
                data: {
                    checked: item.checked ? "0" : "1",
                },
            },
        })
    }
}
export function createSetChecklistPreferenceAction(showCompleted) {
    return (dispatch) => {
        dispatch({
            type: SET_CHECKLIST_PREFERENCE,
            payload: showCompleted,
        })

        dispatch({
            type: SET_CHECKLIST_PREFERENCE_REQUEST,
            request: {
                method: "post",
                url: `user_preferences`,
                data: {
                    show_completed_checklist_items: showCompleted,
                },
            },
        })
    }
}

// Selectors
export * from "./selectors"

// Reducer
const reducers = {
    [CHECKLIST + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [CHECKLIST + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [CHECKLIST + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [PREFERENCES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [PREFERENCES + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, preferences: payload.result,
        }
    },

    [PREFERENCES + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    // Check item
    [CHECK_ITEM_REQUEST + ERROR](state) {
        return { ...state }
    },

    [CHECK_ITEM](state, payload) {
        return {
            ...state,
            data: {
                ...state.data,
                checklists: state.data.checklists.map((item) => {
                    if (item.id === payload.id) {
                        return {
                            ...item,
                            checked: !payload.checked,
                        }
                    }
                    return item
                }),
            },
        }
    },
    [SET_CHECKLIST_PREFERENCE](state, payload) {
        return {
            ...state,
            preferences: {
                ...state.preferences,
                show_completed_checklist_items: payload
            },
        }
    },
    [SET_CHECKLIST_PREFERENCE_REQUEST + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [SET_CHECKLIST_PREFERENCE_REQUEST + LOADED](state) {
        return {
            ...state, isLoading: false, loadingError: null,
        }
    },
    [SET_CHECKLIST_PREFERENCE_REQUEST + ERROR](state, payload) {
        return { ...state, loadingError: payload.result }
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
