import get from "lodash/get"
import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { LOADING, LOADED, ERROR } from "../../middleware/actions"
import { SELECT_COURSE } from "../courses"
import { LOGOUT } from "../authentication"

// Required variables
const initialState = {
    followers: null,
    isLoading: false,
    errors: null,
}
export const reducerName = "followers"

// Actions
export const GET_FOLLOWERS = createActionName(reducerName, "GET_FOLLOWERS")

// Action creators
export function createGetFollowersAction() {
    return {
        type: GET_FOLLOWERS,
        request: {
            method: "get",
            url: "user/followers",
        },
    }
}

// Selectors
export const selectFollowers = state => get(state, `${reducerName}.followers`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectErrors = state => get(state, `${reducerName}.errors`)

// Reducer
const reducers = {
    [GET_FOLLOWERS + LOADING](state) {
        return {
            ...state, isLoading: true,
        }
    },

    [GET_FOLLOWERS + LOADED](state, payload) {
        return {
            ...state, isLoading: false, followers: payload.result,
        }
    },

    [GET_FOLLOWERS + ERROR](state, payload) {
        return {
            ...state, errors: payload.result,
        }
    },

    [SELECT_COURSE]() { return initialState },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
