import get from "lodash/get"
import assign from "lodash/assign"
import moment from "moment"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import pageConverter from "./pageConverter"
import { initialState, pageStatus, selectStartPage } from "./index"
import { PAGES, OPEN_PAGE, CLOSE_FEEDBACK, POST_PAGE, EMPTY, SET_FAVOURITE } from "./actions"
import { SELECT_COURSE } from "../courses"
import { LOGOUT } from "../authentication"
import constants from "../../../constants"
import { SELECT_LANGUAGE } from "../languages"

const moduleAnswerState = {
    multiChoice: (module, answer) => {
        const state = get(answer, "state")
        const selectedAnswers = Object.keys(state)
            .filter(it => state[it] === "1")
            .map(it => parseInt(it, 10))
        const choices = get(module, "choices")

        const correctAnswerIds = choices.filter(it => it.correct)
            .map(it => parseInt(it.id, 10))
            .sort()

        if (String(selectedAnswers) !== String(correctAnswerIds)) {
            return constants.answerStatus.incorrect
        }

        return constants.answerStatus.correct
    },

    mandatoryEssay: (module, answer) => (
        !answer.state.text ? constants.answerStatus.incorrect : constants.answerStatus.neutral
    ),

    [constants.pageModules.multipleChoiceImage]: (module, answer) => moduleAnswerState.multiChoice(module, answer),
    [constants.pageModules.multipleChoice]: (module, answer) => moduleAnswerState.multiChoice(module, answer),
    [constants.pageModules.essay]: (module, answer) => moduleAnswerState.mandatoryEssay(module, answer),
    [constants.pageModules.photoEssay]: (module, answer) => moduleAnswerState.mandatoryEssay(module, answer),
    [constants.pageModules.assign]: (module, answer) => (module.choices.reduce((result, choice) => {
        const choiceId = get(choice, "id", 0)
        const group = get(choice, "group")

        return result && (answer.state[group] && answer.state[group].includes(choiceId))
    }, true) && constants.answerStatus.correct) || constants.answerStatus.incorrect,
    [constants.pageModules.order]: (module, answer) => {
        const res = (
            module.items.length === Object.keys(answer.state).length
            && Object.keys(answer.state).reduce((result, key) => (
                result
                // If the key does not match the value the answer is not matched properly
                && `${answer.state[key]}` === key
            ), true)
        )
        return (res) ? constants.answerStatus.correct : constants.answerStatus.incorrect
    },

    default: () => constants.answerStatus.neutral,
}

function getAnswerState(page, answer) {
    if ((page.isNeutralFeedback && !page.mandatoryAnswer) || !page.modules) return constants.answerStatus.neutral

    return page.modules
        .map(module => (
            moduleAnswerState[module.type]
            || moduleAnswerState.default
        )(module, answer))
        .reduce((state, currentValue) => {
            if (state === constants.answerStatus.incorrect) {
                return constants.answerStatus.incorrect
            } else if (
                state === constants.answerStatus.correct
                && currentValue !== constants.answerStatus.incorrect
            ) {
                return constants.answerStatus.correct
            }
            return currentValue
        }, constants.answerStatus.neutral)
}

const reducers = {
    [PAGES + LOADING](state, payload) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
            chapterId: payload,
        }
    },
    [PAGES + LOADED](state, payload) {
        if (state.chapterId !== payload.originalPayload) { return state }
        const pages = payload.result.map(pageConverter)
        const selectedIndex = selectStartPage(pages)

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: pages,
            currentPage: {
                page: get(pages, `[${selectedIndex}]`, {}),
                index: selectedIndex,
                status: pageStatus.showing,
                openedAt: moment().format(), // add timestamp
            },
        }
    },
    [PAGES + ERROR](state, payload) {
        if (state.chapterId !== payload.originalPayload) { return state }
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [OPEN_PAGE](state, payload) {
        if (state.data === null) {
            return { ...state, currentPage: null }
        }
        const selectedIndex = state.data.findIndex(page => page.id === payload.id)

        if (selectedIndex === -1) {
            return {
                ...state,
                currentPage: {
                    page: { id: payload.id },
                    status: pageStatus.notFound,
                },
            }
        }

        return {
            ...state,
            currentPage: {
                page: state.data[selectedIndex],
                index: selectedIndex,
                status: pageStatus.showing,
                openedAt: payload.openedAt,
            },
        }
    },

    [CLOSE_FEEDBACK](state) {
        if (!state.currentPage || state.currentPage.status !== pageStatus.postSuccess) {
            return state
        }

        return {
            ...state,
            currentPage: {
                ...state.currentPage,
                status: pageStatus.showing,
            },
        }
    },

    [POST_PAGE + LOADING](state, payload) {
        const currentPageId = get(state, "currentPage.page.id")
        if (!state.data || !currentPageId || currentPageId !== payload.id) {
            return state
        }

        const updatedPage = {
            ...state.currentPage.page,
            answer: payload.data || null,
            isCorrect: getAnswerState(state.currentPage.page, payload.data),
            isFirstTimeVisit: !get(state.currentPage, "page.answer.updateTime"),
        }

        // Update the page answer in the list
        const updatedPages = state.data.map((page) => {
            if (page.id !== payload.id) return page
            return updatedPage
        })

        return {
            ...state,
            data: updatedPages,
            currentPage: {
                ...state.currentPage,
                status: pageStatus.posting,
                page: updatedPage,
            },
        }
    },

    [POST_PAGE + LOADED](state, payload) {
        const currentPageId = get(state, "currentPage.page.id")
        if (!state.data || !currentPageId || currentPageId !== get(payload, "originalPayload.id")) {
            return state
        }
        const updatedPages = state.data.map(page => (page.id === state.currentPage.page.id
            ? { ...page, userLeaderboardScore: get(payload, "result.user_leaderboard_score") }
            : page))

        return {
            ...state,
            currentPage: {
                ...state.currentPage,
                page: {
                    ...state.currentPage.page,
                    userLeaderboardScore: get(payload, "result.user_leaderboard_score"),
                },
                status: pageStatus.postSuccess,
            },
            data: updatedPages,
        }
    },

    [POST_PAGE + ERROR](state, payload) {
        const currentPageId = get(state, "currentPage.page.id")
        if (!state.data || !currentPageId || currentPageId !== get(payload, "originalPayload.id")) {
            return state
        }

        return {
            ...state,
            currentPage: {
                ...state.currentPage,
                status: pageStatus.postError,
            },
        }
    },

    [SET_FAVOURITE](state, payload) {
        const updatedPages = state.data.map(page => (page.id === state.currentPage.page.id
            ? assign({}, page, { isFavorite: !payload.isFavourite })
            : page))

        return {
            ...state,
            currentPage: {
                ...state.currentPage,
                page: {
                    ...state.currentPage.page,
                    isFavorite: !payload.isFavourite,
                },
            },
            data: updatedPages,
        }
    },

    // Reset reducers
    [SELECT_LANGUAGE + LOADED]() {
        return initialState
    },
    [EMPTY]() { return initialState },
    [LOGOUT + LOADED]() {
        return initialState
    },
    [SELECT_COURSE]() { return initialState },
}

export default reducers
