import React from "react"
import PropTypes from "prop-types"
import ClassNames from "classnames"

// material-ui
import CircularProgress from "@material-ui/core/CircularProgress"
import withStyles from "@material-ui/core/styles/withStyles"

import styles from "./styles"

const Loading = ({
    classes, loading, transparent,lighten, className, green,
}) => (
    <div className={ClassNames(
        className,
        classes.root,
        loading ? "loading" : "",
        transparent ? "transparent" : "",
        green ? "green" : "",
    )}
    >
        <CircularProgress className={ClassNames(
            className,
            classes.loader,
            lighten ? "white": "",
            green ? "green" : "",
        )}
        />
    </div>
)

Loading.propTypes = {
    /* set loading to false to hide the component without removing it from the DOM */
    loading: PropTypes.bool,
    transparent: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
}

Loading.defaultProps = {
    loading: true,
    transparent: false,
    className: "",
}

export default withStyles(styles, { withTheme: true })(Loading)
