import client from "../../../configure/client";
import { createActionName } from "../../utility";
import { reducerName, initialState } from './index'

const URLs = {
    sendRequestDeleteDataConfirmation: "/v1/player/public/deletion_requests/request_delete_data_public", //This will send confirmation email to the user.
    sendDeleteRequestToSupport: "/v1/player/public/deletion_requests/delete_my_data_public" //This will send email request to support after confirming the email.
};

const methods = {
    post: "post"
};

export const CLEAR_DATA = createActionName(reducerName, "CLEAR_DATA");
export const SET_IS_REQUEST_SENT = createActionName(reducerName, "SET_IS_REQUEST_SENT");
export const SET_IS_CONFIRMATION_EMAIL_SENT = createActionName(reducerName, "SET_IS_CONFIRMATION_EMAIL_SENT");
export const TOGGLE_IS_LOADING = createActionName(reducerName, "TOGGLE_IS_LOADING");
export const SET_ERROR_MESSAGE = createActionName(reducerName, "SET_ERROR_MESSAGE");


export const createSetIsRequestSentAction = () => ({ type: SET_IS_REQUEST_SENT });
export const createSetIsConfirmationEmailSentAction = () => ({ type: SET_IS_CONFIRMATION_EMAIL_SENT });
export const createClearDataAction = () => ({ type: CLEAR_DATA });
export const createToggleIsLoadingAction = () => ({ type: TOGGLE_IS_LOADING });
export const createSetErrorMessage = message => ({ type: SET_ERROR_MESSAGE, payload: message });

// Async action

/**
 * Asynchronously sends a request to delete user data to the support team.
 * 
 * @param {string} userEmail - The email of the user whose data is to be deleted.
 * @returns {Function} - An async function that dispatches actions to the Redux store.
 */
export const sendDeleteRequestToSupportAction = userEmail => async dispatch => {
    dispatch(createToggleIsLoadingAction());
    try {
        const response = await client.request({ method: methods.post, url: URLs.sendDeleteRequestToSupport, params: { email: userEmail } });
        if (response.status === 200) {
            dispatch(createSetIsRequestSentAction());
        }
    } catch (error) {
        console.error("Error occurred while requesting user data deletion:", error);
        dispatch(createSetErrorMessage(initialState.defaultErrorMessage));
    } finally {
        dispatch(createToggleIsLoadingAction());
    }
};

/**
 * Asynchronously sends a request to confirm deletion of user data.
 * 
 * @param {Object} data - Object containing user data.
 * @param {string} data.username - The email address of the user.
 * @returns {Function} - An async function that dispatches actions to the Redux store.
 */
export const sendRequestDeleteDataConfirmationAction = data => async dispatch => {
    const { username } = data;
    dispatch(createToggleIsLoadingAction());
    try {
        const response = await client.request({ method: methods.post, url: URLs.sendRequestDeleteDataConfirmation, params: { email: username } });
        if (response.status === 200) {
            dispatch(createSetIsConfirmationEmailSentAction());
        }
    } catch (error) {
        console.error("Error occurred while sending user data email confirmation:", error);
        dispatch(createSetErrorMessage(initialState.defaultErrorMessage));
    } finally {
        dispatch(createToggleIsLoadingAction());
    }
};