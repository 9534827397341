import { createActionName } from "../../utility"

export const reducerName = "messages"

export const MEMBERS = createActionName(reducerName, "MEMBERS")
export const MESSAGES = createActionName(reducerName, "MESSAGES")
export const ADD_MESSAGE = createActionName(reducerName, "ADD_MESSAGE")
export const UPDATE_CONVERSATION = createActionName(reducerName, "UPDATE_CONVERSATION")
export const CREATE_GROUP = createActionName(reducerName, "CREATE_GROUP")
export const EDIT_GROUP = createActionName(reducerName, "EDIT_GROUP")
export const SEND_MESSAGE = createActionName(reducerName, "SEND_MESSAGE")
export const PREVIEWS = createActionName(reducerName, "PREVIEWS")
export const ADD_PREVIEW = createActionName(reducerName, "ADD_PREVIEW")
export const REMOVE_PREVIEW = createActionName(reducerName, "REMOVE_PREVIEW")
export const EDIT_PREVIEW = createActionName(reducerName, "EDIT_PREVIEW")
export const MARK_AS_READ = createActionName(reducerName, "MARK_AS_READ")
export const UPDATE_CONVERSATION_STATUS = createActionName(reducerName, "UPDATE_CONVERSATION_STATUS")
export const MARK_TUTORIAL_AS_SEEN = createActionName(reducerName, "MARK_TUTORIAL_AS_SEEN")
export const TUTORIAL_SEEN = createActionName(reducerName, "TUTORIAL_SEEN")
