import get from "lodash/get"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { SELECT_COURSE } from "../courses"
import reducerRegistery from "../../ReducerRegistery"

// Required variables
const initialState = {
    /* Holds the notifications redux object */
    data: null,
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
}
export const reducerName = "notifications"

// Selectors
export const selectNotifications = state => get(state, `${reducerName}.data`)
export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectUnreadNotifications = (state) => {
    const notifications = get(state, `${reducerName}.data.notifications`)

    if (!notifications) return []

    const unreadNotifications = notifications.filter(notification => !notification.read)

    return unreadNotifications
}
export const selectHasUnreadNotifications = state => selectUnreadNotifications(state).length > 0
export const selectNotification = (state, notificationId) => {
    const data = get(state, `${reducerName}.data`)

    if (!data || !notificationId) return null

    return data.notifications
        .find(notification => parseInt(notification.id, 10) === parseInt(notificationId, 10))
}
export const selectNotificationsTitle = state => get(state, `${reducerName}.data.title`)

// Actions
export const GET_NOTIFICATIONS = createActionName(reducerName, "GET_NOTIFICATIONS")
export const SET_NOTIFICATION_READ = createActionName(reducerName, "SET_NOTIFICATION_READ")
export const SET_NOTIFICATION_CONFIRMED = createActionName(reducerName, "SET_NOTIFICATION_CONFIRMED")

// Action creators
export function createLoadNotificationsAction() {
    return ({
        type: GET_NOTIFICATIONS,
        request: {
            method: "get",
            url: "notifications",
        },
    })
}
export function createSetNotificationReadAction(notificationId) {
    return ({
        type: SET_NOTIFICATION_READ,
        request: {
            method: "put",
            url: `notifications/${notificationId}/mark_as_read`,
        },
        payload: {
            notificationId,
        },
    })
}
export function createSetNotificationConfirmedAction(notificationId) {
    return ({
        type: SET_NOTIFICATION_CONFIRMED,
        request: {
            method: "put",
            url: `notifications/${notificationId}/mark_as_confirmed`,
        },
        payload: {
            notificationId,
        },
    })
}

// Reducer
const reducers = {
    [GET_NOTIFICATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [GET_NOTIFICATIONS + LOADED](state, payload) {
        return {
            ...state, isLoading: false, loadingError: null, data: payload.result,
        }
    },
    [GET_NOTIFICATIONS + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SET_NOTIFICATION_READ + LOADED](state, payload) {
        const readNotificationId = get(payload, "originalPayload.notificationId", null)
        const notifications = get(state, "data.notifications", null)

        if (!readNotificationId || !notifications) return { ...state }

        const newNotificationsArr = notifications.map(notification => ({
            ...notification,
            read: notification.id === readNotificationId ? true : notification.read,
        }))

        return {
            ...state,
            data: {
                ...state.data,
                notifications: newNotificationsArr,
            },
        }
    },

    [SET_NOTIFICATION_CONFIRMED + LOADED](state, payload) {
        const confirmedNotificationId = get(payload, "originalPayload.notificationId", null)
        const notifications = get(state, "data.notifications", null)

        if (!confirmedNotificationId || !notifications) return { ...state }

        const newNotificationsArr = notifications.map(notification => ({
            ...notification,
            confirmed: notification.id === confirmedNotificationId ? true : notification.confirmed,
        }))

        return {
            ...state,
            data: {
                ...state.data,
                notifications: newNotificationsArr,
            },
        }
    },

    // Reset reducers
    [SELECT_COURSE](state) {
        return {
            ...initialState,
            GCMToken: state.GCMToken,
        }
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

// Register the reducer with the registery
reducerRegistery.register(reducerName, reducer)
