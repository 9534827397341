import get from "lodash/get"
import uuid from "uuid"
import { createSelector } from "reselect"

import orderBy from "lodash/orderBy"
import { reducerName } from "./index"

// Basic Selectors
export const selectIsLoading = (state) => {
    const loaders = get(state, `${reducerName}.isLoading`)
    return Object.keys(loaders).some(key => loaders[key])
}
export const selectErrors = state => get(state, `${reducerName}.errors`)
export const selectTabTitle = state => get(state, `${reducerName}.tabTitle`)

// Todo
export const selectTodo = state => get(state, `${reducerName}.data.todo`)

// Loading
export const selectNewHiresIsLoading = state => get(state, `${reducerName}.isLoading.newHires`)
export const selectNewHireDetailIsLoading = state => get(state, `${reducerName}.isLoading.newHireDetail`)
export const selectPendingNewHireIsLoading = state => get(state, `${reducerName}.isLoading.pendingNewHires`)
export const selectConfirmedNewHireIsLoading = state => get(state, `${reducerName}.isLoading.confirmedNewHires`)
export const selectNewHireStoriesIsLoading = state => get(state, `${reducerName}.isLoading.newHireStories`)
export const selectNewHireChaptersIsLoading = state => get(state, `${reducerName}.isLoading.newHireChapters`)

// New Hires
export const selectNewHires = state => get(state, `${reducerName}.data.newHires`, [])
export const selectPendingNewHires = state => get(state, `${reducerName}.data.pendingNewHires.newHires`, [])
export const selectPendingPageId = state => get(state, `${reducerName}.data.pendingNewHires.nextPage`, 1)
export const selectPendingCount = state => get(state, `${reducerName}.data.pendingNewHires.totalCount`, 0)
export const selectConfirmedNewHires = state => get(state, `${reducerName}.data.confirmedNewHires.newHires`, [])
export const selectConfirmedPageId = state => get(state, `${reducerName}.data.confirmedNewHires.nextPage`, 1)
export const selectConfirmedCount = state => get(state, `${reducerName}.data.confirmedNewHires.totalCount`, 0)
export const selectUpdatedNewHire = state => get(state, `${reducerName}.updatedHireId`, null)
export const selectHasConfirmedNewHires = createSelector(selectConfirmedNewHires, newHires => newHires.some(newHire => newHire.management_approved))
export const selectHasUnconfirmedNewHires = createSelector(selectPendingNewHires, newHires => newHires.some(newHire => !newHire.management_approved))

// find newHire from pending
export const selectPendingNewHire = createSelector(selectPendingNewHires, (_, id) => id,
    (newHires, id) => newHires.find(
        newHire => parseInt(newHire.id, 10) === parseInt(id, 10),
    ))

// find newHire from confirmed
export const selectConfirmedNewHire = createSelector(selectConfirmedNewHires, (_, id) => id,
    (newHires, id) => newHires.find(
        newHire => parseInt(newHire.id, 10) === parseInt(id, 10),
    ))

export const selectNewHire = createSelector(
    selectPendingNewHire,
    selectConfirmedNewHire,
    (pendingHire, confirmedHire) => pendingHire || confirmedHire,
)

export const selectNewHireCourses = createSelector(selectNewHire, newHire => newHire.courses_status)

// order courses of the selected new hire in desc
export const selectNewHireOrderedCourses = createSelector(
    selectNewHireCourses,
    courses => orderBy(courses, [(course) => {
        const { progress } = course

        if (progress === 100) {
            return -1
        }

        return progress
    }], ["desc"]),
)

// Introduction
export const selectIntroduction = state => get(state, `${reducerName}.data.introduction`)

// Tips and Tricks
export const selectTipsAndTricks = (state) => {
    const tipsAndTricks = get(state, `${reducerName}.data.tipsAndTricks`)

    if (!tipsAndTricks) return null

    const { tips, sub_categories } = tipsAndTricks
    const categories = []

    // If the tips and tricks have not yet loaded, or the list is already merged, do nothing
    if (!tips || !tips.length) {
        return tipsAndTricks
    }

    // If there are no sub categories, return one nameless category
    if (!sub_categories || !sub_categories.length) {
        return [
            {
                id: 1,
                title: "",
                items: tips,
            },
        ]
    }

    // If there are any items with no category, add them to the list first under
    // a nameless category
    const itemsWithNoCategory = tips.filter(item => !item.sub_category_id)
    if (itemsWithNoCategory.length) {
        categories.push({
            id: uuid(),
            title: "",
            items: itemsWithNoCategory,
        })
    }

    // Merge the items into their subcategories
    sub_categories.map((subCategory) => {
        const mergedSubcategory = { ...subCategory }
        mergedSubcategory.items = tips.filter(
            item => item.sub_category_id === mergedSubcategory.id,
        )
        return categories.push(mergedSubcategory)
    })

    return categories
}

//  this select tip is not used because it won't work on refresh,
// to-do: have a separate API call for the selected tip
export const selectTip = (state, tipId) => {
    const tipsAndTricks = get(state, `${reducerName}.data.tipsAndTricks`)
    let selectedTip = null
    if (tipsAndTricks) {
        selectedTip = tipsAndTricks.tips.find(
            tip => parseInt(tip.id, 10) === parseInt(tipId, 10),
        )
    }

    return selectedTip
}

// Unapproved Hires
export const selectUnapprovedHires = state => get(state, `${reducerName}.data.unapprovedHires`)
export const selectUnapprovedHiresIsLoading = state => get(state, `${reducerName}.isLoading.unapproved`)
export const selectApproveNewHireIsLoading = state => get(state, `${reducerName}.isLoading.approveNewHire`)
export const selectRemoveNewHireIsLoading = state => get(state, `${reducerName}.isLoading.removeNewHire`)
export const selectApproveNewHire = state => get(state, `${reducerName}.data.approveNewHire`)

// Essay
export const selectIsEssay = state => get(state, `${reducerName}.isEssay`)

// Available managers
export const selectAvailableManagersIsLoading = state => get(state, `${reducerName}.isLoading.availableManagers`)
export const selectAvailableManagers = state => get(state, `${reducerName}.data.availableManagers`)

export const selectManagersForwardIsLoading = state => get(state, `${reducerName}.isLoading.managersForward`)
export const selectManagersForward = state => get(state, `${reducerName}.data.managersForward`)
export const selectProfileOpen = state => get(state, `${reducerName}.isOpen`)
export const selectPopUpOpen = state => get(state, `${reducerName}.ispopUpOpen`)
